import Dexie from 'dexie'

export const db = new Dexie('ToriiSRS')
db.version(2).stores({
  sync: 'id',
  vocabulary: 'id,jlpt_level,wk_level',
  custom: 'id',
  decksMetadata: 'id',
  decks: '++,id',
  progress: 'vocab_id,archived,prioritized,reviewed_meaning,reviewed_reading,reviewed_jptojp,correct_meaning,correct_reading,correct_jptojp,streak_meaning,streak_reading,streak_jptojp,due_meaning,due_reading,due_jptojp',
  user: 'id',
  settings: 'id',
  pro: 'id'
})
