<template>
<v-row>
  <v-col cols="12">
    <v-card class="rounded-t-0">
      <v-card-text>
        <WanaKanaTextfield
          :id="searchId"
          v-model="search"
          :active="false"
          indicator
          clearable
          outlined
          class="mx-2 mt-2"
          label="Search dictionary"
          hint="English, Japanese or Romaji"
          :disabled="searching || !$store.getters.online"
          @keyup.enter="searchInDictionary()"
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <v-progress-circular
                v-if="searching"
                indeterminate
                color="primary"
                size="24"
              ></v-progress-circular>
              <v-icon v-else color="primary" @click="searchInDictionary()">mdi-magnify</v-icon>
            </v-fade-transition>
          </template>
        </WanaKanaTextfield>
        <v-row dense>
          <v-col cols="12">
            <span>Quick Add Preferences</span>
          </v-col>
          <v-col cols="auto">
            <v-select
              outlined
              dense
              hide-details
              label="SRS Stage"
              :items="srsStages"
              v-model="srsStage"
              item-value="id"
              :item-text="itemText"
              class="text-caption"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
  <v-col v-for="(entry, index) in searchResults" :key="index" cols="12" lg="6">
    <v-card height="100%" class="d-flex flex-column my-1">
      <v-card-title>
        <div v-if="entry.word !== entry.reading">{{ entry.word }} 【{{ entry.reading }}】</div>
        <div v-else>{{ entry.word }}</div>
        <v-icon v-for="(voice, i) in $store.getters.japanesePollyVoices" :key="i"
          :color="voice.color"
          class="mx-1"
          @click="genAudio(entry.word, voice.name, voice.gender, voice.language, voice.service)"
        >
          mdi-volume-high
        </v-icon>
      </v-card-title>
      <v-card-subtitle>
        <v-chip v-for="(tag, ti) in setTags(entry.common, entry.jlpt, entry.wk)" :key="ti"
          x-small label outlined
          :color="tag.color"
          class="mx-1"
        >
          <v-icon v-if="tag.icon" x-small left>{{ tag.icon }}</v-icon>
          {{ tag.label }}
        </v-chip>
      </v-card-subtitle>
      <v-card-text>
        <v-radio-group v-model="radioGroup[index]" mandatory>
          <v-radio
            v-for="(sense, si) in entry.senses"
            :key="si"
            :label="`${si+1}. ${sense.definitions}`"
            :value="sense"
          >
            <template v-slot:label>
              <div>
                <div class="text-caption">{{ sense.pos }}</div>
                <div class="text--primary">{{ si+1 }}. {{ sense.definitions }}</div>
                <div v-if="sense.usuallyKanaOnly" class="text-caption">usually written using kana alone</div>
                <v-tooltip v-for="(voice, i) in $store.getters.englishPollyVoices" :key="i" bottom>
                  <template v-slot:activator="{on}">
                    <v-icon v-on="on" class="mx-1" @click="genAudio(sense.definitions, voice.name, voice.gender, voice.language, voice.service)">mdi-volume-high</v-icon>
                  </template>
                  {{ voice.nickname }}, {{ voice.language }}
                </v-tooltip>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" :loading="loading" @click="modifyItem(entry, radioGroup[index])">
          <v-icon left>mdi-pencil-outline</v-icon>
          Edit
        </v-btn>
        <v-btn depressed :disabled="entry.inDatabase" color="primary" :loading="loading" @click="addItem(index)">
          <v-icon left>{{ entry.inDatabase ? 'mdi-check' : 'mdi-plus' }}</v-icon>
          {{ entry.inDatabase ? 'Added' : 'Add' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
  <v-col v-if="!searching && searchSuccess" cols="12">
    <div class="text-center text-overline text--secondary">
      <strong>{{ searchResults.length }}</strong> words found |
      powered by <a href="https://jisho.org/forum/54fefc1f6e73340b1f160000-is-there-any-kind-of-search-api" target="_blank">Jisho API</a>
    </div>
  </v-col>
</v-row>
</template>

<script>
import WanaKanaTextfield from '@/components/WanaKanaTextfield.vue'
import { jishoparser } from '@/plugins/jishoparser'

export default {
  components: {
    WanaKanaTextfield
  },
  data () {
    return {
      searchId: 'searchDict',
      search: '',
      searching: false,
      searchSuccess: false,
      searchResults: [],
      radioGroup: [],
      loading: false,
      reloadRequired: false,
      srsStage: 1
    }
  },
  computed: {
    srsStages () {
      return this.$store.state.srsStages
      // return this.$store.state.srsStages.slice(1)
    }
  },
  methods: {
    searchInDictionary () {
      if (this.search) {
        this.search = this.search.trim()

        this.searching = true
        this.searchSuccess = false
        this.searchResults = []

        jishoparser.searchByKeyword(this.search).then(response => {
          this.searchResults = response
          this.searching = false
          this.searchSuccess = true
        })
      }
    },
    setTags (common, jlpt, wk) {
      const tagData = []
      if (common) tagData.push({ label: 'Common Word', color: 'green', icon: '' })
      if (jlpt) tagData.push({ label: `JLPT N${jlpt}`, color: 'secondary', icon: '' })
      if (wk) tagData.push({ label: `WaniKani LVL ${wk}`, color: 'lessons', icon: '' })
      return tagData
    },
    genAudio (text, voice, gender, language, service) {
      this.$store.dispatch('apiPost/synthesizeSpeech', { text, voice, gender, language, service })
    },
    itemText (item) {
      return `${this.$store.getters.srsStageName(item.id)} (next review: ${item.nextReviewText})`
    },
    modifyItem (entry, sense) {
      const vocab = {
        vocab: entry.word,
        vocab_kana: entry.reading,
        meaning: sense.definitions,
        sentence: '',
        sentence_meaning: '',
        pos: sense.pos,
        jlpt_level: entry.jlpt || 0,
        notes: ''
      }
      this.$emit('edit', vocab)
    },
    addItem (searchResultsIndex) {
      this.loading = true
      const vocab = this.searchResults[searchResultsIndex].word
      const reading = this.searchResults[searchResultsIndex].reading
      let meaning = this.radioGroup[searchResultsIndex].definitions
      let pos = this.radioGroup[searchResultsIndex].pos
      const jlpt = this.searchResults[searchResultsIndex].jlpt

      // Replacing all ; with , where required in case the user used the wrong separator
      if (meaning) meaning = meaning.replace(/;/g, ',')
      if (pos) pos = pos.replace(/;/g, ',')

      // Getting current time in UTC mode if vacation mode is off,
      // otherwise it's important that now = vacationStart
      const now = this.$store.getters['user/vacationMode'] ? this.$date.unix(this.$store.state.user.vacationStart).utc() : this.$date.utc()

      // Round up to nearest hour
      let reviewDue = now.minute() || now.second() || now.millisecond() ? now.add(1, 'hour').startOf('hour') : now.startOf('hour')

      // Adding hours until next review - 1 (!) depending on selected srs level
      reviewDue = reviewDue.add(this.srsStages.find(x => x.id === this.srsStage).nextReviewValue - 1, 'hours').unix()

      // Creating new custom vocab object
      const customVocab = {
        id: this.$store.state.vocabulary.customVocabLastIndex,
        vocab: vocab,
        vocab_kana: reading,
        meaning: meaning,
        pos: pos || null,
        jlpt_level: jlpt || 0,
        sentence: null,
        sentence_meaning: null,
        notes: null,
        archived: 0,
        prioritized: 0,
        streak_meaning: this.srsStage,
        streak_reading: this.srsStage,
        streak_jptojp: this.srsStage,
        due_meaning: reviewDue,
        due_reading: reviewDue,
        due_jptojp: reviewDue,
        examples: null,
        synonyms: null,
        readings: null,
        vocab_audio: null,
        sentence_audio: null,
        reviewed_meaning: 0,
        reviewed_reading: 0,
        reviewed_jptojp: 0,
        correct_meaning: 0,
        correct_reading: 0,
        correct_jptojp: 0
      }

      // Saving to database
      this.$store.dispatch('vocabulary/addCustom', customVocab)
      this.$snackbar.showMessage({ content: `Added「${customVocab.vocab}」to custom vocab!`, color: 'success' })
      this.loading = false
      this.reloadRequired = true

      this.searchResults[searchResultsIndex].inDatabase = true
    }
  },
  beforeDestroy () {
    if (this.reloadRequired) {
      this.$store.dispatch('requireReload')
    }
  }
}
</script>
