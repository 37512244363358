<template>
<v-app :style="{ background: $vuetify.theme.themes[theme].background }">
  <SnackbarComponent />
  <NavigationDrawer ref="navDrawer" v-if="isLoggedIn && $route.meta.showNavigationDrawer" />
  <v-system-bar v-if="!online" app dark color="warning" class="white--text">
    <v-icon color="white">
      {{ $store.state.settings.offlineMode ? 'mdi-wifi-off' : 'mdi-wifi-strength-alert-outline' }}
    </v-icon>
    <span v-html="systemBarText" />
  </v-system-bar>
  <v-app-bar app dense dark v-if="$vuetify.breakpoint.smAndDown && (isLoggedIn && $route.meta.showNavigationDrawer)" color="primary">
    <v-app-bar-nav-icon @click="openNavigationDrawer()"></v-app-bar-nav-icon>
    <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
    <QuickSettings v-if="$route.meta.title === 'Dashboard'" :floating="false" />
    <v-spacer></v-spacer>
    <v-btn icon large to="dashboard">
      <v-icon>mdi-home</v-icon>
    </v-btn>
  </v-app-bar>
  <v-main>
    <router-view></router-view>
    <!-- Show message to user when app is ready to be updated to a new version -->
    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      <v-icon left>mdi-update</v-icon>
      An update is available!
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          outlined
          :disabled="$store.state.vocabulary.isInitialising"
          @click="refreshApp">
          Reload page
        </v-btn>
      </template>
    </v-snackbar>
  </v-main>
  <v-footer v-if="$route.meta.showFooter">
    <v-container>
      <v-row no-gutters>
        <v-col class="text-left text-caption text--secondary">
          Made with ❤️ in 🇦🇹 🇯🇵
        </v-col>
        <v-col class="text-right text-caption text--secondary">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" left>{{ syncQueueSize > 0 ? 'mdi-cloud-refresh' : 'mdi-cloud-check' }}</v-icon>
              {{ syncQueueSize > 0 ? `${syncQueueSize} items in queue` : '' }}
            </template>
            {{ syncQueueSize === 0 ? 'All items synced' : '' }}
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</v-app>
</template>

<script>
import SnackbarComponent from '@/components/SnackbarComponent.vue'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
import update from '@/mixins/update'
import QuickSettings from '@/dashboard/QuickSettings.vue'

export default {
  name: 'App',
  components: {
    SnackbarComponent,
    NavigationDrawer,
    QuickSettings
  },
  mixins: [update],
  data () {
    return {}
  },
  computed: {
    online () {
      return this.$store.getters.online
    },
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    isLoggedIn () {
      return this.$store.state.loggedIn
    },
    syncQueueSize () {
      return this.$store.getters['api/itemsInQueue']
    },
    systemBarText () {
      return this.$store.state.settings.offlineMode
        ? 'You are using Torii in <strong>Offline Mode</strong>.'
        : 'It appears you\'re offline. Torii is now in <strong>Offline Mode</strong>.'
    }
  },
  methods: {
    updateOnlineStatus (status) {
      const { type } = status
      this.$store.dispatch('setOnlineStatus', type === 'online')
    },
    openNavigationDrawer () {
      this.$refs.navDrawer.toggle()
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        document.title = `${process.env.VUE_APP_TITLE} | ${to.meta.title}`
      }
    }
  },
  created () {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      // Stash the event so it can be triggered later.
      this.$store.dispatch('setInstallPrompt', e)
    })

    window.addEventListener('appinstalled', () => {
      this.$store.dispatch('setInstallPrompt', null)
    })
  },
  mounted () {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy () {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  }
}
</script>
