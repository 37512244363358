export const utils = {
  /**
   * Shuffles array in place. ES6 version
   * @param {Array} a items An array containing the items.
   */
  shuffle: (a) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]]
    }
    return a
  },
  // Returns keys/values from obj1 that are not identical to their counterparts in obj2
  objectDifference: (obj1, obj2) => {
    return Object.keys(obj1).reduce((diff, key) => {
      if (obj2[key] === obj1[key]) return diff
      return {
        ...diff,
        [key]: obj1[key]
      }
    }, {})
  },
  numberToCurrency: (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number).replace('.00', '')
  },
  capitalizeFirstLetter: (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  },
  optimalStringAlignmentDistance (s, t) {
    // Determine the "optimal" string-alignment distance between s and t
    if (!s || !t) return -1

    const m = s.length
    const n = t.length

    /* For all i and j, d[i][j] holds the string-alignment distance
     * between the first i characters of s and the first j characters of t.
     * Note that the array has (m+1)x(n+1) values.
     */
    const d = []
    for (let i = 0; i <= m; i++) {
      d[i] = []
      d[i][0] = i
    }
    for (let j = 0; j <= n; j++) {
      d[0][j] = j
    }

    // Determine substring distances
    let cost = 0
    for (let k = 1; k <= n; k++) {
      for (let l = 1; l <= m; l++) {
        cost = (s.charAt(l - 1) === t.charAt(k - 1)) ? 0 : 1 // Subtract one to start at strings' index zero instead of index one
        d[l][k] = Math.min(d[l][k - 1] + 1, // insertion
          Math.min(d[l - 1][k] + 1, // deletion
            d[l - 1][k - 1] + cost)) // substitution

        if (l > 1 && k > 1 && s.charAt(l - 1) === t.charAt(k - 2) && s.charAt(l - 2) === t.charAt(k - 1)) {
          d[l][k] = Math.min(d[l][k], d[l - 2][k - 2] + cost) // transposition
        }
      }
    }

    // Return the strings' distance
    return d[m][n]
  },
  numToWords (n) {
    let string = n.toString()
    let word
    // const and = 'and'

    /* Remove spaces and commas */
    string = string.replace(/[, ]/g, '')

    /* Is number zero? */
    if (parseInt(string) === 0) {
      return 'zero'
    }

    /* Array of units as words */
    const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen']

    /* Array of tens as words */
    const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

    /* Array of scales as words */
    const scales = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion']

    /* Split user argument into 3 digit chunks from right to left */
    let start = string.length
    const chunks = []
    while (start > 0) {
      const end = start
      chunks.push(string.slice((start = Math.max(0, start - 3)), end))
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    const chunksLen = chunks.length
    if (chunksLen > scales.length) {
      return ''
    }

    /* Stringify each integer in each chunk */
    const words = []
    for (let i = 0; i < chunksLen; i++) {
      const chunk = parseInt(chunks[i])
      if (chunk) {
        /* Split chunk into array of individual integers */
        const ints = chunks[i].split('').reverse().map(parseFloat)

        /* If tens integer is 1, i.e. 10, then add 10 to units integer */
        if (ints[1] === 1) {
          ints[0] += 10
        }

        /* Add scale word if chunk is not zero and array item exists */
        if ((word = scales[i])) {
          words.push(word)
        }

        /* Add unit word if array item exists */
        if ((word = units[ints[0]])) {
          words.push(word)
        }

        /* Add tens word if array item exists */
        if ((word = tens[ints[1]])) {
          words.push(word)
        }

        /* Add 'and' string after units or tens integer if: */
        if (ints[0] || ints[1]) {
          /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
          if (ints[2] || (!i && chunksLen)) {
            // words.push(and)
          }
        }

        /* Add hundreds word if array item exists */
        if ((word = units[ints[2]])) {
          words.push(word + ' hundred')
        }
      }
    }

    return words.reverse().join(' ')
  }
}
