import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/router'
import store from '@/store/store'
import vuetify from './plugins/vuetify'
import snackbar from '@/plugins/snackbar'
import * as wanakana from 'wanakana'
import { db } from '@/plugins/db'
import i18n from './i18n'
import VueTour from 'vue-tour'
import vars from '@/vars.js'

require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)
Vue.use(snackbar, { store })

Vue.config.productionTip = false
axios.defaults.withCredentials = true

// Add a request interceptor to the axios instance in order to
// cancel all requests if the user is not connected to the internet or
// offline mode is enabled.
const controller = new AbortController()
axios.interceptors.request.use((config) => {
  if (!store.getters.online) {
    const cfg = {
      ...config,
      signal: controller.signal
    }
    controller.abort()
    return cfg
  }
  return config
},
(error) => {
  return Promise.reject(error)
})

// global variables
Vue.prototype.$date = moment
Vue.prototype.$waka = wanakana

// Set up IndexedDB storage
Vue.prototype.$db = db

// Read theme prefs from local storage
const localSettings = JSON.parse(localStorage.getItem(vars.localStorageKeys.prefs))
if (localSettings) {
  vuetify.framework.theme.dark = localSettings.theme.darkTheme
  // Light
  if (localSettings.theme.light.primary) vuetify.framework.theme.themes.light.primary = localSettings.theme.light.primary
  if (localSettings.theme.light.secondary) vuetify.framework.theme.themes.light.secondary = localSettings.theme.light.secondary
  if (localSettings.theme.light.accent) vuetify.framework.theme.themes.light.accent = localSettings.theme.light.accent
  if (localSettings.theme.light.lessons) vuetify.framework.theme.themes.light.lessons = localSettings.theme.light.lessons
  if (localSettings.theme.light.reviews) vuetify.framework.theme.themes.light.reviews = localSettings.theme.light.reviews
  if (localSettings.theme.light.heiban) vuetify.framework.theme.themes.light.heiban = localSettings.theme.light.heiban
  if (localSettings.theme.light.atamadaka) vuetify.framework.theme.themes.light.atamadaka = localSettings.theme.light.atamadaka
  if (localSettings.theme.light.nakadaka) vuetify.framework.theme.themes.light.nakadaka = localSettings.theme.light.nakadaka
  if (localSettings.theme.light.odaka) vuetify.framework.theme.themes.light.odaka = localSettings.theme.light.odaka
  // Dark
  if (localSettings.theme.dark.primary) vuetify.framework.theme.themes.dark.primary = localSettings.theme.dark.primary
  if (localSettings.theme.dark.secondary) vuetify.framework.theme.themes.dark.secondary = localSettings.theme.dark.secondary
  if (localSettings.theme.dark.accent) vuetify.framework.theme.themes.dark.accent = localSettings.theme.dark.accent
  if (localSettings.theme.dark.lessons) vuetify.framework.theme.themes.dark.lessons = localSettings.theme.dark.lessons
  if (localSettings.theme.dark.reviews) vuetify.framework.theme.themes.dark.reviews = localSettings.theme.dark.reviews
  if (localSettings.theme.dark.heiban) vuetify.framework.theme.themes.dark.heiban = localSettings.theme.dark.heiban
  if (localSettings.theme.dark.atamadaka) vuetify.framework.theme.themes.dark.atamadaka = localSettings.theme.dark.atamadaka
  if (localSettings.theme.dark.nakadaka) vuetify.framework.theme.themes.dark.nakadaka = localSettings.theme.dark.nakadaka
  if (localSettings.theme.dark.odaka) vuetify.framework.theme.themes.dark.odaka = localSettings.theme.dark.odaka
}

// Creating main Vue instance
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
