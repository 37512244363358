import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import HomePage from '@/components/HomePage.vue'
import LandingPage from '@/login/LandingPage.vue'
import ResetPassword from '@/reset/ResetPassword.vue'
import DashboardPage from '@/dashboard/DashboardPage.vue'
import LessonsPage from '@/lessons/LessonsPage.vue'
import ReviewsPage from '@/reviews/ReviewsPage.vue'
import SummaryPage from '@/reviews/SummaryPage.vue'
import SelfStudy from '@/self-study/SelfStudy.vue'
import AddVocab from '@/vocab/AddVocab.vue'
import DecksPage from '@/decks/DecksPage.vue'
import BrowsePage from '@/browse/BrowsePage.vue'
import StatsPage from '@/stats/StatsPage.vue'
import SettingsPage from '@/settings/SettingsPage.vue'
import FAQPage from '@/faq/FAQPage.vue'
import ReleaseNotes from '@/release-notes/ReleaseNotes.vue'
import PrimePage from '../prime/PrimePage.vue'

Vue.use(VueRouter)
let init = false

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/lp',
    name: 'lp',
    component: LandingPage,
    meta: {
      title: 'Landing Page'
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPassword,
    meta: {
      title: 'Reset Password'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardPage,
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
      showNavigationDrawer: true,
      showFooter: true
    }
  },
  {
    path: '/lessons',
    name: 'lessons',
    component: LessonsPage,
    meta: {
      title: 'Lessons',
      requiresAuth: true
    }
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: ReviewsPage,
    meta: {
      title: 'Reviews',
      requiresAuth: true
    }
  },
  {
    path: '/reviews/summary',
    name: 'reviews-summary',
    component: SummaryPage,
    meta: {
      title: 'Reviews Summary',
      requiresAuth: true
    }
  },
  {
    path: '/selfstudy',
    name: 'self-study',
    component: SelfStudy,
    meta: {
      title: 'Self Study',
      requiresAuth: true,
      showNavigationDrawer: true
    }
  },
  {
    path: '/add',
    name: 'add',
    component: AddVocab,
    meta: {
      title: 'Add Vocabulary',
      requiresAuth: true,
      showNavigationDrawer: true
    }
  },
  {
    path: '/decks',
    name: 'decks',
    component: DecksPage,
    meta: {
      title: 'Decks',
      requiresAuth: true,
      showNavigationDrawer: true
    }
  },
  {
    path: '/browse',
    name: 'browse',
    component: BrowsePage,
    meta: {
      title: 'Browse Vocabulary',
      requiresAuth: true,
      showNavigationDrawer: true
    },
    props: true
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsPage,
    meta: {
      title: 'Statistics',
      requiresAuth: true,
      showNavigationDrawer: true
    },
    props: true
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPage,
    meta: {
      title: 'Settings',
      requiresAuth: true,
      showNavigationDrawer: true
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQPage,
    meta: {
      title: 'Knowledge Base',
      showNavigationDrawer: true
    }
  },
  {
    path: '/updates',
    name: 'updates',
    component: ReleaseNotes,
    meta: {
      title: 'Updates',
      showNavigationDrawer: true
    }
  },
  {
    path: '/prime',
    name: 'prime',
    component: PrimePage,
    meta: {
      title: 'Prime',
      requiresAuth: true,
      showNavigationDrawer: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // Always scroll to top of page when route changes
    return { x: 0, y: 0 }
  }
})

// Navigation Guard
router.beforeEach((to, from, next) => {
  if (to.name !== 'reset' && !init) {
    init = true
    next({ name: 'home' })
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    if (store.state.loggedIn) {
      next()
    } else {
      console.log('Navigation Guard: Not logged in.')
      Vue.prototype.$snackbar.showMessage({ content: 'You\'re not logged in.', color: 'error' })
    }
  } else {
    next()
  }
})

export default router
