<template>
  <v-container fluid fill-height>
    <v-row align="start" justify="start">
      <v-col>
        <v-card width="600px" color="rgb(255, 255, 255, 0.8)" class="mx-auto">
          <v-card-title>Change Password</v-card-title>
          <v-divider class="mx-5" />
          <v-card-text>
            <v-form v-model="valid" ref="pwResetForm">
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                label="New password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                @keyup.enter="changePassword"
              />
              <v-text-field
                v-model="passwordConfirm"
                :rules="passwordConfirmRules"
                :type="showPassword ? 'text' : 'password'"
                label="Confirm new password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                @keyup.enter="changePassword"
              />
            </v-form>
            <v-btn block color="info" :loading="loading" :disabled="loading" @click="changePassword">Change my Password</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ResetDialog ref="pwChangedDialog" />
  </v-container>
</template>

<script>
import ResetDialog from './ResetDialog.vue'

export default {
  components: {
    ResetDialog
  },
  data () {
    return {
      defaultErrorReqField: 'Field can\'t be empty',
      loading: false,
      valid: false,
      showPassword: false,
      token: '',
      password: '',
      passwordRules: [
        v => !!v || this.defaultErrorReqField,
        v => /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,64}$/.test(v) || '8-64 characters, at least 1 letter and 1 number'
      ],
      passwordConfirm: '',
      passwordConfirmRules: [
        v => !!v || this.defaultErrorReqField,
        v => v === this.password || 'Passwords must match'
      ]
    }
  },
  created () {
    this.token = this.$router.currentRoute.query.token
    // Redirecting to /home if reset token appears to be invalid
    if (!this.token || this.token.length !== 64) {
      this.$router.push('home')
    }
  },
  methods: {
    changePassword () {
      this.$refs.pwResetForm.validate()
      if (this.valid) {
        this.loading = true

        this.$store.dispatch('apiPost/resetSubmit', {
          token: this.token,
          password: this.password
        }).then(response => {
          this.$refs.pwChangedDialog.init(response.data.success)
        })
      }
    }
  }
}
</script>
