import vars from '@/vars.js'
import moment from 'moment'

const localStorageKeyName = vars.localStorageKeys.log
const logFileName = 'torii_srs_log.txt'
const logMaxLines = 2000

function writeToLocalStorage (text) {
  // Read log from localStorage
  const existingLog = JSON.parse(localStorage.getItem(localStorageKeyName)) || []
  // Add entry
  existingLog.push(`${moment.utc().format()}: ${text}`)
  // Limit log size
  if (existingLog.length > logMaxLines) existingLog.splice(0, existingLog.length - logMaxLines)
  // Write modified log to localStorage
  localStorage.setItem(localStorageKeyName, JSON.stringify(existingLog))
}

// define a new console
const console = (function (oldCons) {
  return {
    log: (text, color) => {
      // Print to console in dev mode, skip in production
      if (process.env.NODE_ENV !== 'production') oldCons.log(text, color || '')
      writeToLocalStorage(text)
    },
    info: text => {
      // Print to console
      oldCons.info(text)
    },
    warn: text => {
      // Print to console
      oldCons.warn(text)
      writeToLocalStorage('WARN|' + text)
    },
    error: text => {
      // Print to console
      oldCons.error(text)
      writeToLocalStorage('ERROR|' + text)
    }
  }
}(window.console))

// Then redefine the old console
window.console = console

export const logger = {
  generateLogFile: () => {
    // Store browser information (window.navigator) in new object
    const _navigator = {}
    for (const i in window.navigator) _navigator[i] = window.navigator[i]

    // Read existing log from localStorage
    const existingLog = JSON.parse(localStorage.getItem(localStorageKeyName)) || []

    // Store information in new object
    const blobObj = {
      'Browser Information': _navigator,
      Log: existingLog
    }

    // Generate and return file
    const blob = new Blob([JSON.stringify(blobObj, null, 2)], { type: 'text/plain;charset=utf-8' })
    const file = new File([blob], logFileName, { type: 'text/plain' })
    return file
  }
}
