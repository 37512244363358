<template>
<v-card class="rounded-t-0">
  <v-card-text>
    <v-form ref="form" v-model="valid" @submit.prevent="addItem">
      <v-row dense>
        <v-col cols="12" sm="6">
          <WanaKanaTextfield outlined clearable hide-details
            id="addVocab"
            v-model="vocab"
            maxlength="15"
            label="Vocabulary"
            placeholder="例えば"
            :rules="rules"
            :error="errVocab"
          >
            <template v-slot:append>
              <v-icon :color="pollyVoice.color" @click="genAudio(vocab)">mdi-volume-high</v-icon>
            </template>
          </WanaKanaTextfield>
        </v-col>
        <v-col cols="12" sm="6">
          <WanaKanaTextfield outlined clearable hide-details
            id="addReading"
            v-model="reading"
            maxlength="15"
            label="Reading"
            placeholder="たとえば"
            :rules="rules"
            :error="errReading"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field outlined clearable
            v-model="meaning"
            maxlength="150"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            label="Meaning"
            placeholder="separate, with, commas"
            hide-details
            :rules="rules"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <WanaKanaTextfield outlined clearable hide-details indicator
            id="addSentence"
            v-model="sentenceJp"
            maxlength="150"
            label="Context sentence"
            placeholder="例えば、これはペンパイナッポーアッポーペンです。"
            :rules="rules"
          >
            <template v-slot:append>
              <v-icon :color="pollyVoice.color" @click="genAudio(sentenceJp)">mdi-volume-high</v-icon>
            </template>
          </WanaKanaTextfield>
        </v-col>
        <v-col cols="12">
          <v-text-field outlined clearable
            v-model="sentenceEn"
            maxlength="150"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            label="Sentence meaning"
            placeholder="For example, this is a pen-pineapple-apple-pen."
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field outlined clearable
            v-model="pos"
            maxlength="100"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            label="Part of speech"
            placeholder="Adverb"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="jlptLevel"
            :items="jlptLevels"
            item-value="lv"
            item-text="title"
            label="JLPT level"
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            no-resize
            clearable
            rows="5"
            v-model="notes"
            counter="300"
            maxlength="300"
            label="Notes"
            placeholder="Add note or mnemonic"
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
      <v-divider class="mb-5"></v-divider>
      <v-select
        v-if="!editing"
        outlined
        label="SRS Stage"
        :items="srsStages"
        v-model="srsStage"
        item-value="id"
        :item-text="itemText"
      ></v-select>
      <v-alert type="success"
        v-model="alert"
        dismissible
        outlined
        text
      >
        {{ text }}
      </v-alert>

      <v-btn depressed color="primary" type="submit" :loading="loading" :disabled="!creationEnabled">
        <v-icon left>mdi-plus</v-icon>
        {{ submitBtnText }}
      </v-btn>
    </v-form>
  </v-card-text>
</v-card>
</template>

<script>
import WanaKanaTextfield from '@/components/WanaKanaTextfield.vue'

export default {
  props: {
    editing: Boolean,
    item: Object
  },
  components: {
    WanaKanaTextfield
  },
  data () {
    return {
      srsStage: 1,
      voiceCycle: 0,
      reloadRequired: false,
      loading: false,
      alert: false,
      text: '',
      valid: true,
      errVocab: false,
      errReading: false,
      vocab: '',
      reading: '',
      meaning: '',
      sentenceJp: '',
      sentenceEn: '',
      pos: '',
      jlptLevel: 0,
      notes: '',
      rules: [
        v => !!v || 'Field is required'
      ],
      jlptLevels: [
        { title: 'N5', lv: 5 },
        { title: 'N4', lv: 4 },
        { title: 'N3', lv: 3 },
        { title: 'N2', lv: 2 },
        { title: 'N1', lv: 1 },
        { title: 'None', lv: 0 }
      ]
    }
  },
  computed: {
    creationEnabled () {
      return this.editing ||
        this.$store.getters['vocabulary/customVocab'].length < this.$store.state.limits.customVocab.free ||
        this.$store.getters.proUser
    },
    srsStages () {
      return this.$store.state.srsStages
      // return this.$store.state.srsStages.slice(1)
    },
    pollyVoice () {
      return this.$store.getters.japanesePollyVoices[this.voiceCycle]
    },
    submitBtnText () {
      return this.editing ? 'Save changes' : 'Add to my words'
    }
  },
  methods: {
    genAudio (text) {
      if (text && text.trim()) {
        const voice = this.pollyVoice.name
        const gender = this.pollyVoice.gender
        const language = this.pollyVoice.language
        const service = this.pollyVoice.service

        this.$store.dispatch('apiPost/synthesizeSpeech', { text, voice, gender, language, service })

        this.voiceCycle++
        if (this.voiceCycle === this.$store.getters.japanesePollyVoices.length) {
          this.voiceCycle = 0
        }
      }
    },
    itemText (item) {
      return `${this.$store.getters.srsStageName(item.id)} (next review: ${item.nextReviewText})`
    },
    addItem () {
      this.loading = true
      if (this.vocab) this.vocab = this.$waka.toKana(this.vocab)
      if (this.reading) this.reading = this.$waka.toKana(this.reading)
      // if (this.sentenceJp) this.sentenceJp = this.$waka.toKana(this.sentenceJp)
      this.errVocab = !this.$waka.isJapanese(this.vocab)
      this.errReading = !this.$waka.isKana(this.reading)

      this.$refs.form.validate()
      if (this.valid) {
        // Replacing all ; with , where required in case the user used the wrong separator
        if (this.meaning) this.meaning = this.meaning.replace(/;/g, ',')
        if (this.pos) this.pos = this.pos.replace(/;/g, ',')

        // Getting current time in UTC mode if vacation mode is off,
        // otherwise it's important that now = vacationStart
        const now = this.$store.getters['user/vacationMode'] ? this.$date.unix(this.$store.state.user.vacationStart).utc() : this.$date.utc()

        // Round up to nearest hour
        let reviewDue = now.minute() || now.second() || now.millisecond() ? now.add(1, 'hour').startOf('hour') : now.startOf('hour')

        // Adding hours until next review - 1 (!) depending on selected srs level
        reviewDue = reviewDue.add(this.srsStages.find(x => x.id === this.srsStage).nextReviewValue - 1, 'hours').unix()

        // Creating new custom vocab object
        const customVocab = {
          id: this.editing ? this.item.id : this.$store.state.vocabulary.customVocabLastIndex,
          vocab: this.vocab,
          vocab_kana: this.reading,
          meaning: this.meaning,
          pos: this.pos || null,
          jlpt_level: this.jlptLevel || 0,
          sentence: this.sentenceJp || null,
          sentence_meaning: this.sentenceEn || null,
          notes: this.notes || null,
          archived: this.editing ? this.item.archived : 0,
          prioritized: this.editing ? this.item.prioritized : 0,
          streak_meaning: this.editing ? this.item.streak_meaning : this.srsStage,
          streak_reading: this.editing ? this.item.streak_reading : this.srsStage,
          streak_jptojp: this.editing ? this.item.streak_jptojp : this.srsStage,
          due_meaning: this.editing ? this.item.due_meaning : reviewDue,
          due_reading: this.editing ? this.item.due_reading : reviewDue,
          due_jptojp: this.editing ? this.item.due_jptojp : reviewDue,
          examples: null,
          synonyms: null,
          readings: null,
          vocab_audio: null,
          sentence_audio: null,
          reviewed_meaning: this.editing ? this.item.reviewed_meaning : 0,
          reviewed_reading: this.editing ? this.item.reviewed_reading : 0,
          reviewed_jptojp: this.editing ? this.item.reviewed_jptojp : 0,
          correct_meaning: this.editing ? this.item.correct_meaning : 0,
          correct_reading: this.editing ? this.item.correct_reading : 0,
          correct_jptojp: this.editing ? this.item.correct_jptojp : 0
        }

        // Saving to database
        if (!this.editing) {
          this.$store.dispatch('vocabulary/addCustom', customVocab)
          this.$refs.form.reset()
          this.srsStage = 1

          this.text = `Added「${customVocab.vocab}」to custom vocab!`
          this.alert = true
        } else {
          this.$store.dispatch('vocabulary/modifyCustom', customVocab)

          this.text = `Custom item「${customVocab.vocab}」was modified!`
          this.alert = true
        }

        this.loading = false
        this.reloadRequired = true
      } else {
        this.loading = false
      }
    },
    setValues () {
      this.alert = false
      this.text = ''

      if (this.item) {
        this.srsStage = 1
        this.vocab = this.item.vocab
        this.reading = this.item.vocab_kana
        this.meaning = this.item.meaning
        this.sentenceJp = this.item.sentence
        this.sentenceEn = this.item.sentence_meaning
        this.pos = this.item.pos
        this.jlptLevel = this.item.jlpt_level
        this.notes = this.item.notes
      }
    }
  },
  watch: {
    item () {
      this.setValues()
    }
  },
  mounted () {
    this.setValues()
  },
  beforeDestroy () {
    if (this.reloadRequired) {
      this.$store.dispatch('requireReload')
    }
  }
}
</script>
