<template>
<v-dialog v-model="show" persistent scrollable>
  <v-card>
    <v-card-title>Appearance</v-card-title>
    <v-divider class="mb-3"></v-divider>
    <v-card-text>
      <v-row>
        <v-col>
          <v-switch
            v-model="quickSettings"
            dense inset hide-details
            label="Show Quick Settings on Dashboard"
            class="text--primary text-subtitle-2"
          ></v-switch>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <v-row no-gutters>
        <v-col cols="12">
          <span class="text--primary text-subtitle-2">Theme</span>
        </v-col>
        <v-col>
          <v-item-group v-model="darkTheme" mandatory>
            <v-row>
              <v-col cols="6">
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    flat
                    :dark="active"
                    height="50"
                    :color="active ? 'primary' : 'dummyColor'"
                    class="d-flex align-center justify-center text-subtitle-1"
                    @click="toggle"
                  >
                    Light
                    <v-icon right>mdi-white-balance-sunny</v-icon>
                  </v-card>
                </v-item>
              </v-col>
              <v-col cols="6">
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    flat
                    :dark="active"
                    height="50"
                    :color="active ? 'primary' : 'dummyColor'"
                    class="d-flex align-center justify-center text-subtitle-1"
                    @click="toggle"
                  >
                    Dark
                    <v-icon right>mdi-weather-night</v-icon>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <v-row>
        <v-col v-for="(entry, i) in variables" :key="i" cols="6">
          <div class="text--primary text-subtitle-2">{{ entry.title }}</div>
          <v-card flat :color="darkTheme ? entry.darkColor : entry.lightColor" height="35" @click="showColorPicker(entry)"></v-card>
          <v-btn plain block small color="primary" @click="setToDefault(entry)">Set to default</v-btn>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <v-row no-gutters>
        <v-col cols="12">
          <v-switch
            v-model="pitchAccentColorsEnable"
            dense inset hide-details
            label="Enable Pitch Accent Pattern Colors"
            class="text--primary text-subtitle-2"
          ></v-switch>
        </v-col>
        <v-col cols="12">
          <v-switch
            v-model="pitchAccentColorsDisableInLessons"
            dense inset hide-details
            label="Disable in Lessons"
            :disabled="!pitchAccentColorsEnable"
            class="text--primary"
          ></v-switch>
        </v-col>
        <v-col cols="12">
          <v-switch
            v-model="pitchAccentColorsDisableInReviews"
            dense inset hide-details
            label="Disable in Reviews"
            :disabled="!pitchAccentColorsEnable"
            class="text--primary"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(entry, i) in pitchAccentColors" :key="i" cols="6">
          <KanjiFuriganaCompound :kanji="entry.title.kanji" :furigana="entry.title.furigana" />
          <v-card flat :color="darkTheme ? entry.darkColor : entry.lightColor" height="35" @click="showColorPicker(entry)"></v-card>
          <v-btn plain block small color="primary" @click="setToDefault(entry)">Set to default</v-btn>
        </v-col>
        </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="discardChanges">Discard Changes</v-btn>
      <v-btn depressed color="primary" @click="saveTheme">Save Theme</v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog width="300" v-model="colorPicker">
    <v-card>
        <v-color-picker
          v-model="colorPickerModel"
          dot-size="25"
          mode="hexa"
          show-swatches
          swatches-max-height="200"
        ></v-color-picker>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn depressed block color="primary" @click="selectColor">Select color</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-dialog>
</template>

<script>
import KanjiFuriganaCompound from '@/components/KanjiFuriganaCompound.vue'

export default {
  components: {
    KanjiFuriganaCompound
  },
  data () {
    return {
      show: false,
      colorPicker: false,
      colorPickerModel: '',
      colors: {
        light: {
          primary: this.$vuetify.theme.themes.light.primary,
          secondary: this.$vuetify.theme.themes.light.secondary,
          accent: this.$vuetify.theme.themes.light.accent,
          lessons: this.$vuetify.theme.themes.light.lessons,
          reviews: this.$vuetify.theme.themes.light.reviews
        },
        dark: {
          primary: this.$vuetify.theme.themes.dark.primary,
          secondary: this.$vuetify.theme.themes.dark.secondary,
          accent: this.$vuetify.theme.themes.dark.accent,
          lessons: this.$vuetify.theme.themes.dark.lessons,
          reviews: this.$vuetify.theme.themes.dark.reviews
        }
      },
      selected: ''
    }
  },
  computed: {
    theme () {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    darkTheme: {
      get () {
        return this.$vuetify.theme.dark ? 1 : 0
      },
      set (val) {
        this.$vuetify.theme.dark = !!val
        this.$store.dispatch('settings/updateLocal', {})
      }
    },
    quickSettings: {
      get () {
        return this.$store.state.settings.showQuickSettings
      },
      set (enabled) {
        this.$store.dispatch('settings/updateLocal', { showQuickSettings: enabled })
      }
    },
    pitchAccentColorsEnable: {
      get () {
        return this.$store.state.settings.theme[this.theme].pitchAccentColors.enable
      },
      set (enabled) {
        this.$store.dispatch('settings/updateLocal', { theme: { [this.theme]: { pitchAccentColors: { enable: enabled } } } })
      }
    },
    pitchAccentColorsDisableInLessons: {
      get () {
        return this.$store.state.settings.theme[this.theme].pitchAccentColors.disableInLessons
      },
      set (enabled) {
        this.$store.dispatch('settings/updateLocal', { theme: { [this.theme]: { pitchAccentColors: { disableInLessons: enabled } } } })
      }
    },
    pitchAccentColorsDisableInReviews: {
      get () {
        return this.$store.state.settings.theme[this.theme].pitchAccentColors.disableInReviews
      },
      set (enabled) {
        this.$store.dispatch('settings/updateLocal', { theme: { [this.theme]: { pitchAccentColors: { disableInReviews: enabled } } } })
      }
    },
    variables () {
      return [
        { title: 'Primary Color', value: 'primary', lightColor: this.$vuetify.theme.themes.light.primary, darkColor: this.$vuetify.theme.themes.dark.primary },
        { title: 'Secondary Color', value: 'secondary', lightColor: this.$vuetify.theme.themes.light.secondary, darkColor: this.$vuetify.theme.themes.dark.secondary },
        { title: 'Accent Color', value: 'accent', lightColor: this.$vuetify.theme.themes.light.accent, darkColor: this.$vuetify.theme.themes.dark.accent },
        { title: 'Lessons Color', value: 'lessons', lightColor: this.$vuetify.theme.themes.light.lessons, darkColor: this.$vuetify.theme.themes.dark.lessons },
        { title: 'Reviews Color', value: 'reviews', lightColor: this.$vuetify.theme.themes.light.reviews, darkColor: this.$vuetify.theme.themes.dark.reviews }
      ]
    },
    pitchAccentColors () {
      return [
        { title: { kanji: '平板', furigana: 'へいばん' }, value: 'heiban', lightColor: this.$vuetify.theme.themes.light.heiban, darkColor: this.$vuetify.theme.themes.dark.heiban },
        { title: { kanji: '頭高', furigana: 'あたまだか' }, value: 'atamadaka', lightColor: this.$vuetify.theme.themes.light.atamadaka, darkColor: this.$vuetify.theme.themes.dark.atamadaka },
        { title: { kanji: '中高', furigana: 'なかだか' }, value: 'nakadaka', lightColor: this.$vuetify.theme.themes.light.nakadaka, darkColor: this.$vuetify.theme.themes.dark.nakadaka },
        { title: { kanji: '尾高', furigana: 'おだか' }, value: 'odaka', lightColor: this.$vuetify.theme.themes.light.odaka, darkColor: this.$vuetify.theme.themes.dark.odaka }
      ]
    }
  },
  watch: {
    show () {
      for (const key of Object.keys(this.colors.light)) {
        this.colors.light[key] = this.$vuetify.theme.themes.light[key]
      }
      for (const key of Object.keys(this.colors.dark)) {
        this.colors.dark[key] = this.$vuetify.theme.themes.dark[key]
      }
    }
  },
  methods: {
    showColorPicker (entry) {
      this.colorPickerModel = this.darkTheme ? entry.darkColor : entry.lightColor
      this.selected = entry.value
      this.colorPicker = true
    },
    selectColor () {
      if (this.darkTheme) this.$vuetify.theme.themes.dark[this.selected] = this.colorPickerModel
      else this.$vuetify.theme.themes.light[this.selected] = this.colorPickerModel
      this.colorPicker = false
    },
    setToDefault (entry) {
      const sel = entry.value
      if (this.darkTheme) this.$vuetify.theme.themes.dark[sel] = this.$vuetify.theme.themes.dark[`${sel}Default`]
      else this.$vuetify.theme.themes.light[sel] = this.$vuetify.theme.themes.light[`${sel}Default`]
    },
    discardChanges () {
      for (const [key, value] of Object.entries(this.colors.light)) {
        this.$vuetify.theme.themes.light[key] = value
      }
      for (const [key, value] of Object.entries(this.colors.dark)) {
        this.$vuetify.theme.themes.dark[key] = value
      }
      this.show = false
    },
    saveTheme () {
      this.$store.dispatch('settings/updateLocal', {})
      this.show = false
    }
  }
}
</script>
