import Vuetify from '@/plugins/vuetify'
import vars from '@/vars.js'
import { mergeWith } from 'lodash-es'
const localStorageKeyName = vars.localStorageKeys.prefs

const state = {
  timezone: 0,
  time24HourFormat: 0,
  studyMode: {
    id: 0,
    includeKanaOnly: false,
    includeWk: false
  },
  reviewMode: -1,
  reviewAudioMode: 0,
  dailyLimit: 0,
  lessonsBatchSize: 5,
  lessonsPitchAccent: 1,
  lessonsHideKana: 0,
  lessonsHideSentence: 0,
  lessonsAudioAutoplay: 0,
  reviewsFontRandomizer: 0,
  reviewsLightningMode: 1,
  reviewsAutoSubmit: 0,
  reviewsAutoExpand: 0,
  reviewsAutoExpandCorrect: 0,
  reviewsPitchAccent: 0,
  reviewsHideKana: 0,
  reviewsKanaReveal: 0,
  reviewsVocabAudioAutoplay: 0,
  reviewsSentenceAudioAutoplay: 0,
  reviewsAudioPlayAtStart: 0,
  reviewsAudioPlayAtEnd: 0,
  reviewsAudioPlayOnIncorrect: 0,
  placeholder: '*',
  // Local settings
  offlineMode: false,
  hideAnnouncement: 0,
  hideMaintenance: '',
  navDrawerMini: true,
  showQuickSettings: true,
  flashcardMode: false,
  forecastChartSlider: 12,
  japaneseFonts: [],
  reviewsReorder: {
    sortReviewModes: false,
    reviewModes: [1, 2, 4],
    sortSrsStages: false,
    sortSrsStagesAscending: true,
    levelPriority: true
  },
  selfStudy: {
    reviewModes: [1, 2],
    srsStages: [1, 2, 3, 4],
    learnedVocab: true,
    customVocab: false,
    archivedVocab: false,
    flashcardMode: false
  },
  theme: {
    light: {
      pitchAccentColors: {
        enable: false,
        disableInLessons: false,
        disableInReviews: false
      }
    },
    dark: {
      pitchAccentColors: {
        enable: false,
        disableInLessons: false,
        disableInReviews: false
      }
    }
  },
  browse: {
    panelsExpanded: undefined,
    vocabListSelected: undefined,
    jlptLevels: undefined,
    reviewModes: undefined,
    srsStages: undefined,
    pitchAccent: true
  }
}

const getters = {
  decks (state, getters, rootState, rootGetters) {
    return [
      { id: 0, title: 'Core 10k', description: 'Default choice. Contains all vocabulary words of the decks below and more.', count: rootGetters['vocabulary/statistics'].lessons.core10kCount },
      { id: 6, title: 'WaniKani Supplement', description: 'Only contains vocabulary words that you won\'t find on WaniKani.com.', count: rootGetters['vocabulary/statistics'].lessons.wkCount },
      { id: 7, title: 'Kana-only', description: 'No Kanji.', count: rootGetters['vocabulary/statistics'].lessons.kanaOnlyCount },
      { id: 5, title: 'JLPT N5', description: '', count: rootGetters['vocabulary/statistics'].lessons.jlpt5Count },
      { id: 4, title: 'JLPT N4', description: '', count: rootGetters['vocabulary/statistics'].lessons.jlpt4Count },
      { id: 3, title: 'JLPT N3', description: '', count: rootGetters['vocabulary/statistics'].lessons.jlpt3Count },
      { id: 2, title: 'JLPT N2', description: '', count: rootGetters['vocabulary/statistics'].lessons.jlpt2Count },
      { id: 1, title: 'JLPT N1', description: '', count: rootGetters['vocabulary/statistics'].lessons.jlpt1Count }
    ]
  }
}

const mutations = {
  init (state, obj) {
    for (const item in obj) {
      state[item] = obj[item]
    }

    // Read settings from local storage
    const localSettings = JSON.parse(localStorage.getItem(localStorageKeyName))

    // Theme prefs
    if (localSettings) {
      const themes = ['light', 'dark']
      for (const theme of themes) {
        if (localSettings.theme[theme].vars !== undefined) {
          state.theme[theme].pitchAccentColors.enable = localSettings.theme[theme].vars.pitchAccentColorsEnable
          state.theme[theme].pitchAccentColors.disableInLessons = localSettings.theme[theme].vars.pitchAccentColorsDisableInLessons
          state.theme[theme].pitchAccentColors.disableInReviews = localSettings.theme[theme].vars.pitchAccentColorsDisableInReviews
        }
      }
      // Dashboard prefs
      if (localSettings.navDrawerMini !== undefined) state.navDrawerMini = localSettings.navDrawerMini
      if (localSettings.showQuickSettings !== undefined) state.showQuickSettings = localSettings.showQuickSettings
      if (localSettings.flashcardMode !== undefined) state.flashcardMode = localSettings.flashcardMode
      if (localSettings.forecastChartSlider !== undefined) state.forecastChartSlider = localSettings.forecastChartSlider
      // Reviews Reorder prefs
      if (localSettings.reviewsReorder.sortReviewModes !== undefined) state.reviewsReorder.sortReviewModes = localSettings.reviewsReorder.sortReviewModes
      if (localSettings.reviewsReorder.reviewModes !== undefined) state.reviewsReorder.reviewModes = localSettings.reviewsReorder.reviewModes
      if (localSettings.reviewsReorder.sortSrsStages !== undefined) state.reviewsReorder.sortSrsStages = localSettings.reviewsReorder.sortSrsStages
      if (localSettings.reviewsReorder.sortSrsStagesAscending !== undefined) state.reviewsReorder.sortSrsStagesAscending = localSettings.reviewsReorder.sortSrsStagesAscending
      if (localSettings.reviewsReorder.levelPriority !== undefined) state.reviewsReorder.levelPriority = localSettings.reviewsReorder.levelPriority
      // Self Study prefs
      if (localSettings.selfStudy.reviewModes !== undefined) state.selfStudy.reviewModes = localSettings.selfStudy.reviewModes
      if (localSettings.selfStudy.srsStages !== undefined) state.selfStudy.srsStages = localSettings.selfStudy.srsStages
      if (localSettings.selfStudy.learnedVocab !== undefined) state.selfStudy.learnedVocab = localSettings.selfStudy.learnedVocab
      if (localSettings.selfStudy.customVocab !== undefined) state.selfStudy.customVocab = localSettings.selfStudy.customVocab
      if (localSettings.selfStudy.archivedVocab !== undefined) state.selfStudy.archivedVocab = localSettings.selfStudy.archivedVocab
      if (localSettings.selfStudy.flashcardMode !== undefined) state.selfStudy.flashcardMode = localSettings.selfStudy.flashcardMode
      // Other
      if (localSettings.offlineMode !== undefined) state.offlineMode = localSettings.offlineMode
      if (localSettings.hideAnnouncement !== undefined) state.hideAnnouncement = localSettings.hideAnnouncement
      if (localSettings.hideMaintenance !== undefined) state.hideMaintenance = localSettings.hideMaintenance
      if (localSettings.japaneseFonts !== undefined) state.japaneseFonts = localSettings.japaneseFonts
    }

    // Browse
    const browseExists = localSettings !== null && localSettings.browse !== undefined
    state.browse.panelsExpanded = browseExists && localSettings.browse.panelsExpanded ? localSettings.browse.panelsExpanded : [0]
    state.browse.vocabListSelected = browseExists && localSettings.browse.vocabListSelected ? localSettings.browse.vocabListSelected : 'all'
    state.browse.jlptLevels = browseExists && localSettings.browse.jlptLevels ? localSettings.browse.jlptLevels : [0, 1, 2, 3, 4, 5]
    state.browse.reviewModes = browseExists && localSettings.browse.reviewModes ? localSettings.browse.reviewModes : this.state.reviewModes.filter(mode => mode.id !== 3).map(t => t.id)
    state.browse.srsStages = browseExists && localSettings.browse.srsStages ? localSettings.browse.srsStages : this.state.srsStages.map(e => e.id)
    state.browse.pitchAccent = browseExists && localSettings.browse.pitchAccent ? localSettings.browse.pitchAccent : true

    if (!localSettings) this.commit('settings/updateLocal', {})

    console.log('%cSettings loaded.', 'color: green')
  },
  update (state, obj) {
    for (const item in obj) {
      if (typeof obj[item] === 'object' && obj[item] !== null && !Array.isArray(obj[item])) {
        for (const subitem in obj[item]) {
          if (typeof obj[item][subitem] === 'boolean') obj[item][subitem] = obj[item][subitem] ? 1 : 0
          state[item][subitem] = obj[item][subitem]
        }
      } else {
        if (typeof obj[item] === 'boolean') obj[item] = obj[item] ? 1 : 0
        state[item] = obj[item]
      }
    }
  },
  updateLocal (state, obj) {
    // Update vuex state variables
    if (obj) {
      for (const item in obj) {
        if (typeof obj[item] === 'object' && obj[item] !== null && !Array.isArray(obj[item])) {
          mergeWith(state[item], obj[item], (a, b) => Array.isArray(b) ? b : undefined)
        } else {
          state[item] = obj[item]
        }
      }
    }

    // Save in local storage
    const set = {
      theme: {
        darkTheme: Vuetify.framework.theme.dark,
        light: {
          vars: {
            pitchAccentColorsEnable: state.theme.light.pitchAccentColors.enable,
            pitchAccentColorsDisableInLessons: state.theme.light.pitchAccentColors.disableInLessons,
            pitchAccentColorsDisableInReviews: state.theme.light.pitchAccentColors.disableInReviews
          },
          primary: Vuetify.framework.theme.themes.light.primary,
          secondary: Vuetify.framework.theme.themes.light.secondary,
          accent: Vuetify.framework.theme.themes.light.accent,
          lessons: Vuetify.framework.theme.themes.light.lessons,
          reviews: Vuetify.framework.theme.themes.light.reviews,
          heiban: Vuetify.framework.theme.themes.light.heiban,
          atamadaka: Vuetify.framework.theme.themes.light.atamadaka,
          nakadaka: Vuetify.framework.theme.themes.light.nakadaka,
          odaka: Vuetify.framework.theme.themes.light.odaka
        },
        dark: {
          vars: {
            pitchAccentColorsEnable: state.theme.dark.pitchAccentColors.enable,
            pitchAccentColorsDisableInLessons: state.theme.dark.pitchAccentColors.disableInLessons,
            pitchAccentColorsDisableInReviews: state.theme.dark.pitchAccentColors.disableInReviews
          },
          primary: Vuetify.framework.theme.themes.dark.primary,
          secondary: Vuetify.framework.theme.themes.dark.secondary,
          accent: Vuetify.framework.theme.themes.dark.accent,
          lessons: Vuetify.framework.theme.themes.dark.lessons,
          reviews: Vuetify.framework.theme.themes.dark.reviews,
          heiban: Vuetify.framework.theme.themes.dark.heiban,
          atamadaka: Vuetify.framework.theme.themes.dark.atamadaka,
          nakadaka: Vuetify.framework.theme.themes.dark.nakadaka,
          odaka: Vuetify.framework.theme.themes.dark.odaka
        }
      },
      offlineMode: state.offlineMode,
      hideAnnouncement: state.hideAnnouncement,
      hideMaintenance: state.hideMaintenance,
      navDrawerMini: state.navDrawerMini,
      showQuickSettings: state.showQuickSettings,
      flashcardMode: state.flashcardMode,
      forecastChartSlider: state.forecastChartSlider,
      japaneseFonts: state.japaneseFonts,
      reviewsReorder: {
        sortReviewModes: state.reviewsReorder.sortReviewModes,
        reviewModes: state.reviewsReorder.reviewModes,
        sortSrsStages: state.reviewsReorder.sortSrsStages,
        sortSrsStagesAscending: state.reviewsReorder.sortSrsStagesAscending,
        levelPriority: state.reviewsReorder.levelPriority
      },
      selfStudy: {
        reviewModes: state.selfStudy.reviewModes,
        srsStages: state.selfStudy.srsStages,
        learnedVocab: state.selfStudy.learnedVocab,
        customVocab: state.selfStudy.customVocab,
        archivedVocab: state.selfStudy.archivedVocab,
        flashcardMode: state.selfStudy.flashcardMode
      },
      browse: {
        panelsExpanded: state.browse.panelsExpanded,
        vocabListSelected: state.browse.vocabListSelected,
        jlptLevels: state.browse.jlptLevels,
        reviewModes: state.browse.reviewModes,
        srsStages: state.browse.srsStages,
        pitchAccent: state.browse.pitchAccent
      }
    }
    localStorage.setItem(localStorageKeyName, JSON.stringify(set))
  }
}

const actions = {
  init (context, settings) {
    const obj = {
      timezone: settings.timezone || 0,
      time24HourFormat: settings.time_24_hours,
      studyMode: {
        id: settings.deck,
        includeKanaOnly: settings.deck_kana,
        includeWk: settings.deck_wk
      },
      reviewMode: settings.review_mode,
      reviewAudioMode: settings.review_audio_mode,
      dailyLimit: settings.study_limit,
      lessonsBatchSize: settings.study_batch_size || context.state.lessonsBatchSize,
      lessonsPitchAccent: settings.study_accent,
      lessonsHideKana: settings.study_hide_kana,
      lessonsHideSentence: settings.study_hide_sentence,
      lessonsAudioAutoplay: settings.study_autoplay_audio,
      reviewsFontRandomizer: settings.review_rand_font,
      reviewsLightningMode: settings.review_lightning_mode,
      reviewsAutoSubmit: settings.review_hyperjump_mode,
      reviewsAutoExpand: settings.review_auto_expand,
      reviewsAutoExpandCorrect: settings.review_auto_expand_correct,
      reviewsPitchAccent: settings.review_accent,
      reviewsHideKana: settings.review_hide_kana,
      reviewsKanaReveal: settings.review_reveal_kana,
      reviewsVocabAudioAutoplay: settings.review_autoplay_audio,
      reviewsSentenceAudioAutoplay: settings.review_autoplay_audio_sentence,
      reviewsAudioPlayAtStart: settings.review_audio_at_start,
      reviewsAudioPlayAtEnd: settings.review_audio_at_end,
      reviewsAudioPlayOnIncorrect: settings.review_audio_on_wrong_answer
    }

    context.commit('init', obj)

    // Resetting daily lesson limit if required
    const today = this._vm.$date.utc().add(state.timezone, 'hours').toISOString().substr(0, 10)
    if (context.rootState.user.dailyStats.length === 0 || context.rootState.user.dailyStats[context.rootState.user.dailyStats.length - 1].date !== today) {
      console.log('First login today.')
      const dailyStats = Array.from(context.rootState.user.dailyStats)
      dailyStats.push({ date: today, lessons: 0, reviews: 0, selfStudy: 0, recentLessons: 0, recentMistakes: 0 })
      context.dispatch('user/update', { dailyStats }, { root: true })
    }
  },
  update (context, obj) {
    context.commit('update', obj)

    const set = {
      timezone: context.state.timezone,
      time_24_hours: context.state.time24HourFormat,
      deck: context.state.studyMode.id,
      deck_kana: context.state.studyMode.includeKanaOnly,
      deck_wk: context.state.studyMode.includeWk,
      review_mode: context.state.reviewMode,
      review_audio_mode: context.state.reviewAudioMode,
      study_limit: context.state.dailyLimit,
      study_batch_size: context.state.lessonsBatchSize,
      study_accent: context.state.lessonsPitchAccent,
      study_hide_kana: context.state.lessonsHideKana,
      study_hide_sentence: context.state.lessonsHideSentence,
      study_autoplay_audio: context.state.lessonsAudioAutoplay,
      review_rand_font: context.state.reviewsFontRandomizer,
      review_lightning_mode: context.state.reviewsLightningMode,
      review_hyperjump_mode: context.state.reviewsAutoSubmit,
      review_auto_expand: context.state.reviewsAutoExpand,
      review_auto_expand_correct: context.state.reviewsAutoExpandCorrect,
      review_accent: context.state.reviewsPitchAccent,
      review_hide_kana: context.state.reviewsHideKana,
      review_reveal_kana: context.state.reviewsKanaReveal,
      review_autoplay_audio: context.state.reviewsVocabAudioAutoplay,
      review_autoplay_audio_sentence: context.state.reviewsSentenceAudioAutoplay,
      review_audio_at_start: context.state.reviewsAudioPlayAtStart,
      review_audio_at_end: context.state.reviewsAudioPlayAtEnd,
      review_audio_on_wrong_answer: context.state.reviewsAudioPlayOnIncorrect
    }

    // Saving to db
    this._vm.$db.settings.update(0, set).then(updated => {
      if (updated) {
        const now = this._vm.$date.utc().unix()
        this._vm.$db.sync.update(0, { settings: now })
        // Save to server
        context.dispatch('apiPost/updateSettings', null, { root: true })
      }
    })
  },
  updateLocal (context, obj) {
    context.commit('updateLocal', obj)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
