<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :small="$vuetify.breakpoint.mdAndDown"
        depressed
        color="red"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Sign up
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Sign up for Beta</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="email"
            label="Enter your Torii SRS email address"
            :rules="emailRules"
          ></v-text-field>
          <v-select
            v-model="selectBrowsers"
            :items="['Chrome', 'Safari', 'Firefox', 'Edge', 'Opera', 'Samsung Internet', 'Other']"
            label="Which Browser(s) do you use regularly?"
            multiple
            :rules="selectRules"
          ></v-select>
          <v-checkbox
            v-model="cbDesktop"
            :rules="checkboxRules"
            label="I want to use Torii on my Desktop or Laptop computer.">
          </v-checkbox>
          <v-expand-transition>
            <v-select
              v-if="cbDesktop"
              v-model="selectDesktopOS"
              :items="['Windows', 'macOS', 'Chrome OS', 'Linux', 'Other']"
              label="Select your Operating System(s)"
              multiple
              :rules="selectRules"
            ></v-select>
          </v-expand-transition>
          <v-checkbox
            v-model="cbMobile"
            :rules="checkboxRules"
            label="I want to use Torii on my Tablet or Smartphone.">
          </v-checkbox>
          <v-expand-transition>
            <v-select
              v-if="cbMobile"
              v-model="selectMobileOS"
              :items="['Android', 'iOS', 'Windows', 'Chrome OS', 'Other']"
              label="Select your Operating System(s)"
              multiple
              :rules="selectRules"
            ></v-select>
          </v-expand-transition>
        </v-form>
        <v-alert v-if="submitResponse" outlined text :type="submitSuccess ? 'success' : 'error'" class="multi-line">{{ submitResponse }}</v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          @click="closeDialog()"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="sending"
          :disabled="submitSuccess"
          @click="submit()"
        >
          Request Invite
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      valid: false,
      email: '',
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid'
      ],
      cbDesktop: false,
      cbMobile: false,
      selectBrowsers: [],
      selectDesktopOS: [],
      selectMobileOS: [],
      selectRules: [
        v => !!v || '',
        v => v.length > 0 || ''
      ],
      sending: false,
      submitSuccess: false,
      submitResponse: ''
    }
  },
  computed: {
    checkboxRules () {
      return [(this.cbDesktop || this.cbMobile) || '']
    }
  },
  methods: {
    submit () {
      this.validate()
      this.submitResponse = ''

      if (this.valid) {
        this.sending = true
        this.$store.dispatch('apiPost/betaSignup', {
          email: this.email,
          browser: this.selectBrowsers.join(','),
          desktopOS: this.selectDesktopOS.join(','),
          mobileOS: this.selectMobileOS.join(',')
        }).then(response => {
          if (response.data.success) {
            this.submitSuccess = true
            this.submitResponse = 'You are signed up for Beta testing!\nIf you are selected to participate, we will send you an email to let you know that your Torii SRS account now has access to the Beta.'
          } else {
            this.submitSuccess = false
            this.submitResponse = response.data.error_description
          }

          this.sending = false
        })
      }
    },
    closeDialog () {
      this.reset()
      this.dialog = false
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>

<style scoped>
.multi-line {
  white-space: pre-line;
}
</style>
