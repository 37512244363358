<template>
  <v-dialog v-model="show">
    <v-card>
      <v-card-title>Create Deck</v-card-title>
      <v-card-text>
        <v-text-field outlined clearable
          v-model="name"
          maxlength="25"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          label="Name"
          placeholder="My Deck"
        ></v-text-field>
        <v-text-field outlined clearable
          v-model="description"
          maxlength="50"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          label="Description"
          placeholder="Short description"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="cancel">Cancel</v-btn>
        <v-btn color="primary" depressed @click="confirm">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      name: '',
      description: null
    }
  },
  methods: {
    confirm () {
      const id = this.$store.getters['decks/maxId'] + 1
      const name = this.name
      const description = this.description
      const created = this.$date.utc().unix()
      this.$store.dispatch('decks/addDeck', { id, name, description, created })
      this.show = false
    },
    cancel () {
      this.show = false
    }
  }
}
</script>
