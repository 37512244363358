<template>
  <v-card
    :disabled="disabled"
    :elevation="elevation"
    :class="classCard"
    height="100%" class="d-flex flex-column"
  >
    <v-img
      class="flex-grow-0"
      height="150px"
      src="@/assets/img/lpwp.jpeg"
    >
    </v-img>
    <v-text-field v-if="isEditing"
      v-model="deckCopy.nameNew"
      dark flat hide-details solo dense autofocus
      class="text-h6 ma-1"
      background-color="rgba(0, 0, 0, 0.6)"
      maxlength="25"
      @keyup.enter="save"
      @keyup.esc="isEditing = false"
      @focus="$event.target.select()"
    ></v-text-field>
    <v-card-title v-else>
      <span class="text-truncate">{{ deckCopy.name }}</span>
    </v-card-title>
    <v-text-field v-if="isEditing"
      v-model="deckCopy.descriptionNew"
      dark flat hide-details solo dense
      class="text-body-2 ma-1"
      background-color="rgba(0, 0, 0, 0.6)"
      maxlength="50"
      @keyup.enter="save"
      @keyup.esc="isEditing = false"
      @focus="$event.target.select()"
    ></v-text-field>
    <v-card-subtitle v-else class="line-clamp">{{ deckCopy.description }}</v-card-subtitle>
    <v-card-text>
      Items: {{ itemsInDeck }}
    </v-card-text>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
    <v-card-actions v-if="!isEditing">
      <v-spacer></v-spacer>
      <v-btn v-if="!readonly" icon @click="edit">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-btn icon @click="$refs.cdDeleteDeck.show = true">
        <v-icon>mdi-trash-can</v-icon>
        <ConfirmDialog
          ref="cdDeleteDeck"
          :title="`Delete '${deckCopy.name}' ?`"
          message="Do you really want to delete this deck? The vocabulary associated with it will not be removed."
          :persistent="false"
          @confirm="remove"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="isEditing = false">
        <v-icon color="error">mdi-close-thick</v-icon>
      </v-btn>
      <v-btn icon @click="save">
        <v-icon color="success">mdi-check-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  props: {
    deck: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: Number,
      default: 0
    },
    classCard: String
  },
  components: {
    ConfirmDialog
  },
  data () {
    return {
      deckCopy: this.deck,
      isEditing: false
    }
  },
  watch: {
    isEditing (val) {
      this.$emit('isEditing', val)
    },
    deck () {
      this.deckCopy = this.deck
    }
  },
  computed: {
    itemsInDeck () {
      return this.$store.getters['decks/deck'](this.deckCopy.id)
    }
  },
  methods: {
    edit () {
      this.isEditing = true
      this.deckCopy.nameNew = this.deckCopy.name
      this.deckCopy.descriptionNew = this.deckCopy.description
    },
    save () {
      if (this.deckCopy.name === this.deckCopy.nameNew &&
        this.deckCopy.description === this.deckCopy.descriptionNew) {
        this.isEditing = false
        return
      }

      const modifiedDeck = {
        id: this.deckCopy.id,
        name: this.deckCopy.nameNew,
        description: this.deckCopy.descriptionNew
      }
      this.$store.dispatch('decks/modifyDeck', modifiedDeck)
      this.isEditing = false
    },
    remove () {
      this.$store.dispatch('decks/deleteDecks', [this.deckCopy.id])
      this.$emit('removed')
    }
  }
}
</script>
