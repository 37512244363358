<template>
<v-dialog v-model="show">
  <v-card>
    <v-card-title>Interface Language</v-card-title>
    <v-card-text>
      <v-radio-group v-model="radios" mandatory>
        <v-radio v-for="lang in interfaceLanguages" :key="lang.code"
          :label="`${lang.flag} ${lang.title}`"
          :value="lang.code"
        ></v-radio>
      </v-radio-group>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="primary" @click="show = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import vars from '@/vars.js'

export default {
  data () {
    return {
      show: false,
      interfaceLanguages: [
        { title: 'English', code: 'en', flag: '🇺🇸' },
        { title: 'Deutsch', code: 'de', flag: '🇦🇹' }
      ]
    }
  },
  computed: {
    radios: {
      get () {
        return this.$i18n.locale
      },
      set (langCode) {
        this.$i18n.locale = langCode
        localStorage.setItem(vars.localStorageKeys.lang, JSON.stringify({ ui: langCode }))
        console.log('Interface language changed to: ' + langCode)

        console.log('Reloading page...')
        this.$router.go(this.$router.currentRoute)
      }
    }
  }
}
</script>
