<template>
<v-container class="pa-0">
  <v-dialog v-model="welcome" persistent>
    <v-card>
      <v-card-title><v-img src="@/assets/svg/welcome_cats.svg"></v-img></v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text-subtitle-1 text-center mt-3">
        We're so glad you're here!
        By signing up, you've taken your first step towards mastering Japanese vocabulary.
      </v-card-text>
      <v-card-text class="text-subtitle-1 text-center">
        Let's find out how to get going with Torii SRS, in less than a minute.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click="welcome = false">Skip Tour</v-btn>
        <v-btn depressed color="primary" @click="startWelcomeTour">Get Started</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <WelcomeTour ref="tourWelcome" />
</v-container>
</template>

<script>
import WelcomeTour from '@/components/WelcomeTour.vue'

export default {
  components: {
    WelcomeTour
  },
  data () {
    return {
      welcome: false // TODO
    }
  },
  methods: {
    startWelcomeTour () {
      this.welcome = false
      this.$refs.tourWelcome.start()
    }
  }
}
</script>
