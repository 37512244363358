<template>
<v-container class="pa-0">
  <v-row justify="center" align="center" class="pb-0">
    <v-col cols="12" sm="6" class="grow">
      <v-btn id="btnLessons" depressed height="100%" width="100%" x-large :disabled="lessonsUnavailable" color="lessons" to="/lessons" class="rounded-edges text-center py-3">
        <v-row dense align="center" justify="center">
          <v-col class="shrink">
            <v-avatar size="64" color="transparent white--text">
              <v-icon size="48">mdi-school-outline</v-icon>
            </v-avatar>
          </v-col>
          <v-col>
            <div>
              <div class="d-inline text-h4 font-weight-regular mr-2" :class="!lessonsUnavailable ? 'white--text' : ''">{{ lessons }}</div>
              <div class="d-inline text-subtitle-2 font-weight-regular" :class="!lessonsUnavailable ? 'white--text' : ''">Lessons</div>
            </div>
            <v-divider class="my-1" :class="!lessonsUnavailable ? 'divider' : ''"></v-divider>
            <!--<div class="text-caption font-weight-light" :class="!lessonsUnavailable ? 'white--text' : ''">Start Session</div>-->
          </v-col>
        </v-row>
      </v-btn>
    </v-col>
    <v-col cols="12" sm="6" class="grow">
      <v-btn id="btnReviews" depressed height="100%" width="100%" x-large :disabled="reviewsUnavailable" color="reviews" to="/reviews" class="rounded-edges text-center py-3">
        <v-row no-gutters align="center" justify="center">
          <v-col>
            <div>
              <div class="d-inline text-h4 font-weight-regular mr-2" :class="!reviewsUnavailable ? 'white--text' : ''">{{ reviews }}</div>
              <div class="d-inline text-subtitle-2 font-weight-regular" :class="!reviewsUnavailable ? 'white--text' : ''">Reviews</div>
            </div>
            <v-divider class="my-1" :class="!reviewsUnavailable ? 'divider' : ''"></v-divider>
            <!--<div class="text-caption font-weight-light" :class="!reviewsUnavailable ? 'white--text' : ''">Start Session</div>-->
          </v-col>
          <v-col class="shrink">
            <v-avatar size="64" color="transparent white--text">
              <v-icon size="48">mdi-progress-clock</v-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </v-btn>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    vacationMode () {
      return this.$store.getters['user/vacationMode']
    },
    lessons () {
      return this.vacationMode ? 0 : this.$store.getters['user/dailyLessonsRemaining']
    },
    lessonsUnavailable () {
      return !this.lessons || this.$store.state.vocabulary.isInitialising || this.$store.getters['apiGet/syncing']
    },
    reviews () {
      return this.vacationMode ? 0 : this.$store.getters['vocabulary/reviews'].length
    },
    reviewsUnavailable () {
      return !this.reviews || this.$store.state.vocabulary.isInitialising || this.$store.getters['apiGet/syncing']
    }
  }
}
</script>

<style scoped>
.container.fill-height {
  flex-direction: column;
  align-items: initial;
}

.container.fill-height>.row {
  flex: 1 1 auto;
}

.divider {
  border-color: rgba(255,255,255,0.50);
}
</style>
