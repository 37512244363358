<template>
<v-container fluid class="ma-0 pa-0">
  <v-row no-gutters>
    <v-col cols="12">
      <WanaKanaTextfield
        v-model="input"
        :id="inputFieldId"
        :active="japaneseInputExpected"
        ref="mainInput"
        outlined
        hide-details
        :dense="$vuetify.breakpoint.smAndDown"
        :autofocus="true"
        :placeholder="japaneseInputExpected ? '答え' : 'Response'"
        :background-color="inputColor"
        prepend-inner-icon="mdi-help-circle-outline"
        append-icon="mdi-chevron-right-circle-outline"
        class="centered-input text-h6 text-md-h5 mt-6 mx-3"
        :readonly="!awaitingResponse"
        @click:prepend-inner="pressedDontKnow"
        @click:append="pressedEnter"
        @keyup.enter.stop="pressedEnter"
        @keyup.esc="clearTextfield"
      />
    </v-col>
  </v-row>
  <v-row dense justify="center" class="mx-2">
    <v-col align="center" class="grow">
      <v-btn
        :small="$vuetify.breakpoint.smAndDown"
        block
        depressed
        color="primary"
        :disabled="!awaitingResponse || !prevReview"
        @click="previousReview">
        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-restore</v-icon>
        <div v-if="$vuetify.breakpoint.mdAndUp">Undo</div>
      </v-btn>
    </v-col>
    <v-col align="center" class="grow">
      <v-btn
        :small="$vuetify.breakpoint.smAndDown"
        block
        depressed
        color="primary"
        :disabled="awaitingResponse || activeCard === feedbackCard.different"
        @click="toggleItemInfo">
        <v-icon :left="$vuetify.breakpoint.mdAndUp">
          {{ activeCard === feedbackCard.none ? 'mdi-eye' : 'mdi-eye-off' }}
        </v-icon>
        <div v-if="$vuetify.breakpoint.mdAndUp">Item Info</div>
      </v-btn>
    </v-col>
    <v-col align="center" class="grow">
      <v-btn
        :small="$vuetify.breakpoint.smAndDown"
        block
        depressed
        color="primary"
        :disabled="(japaneseInputExpected && (awaitingResponse || activeCard === feedbackCard.different)) || !curReview.vocab_kana"
        @click="playWordAudio"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-volume-high</v-icon>
        <div v-if="$vuetify.breakpoint.mdAndUp">Word</div>
        <v-icon v-else>mdi-alpha-w</v-icon>
      </v-btn>
    </v-col>
    <v-col align="center" class="grow">
      <v-btn
        :small="$vuetify.breakpoint.smAndDown"
        block
        depressed
        color="primary"
        :disabled="(japaneseInputExpected && (awaitingResponse || activeCard === feedbackCard.different)) || !curReview.sentence"
        @click="playSentenceAudio"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-volume-high</v-icon>
        <div v-if="$vuetify.breakpoint.mdAndUp">Sentence</div>
        <v-icon v-else>mdi-alpha-s</v-icon>
      </v-btn>
    </v-col>
    <v-col align="center" class="grow">
      <v-btn
        v-if="awaitingResponse"
        :small="$vuetify.breakpoint.smAndDown"
        block
        depressed
        color="primary"
        @click="nextReview"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-debug-step-over</v-icon>
        <div v-if="$vuetify.breakpoint.mdAndUp">Skip</div>
      </v-btn>
      <v-btn
        v-else
        :small="$vuetify.breakpoint.smAndDown"
        block
        depressed
        color="primary"
        @click="answerIgnore = !answerIgnore"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-undo</v-icon>
        <div v-if="$vuetify.breakpoint.mdAndUp">Ignore Answer</div>
      </v-btn>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12" v-if="!awaitingResponse">
      <v-expand-transition>
        <VocabInfoPanel v-show="activeCard === feedbackCard.none" mode="reviews" toolbar-inline />
      </v-expand-transition>
      <v-container v-if="activeCard === feedbackCard.different">
        <v-card>
          <v-card-title>You're not wrong!</v-card-title>
          <v-card-text>
            <p>【{{ input }}】 does indeed mean <code>{{ vcard }}</code>.<br/>
            However, we were looking for a different answer.</p>
            <p>You can simply continue the session and try again.<br/>
            If you can't think of a different answer, click the button below (in which case <u>your answer will be marked as wrong</u>).</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="answerIgnore = false; activeCard = feedbackCard.none">Show me the answer!</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-container>
      <v-container v-else-if="activeCard === feedbackCard.answerOff">
        <v-card>
          <v-card-title>Your answer was a bit off.</v-card-title>
          <v-card-text>
            <p>You might want to check the meaning to make sure you are correct.</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="answerIgnore = false; activeCard = feedbackCard.none">
              <v-icon left>mdi-eye</v-icon>
              Item Info
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import WanaKanaTextfield from '@/components/WanaKanaTextfield.vue'
import VocabInfoPanel from '@/components/VocabInfoPanel.vue'
import { jmdict } from '@/plugins/jmdict.js'
import { utils } from '@/plugins/utils'

export default {
  components: {
    WanaKanaTextfield,
    VocabInfoPanel
  },
  data () {
    return {
      inputFieldId: 'mainInput',
      input: '',
      awaitingResponse: true,
      enableSubmit: true,
      vcard: '',
      answerIgnore: false,
      activeCard: -1,
      feedbackCard: {
        hidden: -1,
        none: 0,
        answerOff: 1,
        different: 2
      },
      kaomoji: ['(•ิ_•ิ)?', '╮(￣ω￣;)╭', 'ლ(¯ロ¯"ლ)', '(〃￣ω￣〃ゞ', '(￣_￣)・・・']
    }
  },
  computed: {
    curReview () {
      return this.$store.state.reviews.curReview
    },
    prevReview () {
      return this.$store.state.reviews.prevReview
    },
    customVocab () {
      return this.curReview.id >= this.$store.state.vocabulary.customVocabStartIndex
    },
    japaneseInputExpected () {
      return this.curReview.reviewMode.endsWith('ToJp')
    },
    inputColor () {
      if (this.awaitingResponse) return null

      if (this.answerIgnore) return 'warning'
      else return this.answerCorrect ? 'success' : 'error'
    }
  },
  methods: {
    clearTextfield () {
      if (this.awaitingResponse) this.$refs.mainInput.clear()
    },
    focusInput () {
      this.$refs.mainInput.focus()
    },
    toggleItemInfo () {
      if (this.activeCard === this.feedbackCard.none) this.activeCard = this.feedbackCard.hidden
      else this.activeCard = this.feedbackCard.none
    },
    playWordAudio () {
      this.focusInput()
      this.$emit('play-word-audio')
    },
    playSentenceAudio () {
      this.focusInput()
      this.$emit('play-sentence-audio')
    },
    allowedDistance (str) {
      if (str.length <= 3) return 0
      else if (str.length > 3 && str.length <= 6) return 1
      else return 2
    },
    removePrepositions (str) {
      const preps = ['the', 'to', 'a']
      for (let i = 0; i < preps.length; i++) {
        if (str.startsWith(`${preps[i]} `)) return str.substring(preps[i].length + 1)
      }
      return str
    },
    pressedDontKnow () {
      if (this.enableSubmit && this.awaitingResponse) {
        this.input = this.kaomoji[Math.floor(Math.random() * this.kaomoji.length)]
        this.submit(true)
      }
    },
    pressedEnter () {
      if (this.enableSubmit) {
        if (this.awaitingResponse) this.submit()
        else this.next()
      }
    },
    verifyInput () {
      // Trimming input; converting to Kana if jp input is expected
      if (this.input) this.input = this.input.trim()
      if (this.input && this.japaneseInputExpected) this.input = this.$waka.toKana(this.input)
      return this.input &&
        (!this.japaneseInputExpected || (this.japaneseInputExpected && this.$waka.isJapanese(this.input)))
    },
    submit (submitWrongAnswer) {
      // Disable next button for 0.3 seconds to prevent accidental double clicking
      this.enableSubmit = false
      setTimeout(() => { this.enableSubmit = true }, 300)

      const validInput = submitWrongAnswer ? true : this.verifyInput()

      if (validInput) {
        // Compare input against expected answer
        this.awaitingResponse = false
        this.answerCorrect = false
        this.activeCard = this.feedbackCard.hidden

        // Check if submitted answer is correct
        switch (this.curReview.reviewMode) {
          case 'JpToEn': this.verifyMeaning()
            break
          case 'EnToJp':
          case 'JpToJp': this.verifyReading()
            break
        }

        if (this.answerCorrect && (this.$store.state.settings.reviewsLightningMode && this.activeCard === this.feedbackCard.hidden)) {
          this.$emit('next-review', this.answerCorrect)
        } else {
          this.$emit('response-submitted')

          // Expand item info panel if answer was wrong
          if (!this.answerCorrect && this.$store.state.settings.reviewsAutoExpand && this.activeCard === this.feedbackCard.hidden) {
            this.activeCard = this.feedbackCard.none
          // Expand item info panel if answer was correct
          } else if (this.answerCorrect && this.$store.state.settings.reviewsAutoExpandCorrect && this.activeCard === this.feedbackCard.hidden) {
            this.activeCard = this.feedbackCard.none
          }

          // Autoplay audio after response depending on user settings
          if (this.$store.state.settings.reviewsVocabAudioAutoplay && this.japaneseInputExpected) {
            if (this.$store.state.settings.reviewsAudioPlayAtEnd && this.answerCorrect) {
              this.$emit('play-audio')
            } else if (
              this.$store.state.settings.reviewsAudioPlayOnIncorrect &&
              this.$store.state.settings.reviewsAutoExpand &&
              (!this.answerCorrect && !this.answerIgnore)
            ) {
              this.$emit('play-audio')
            }
          }

          // Reveal furigana after response
          if (this.curReview.reviewMode === 'JpToEn' && this.$store.state.settings.reviewsKanaReveal) this.$emit('reveal-furigana')

          // Expand item info panel if "I don't know" button was pressed, regardless of user settings
          if (submitWrongAnswer) this.activeCard = this.feedbackCard.none
        }
      } else {
        this.$refs.mainInput.shake()
      }
    },
    verifyMeaning () {
      console.log('Verifying meaning...')
      const inputFullNoParentheses = this.input.replace(/[()]/g, '').trim() // RegEx removes all parentheses
      const inputFullClean = this.removePrepositions(inputFullNoParentheses).trim()
      const inputElements = this.input.split(',').map(item => item.trim().toLowerCase()).filter(e => e)
      const synonyms = this.curReview.synonyms ? this.curReview.synonyms.replace(/;/g, ',').split(',') : []
      const altMeanings = this.curReview.alt_meanings ? this.curReview.alt_meanings.replace(/;/g, ',').split(',') : [] // accept pre-JMdict meanings of words as answer
      const jmdictGloss = this.customVocab
        ? this.curReview.meaning.split(',')
        : jmdict.getGlossAsArray(this.curReview)
      const meanings = [...synonyms, ...altMeanings, ...jmdictGloss].map(item => item.trim().toLowerCase()).filter(e => e)

      const arrBool = new Array(inputElements.length)
      const arrExactMatch = new Array(inputElements.length)

      for (let i = 0; i < inputElements.length; i++) {
        const inputNoParentheses = inputElements[i].replace(/[()]/g, '').trim() // RegEx removes all parentheses
        const inputClean = this.removePrepositions(inputNoParentheses).trim()
        let inputCleanNumToWords = inputClean

        // Convert number in user input to words
        if (/\d/.test(inputClean)) { // RegEx returns true if string contains number, false otherwise
          const number = inputClean.match(/\d/g).join('') // RegEx extracts number from string
          const numberAsWords = utils.numToWords(number)
          inputCleanNumToWords = inputClean.replace(number, numberAsWords)
        }

        arrBool[i] = false
        arrExactMatch[i] = false
        for (let j = 0; j < meanings.length; j++) {
          const meaningClean = this.removePrepositions(meanings[j]).trim()
          const meaningCut = meaningClean.replace(/ *\([^)]*\) */g, ' ').trim() // RegEx removes all parentheses and the strings inbetween
          const meaningNoParentheses = meaningClean.replace(/[()]/g, '').trim() // RegEx removes all parentheses RegEx replaces multiple spaces/tabs/etc with single space
          const meaningNoParenthesesDistance = utils.optimalStringAlignmentDistance(meaningNoParentheses, inputClean)
          const meaningCutDistance = utils.optimalStringAlignmentDistance(meaningCut, inputClean)

          if (meaningNoParenthesesDistance <= this.allowedDistance(meaningNoParentheses) ||
            meaningCutDistance <= this.allowedDistance(meaningCut)) {
            arrBool[i] = true

            if (meaningNoParenthesesDistance === 0 || meaningCutDistance === 0) arrExactMatch[i] = true
          } else if (inputFullClean.includes(',') && jmdictGloss.length === 1) {
            if (utils.optimalStringAlignmentDistance(meaningNoParentheses, inputFullClean) <= this.allowedDistance(meaningNoParentheses) ||
              utils.optimalStringAlignmentDistance(meaningCut, inputFullClean) <= this.allowedDistance(meaningCut)) {
              arrBool[i] = true
              if (utils.optimalStringAlignmentDistance(meaningNoParentheses, inputFullClean) === 0 || utils.optimalStringAlignmentDistance(meaningCut, inputFullClean) === 0) arrExactMatch[i] = true
            }
          }

          if (inputCleanNumToWords !== inputClean) {
            const meaningNoParenthesesDistance2 = utils.optimalStringAlignmentDistance(meaningNoParentheses, inputCleanNumToWords)
            const meaningCutDistance2 = utils.optimalStringAlignmentDistance(meaningCut, inputCleanNumToWords)

            if (meaningNoParenthesesDistance2 <= this.allowedDistance(meaningNoParentheses) ||
              meaningCutDistance2 <= this.allowedDistance(meaningCut)) {
              arrBool[i] = true

              if (meaningNoParenthesesDistance2 === 0 || meaningCutDistance2 === 0) arrExactMatch[i] = true
            }
          }
        }
      }

      this.answerCorrect = arrBool.every(e => e === true)

      if (this.answerCorrect) {
        // Answer was a bit off
        if (!arrExactMatch.every(e => e === true)) this.activeCard = this.feedbackCard.answerOff
      }
    },
    verifyReading () {
      console.log('Verifying reading...')
      // Create an array that contains the user's actual input + the input as Hiragana
      // so that Hiragana and Katakana can be used interchangeably
      const inputAsHiragana = this.$waka.toHiragana(this.input) // Kanji are not converted; only Katakana
      const inputs = [this.input, inputAsHiragana]

      // checking if the input equals the current reading
      if (inputs.includes(this.curReview.vocab_kana) ||
        (inputs.includes(this.curReview.vocab) && this.curReview.reviewMode === 'EnToJp')) {
        this.answerCorrect = true
        return
      }

      // TODO: if not: checking if the input equals one of the alternative readings

      // if not: checking if the input equals one of the user's custom readings
      if (this.curReview.readings) {
        this.curReview.readings.split(';').map(item => item.trim()).filter(e => e).forEach(reading => {
          if (inputs.includes(reading)) {
            this.answerCorrect = true
          }
        })
      }

      // If EN->JP, check if input matches a word that shares 1 or more definitions with current review item
      if (this.curReview.reviewMode === 'EnToJp') {
        const jmdictGloss = this.customVocab
          ? this.curReview.meaning.split(',')
          : jmdict.getGlossAsArray(this.curReview)
        const similarWord = this.$store.getters['vocabulary/vocab'].find(vocab => {
          if ((vocab.vocab === this.input || vocab.vocab_kana === this.input) && vocab.id !== this.curReview.id) {
            const similarWordGloss = jmdict.getGlossAsArray(vocab)
            const sharedDefinitions = jmdictGloss.filter(val => similarWordGloss.indexOf(val) !== -1)
            return sharedDefinitions.length > 0
          }
          return false
        })

        if (similarWord) {
          const similarWordGloss = jmdict.getGlossAsArray(similarWord)
          const sharedDefinitions = jmdictGloss.filter(val => similarWordGloss.indexOf(val) !== -1)

          if (sharedDefinitions.length > 0) {
            this.vcard = sharedDefinitions.join(', ')
            this.activeCard = this.feedbackCard.different
            this.answerIgnore = true
          }
        }
      // TODO: If JP->JP,
      } else if (this.curReview.reviewMode === 'JpToJp') {
        // TODO
      }
    },
    next () {
      if (this.answerIgnore) return this.nextReview()
      else this.$emit('next-review', this.answerCorrect)
    },
    nextReview () {
      if (!this.answerIgnore) { // Review same item right away when ignoring answer
        this.$store.dispatch('reviews/load')
        this.$emit('autoplay-audio')
      }
      this.$emit('review-loaded')
      this.input = ''
      this.$refs.mainInput.clear()
      this.awaitingResponse = true
      this.answerIgnore = false
      this.activeCard = this.feedbackCard.hidden
    },
    previousReview () {
      this.$store.dispatch('reviews/undoItem')
      this.$emit('autoplay-audio')
      this.$emit('review-loaded')
      this.input = ''
      this.$refs.mainInput.clear()
      this.awaitingResponse = true
      this.activeCard = this.feedbackCard.hidden
    },
    keyHandler (event) {
      // Return if key event handling is temporarily disabled (e.g. if v-dialog/v-overlay is active)
      if (!this.$store.state.keysEnabled) return

      // Return if textfield or textarea are focused
      const exclude = ['input', 'textarea']
      if (event.target.id !== this.inputFieldId && exclude.indexOf(event.target.tagName.toLowerCase()) !== -1) {
        return
      }

      if (event.code === 'Enter') {
        this.pressedEnter()
      } else if (event.code === 'Escape') {
        if (!this.awaitingResponse) this.answerIgnore = !this.answerIgnore
      }
    }
  },
  created () {
    window.addEventListener('keyup', this.keyHandler)
  },
  mounted () {
    // this.focusInput()
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyHandler)
  }
}
</script>
