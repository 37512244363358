// import router from '@/router/router'
import moment from 'moment'
import * as wanakana from 'wanakana'

const state = {
  customVocabStartIndex: 10000,
  customVocabLastIndex: 0,
  vocab: [],
  isInitialising: false
}

const getters = {
  vocab (state) {
    return state.vocab.filter(item => {
      return item.id < state.customVocabStartIndex
    })
  },
  learnedVocab (state) {
    return state.vocab.filter(item => {
      // return item.streak_meaning > 0 && item.id < state.customVocabStartIndex && !item.archived
      return item.streak_meaning > 0 && !item.archived
    })
  },
  customVocab (state) {
    return state.vocab.filter(item => {
      return item.id >= state.customVocabStartIndex
    })
  },
  archivedVocab (state) {
    return state.vocab.filter(item => {
      return item.archived === 1
    })
  },
  priorityVocab (state) {
    return state.vocab.filter(item => {
      return item.prioritized === 1
    })
  },
  lessons (state, getters, rootState) {
    const studyMode = rootState.settings.studyMode.id
    const includeKanaOnly = rootState.settings.studyMode.includeKanaOnly
    const includeWk = rootState.settings.studyMode.includeWk

    return state.vocab.filter(item => {
      let studyModeCondition
      let kanaOnlyCondition = includeKanaOnly ? true : !item.kana_only
      let wkCondition = includeWk ? true : item.wk_level !== -1

      switch (studyMode) {
        case 5:
          studyModeCondition = item.jlpt_level === 5
          break
        case 4:
          studyModeCondition = item.jlpt_level === 4
          break
        case 3:
          studyModeCondition = item.jlpt_level === 3
          break
        case 2:
          studyModeCondition = item.jlpt_level === 2
          break
        case 1:
          studyModeCondition = item.jlpt_level === 1
          break
        case 7:
          // kana-only mode
          studyModeCondition = item.kana_only
          kanaOnlyCondition = true
          wkCondition = true
          break
        case 6:
          // WK mode
          studyModeCondition = item.wk_level >= 0
          wkCondition = true
          break
        default:
          // 10k mode
          studyModeCondition = true
      }
      return item.streak_meaning === 0 && !item.archived &&
        (item.prioritized || (studyModeCondition && kanaOnlyCondition && wkCondition))
    })
  },
  reviewsJpToEn (state) {
    // const arr1 = vocabFilteredByStudyMode.filter(item => {
    return state.vocab.filter(item => {
      // If JP > EN
      return item.due_meaning < moment.utc().unix() &&
        item.streak_meaning > 0 &&
        item.archived === 0
    }).map(obj => ({ ...obj, reviewMode: 'JpToEn' }))
  },
  reviewsEnToJp (state) {
    // const arr2 = vocabFilteredByStudyMode.filter(item => {
    return state.vocab.filter(item => {
      // If JP > EN
      return item.due_reading < moment.utc().unix() &&
        item.streak_reading > 0 &&
        item.archived === 0
    }).map(obj => ({ ...obj, reviewMode: 'EnToJp' }))
  },
  reviewsJpToJp (state) {
    // JP > JP (kanji > reading)
    // const arr3 = vocabFilteredByStudyMode.filter(item => {
    return state.vocab.filter(item => {
      return item.due_jptojp < moment.utc().unix() &&
        item.streak_meaning > 0 &&
        item.archived === 0 &&
        !wanakana.isKana(item.vocab)
    }).map(obj => ({ ...obj, reviewMode: 'JpToJp' }))
  },
  reviews (state, getters, rootState) {
    // Generate review queue/list
    // If a vocab item is not part of the selected study mode, it will still appear in review queue
    // (=like the option to "review everything" in Torii 1.0)
    const reviewMode = rootState.settings.reviewMode
    if (reviewMode === 1) {
      return getters.reviewsJpToEn
    } else if (reviewMode === 2) {
      return getters.reviewsEnToJp
    } else if (reviewMode === 3) {
      return [...getters.reviewsJpToEn, ...getters.reviewsEnToJp]
    } else if (reviewMode === 4) {
      return getters.reviewsJpToJp
    } else {
      // ReviewMode hasn't been loaded yet
      return []
    }
  },
  recentLessons (state, getters) {
    const validSrsStages = [1, 2] // Novice 1 & 2
    return getters.reviews.filter(e => {
      switch (e.reviewMode) {
        case 'JpToEn': return validSrsStages.includes(e.streak_meaning)
        case 'EnToJp': return validSrsStages.includes(e.streak_reading)
        default: return validSrsStages.includes(e.streak_jptojp)
      }
    })
  },
  statistics (state) {
    const stats = {
      lessons: {
        core10kCount: 0,
        core10kLearned: 0,
        wkCount: 0,
        wkLearned: 0,
        kanaOnlyCount: 0,
        kanaOnlyLearned: 0,
        jlpt5Count: 0,
        jlpt5Learned: 0,
        jlpt4Count: 0,
        jlpt4Learned: 0,
        jlpt3Count: 0,
        jlpt3Learned: 0,
        jlpt2Count: 0,
        jlpt2Learned: 0,
        jlpt1Count: 0,
        jlpt1Learned: 0
      },
      reviews: {
        rm: 0,
        rr: 0,
        rjp: 0,
        get tr () { return this.rm + this.rr + this.rjp },
        m: 0,
        r: 0,
        jp: 0,
        get tc () { return this.m + this.r + this.jp }
      }
    }

    return state.vocab.reduce((counter, obj) => {
      // Lessons
      if (obj.id < state.customVocabStartIndex) { // custom vocab doesn't matter for lesson stats
        counter.lessons.core10kCount++
        if (obj.wk_level !== -1) counter.lessons.wkCount++
        if (obj.kana_only) counter.lessons.kanaOnlyCount++
        if (obj.jlpt_level === 5) counter.lessons.jlpt5Count++
        if (obj.jlpt_level === 4) counter.lessons.jlpt4Count++
        if (obj.jlpt_level === 3) counter.lessons.jlpt3Count++
        if (obj.jlpt_level === 2) counter.lessons.jlpt2Count++
        if (obj.jlpt_level === 1) counter.lessons.jlpt1Count++

        if (obj.streak_meaning > 0 || obj.archived) { // archived words are considered "learned" words
          counter.lessons.core10kLearned++
          if (obj.wk_level !== -1) counter.lessons.wkLearned++
          if (obj.kana_only) counter.lessons.kanaOnlyLearned++
          if (obj.jlpt_level === 5) counter.lessons.jlpt5Learned++
          if (obj.jlpt_level === 4) counter.lessons.jlpt4Learned++
          if (obj.jlpt_level === 3) counter.lessons.jlpt3Learned++
          if (obj.jlpt_level === 2) counter.lessons.jlpt2Learned++
          if (obj.jlpt_level === 1) counter.lessons.jlpt1Learned++
        }
      }
      // Reviews
      counter.reviews.rm += obj.reviewed_meaning
      counter.reviews.rr += obj.reviewed_reading
      counter.reviews.rjp += obj.reviewed_jptojp
      counter.reviews.m += obj.correct_meaning
      counter.reviews.r += obj.correct_reading
      counter.reviews.jp += obj.correct_jptojp
      return counter
    }, stats)
  },
  srsStagesCount (state, getters, rootState) {
    // Filtering by Study Mode
    /* const vocabFilteredByStudyMode = studyMode === 0 ? vocabComplete : vocabComplete.filter(item => {
      switch (studyMode) {
        // Custom vocab should appear in all study modes
        case 5: return item.jlpt_level === 5 || item.id >= customVocabStartIndex
        case 4: return item.jlpt_level === 4 || item.id >= customVocabStartIndex
        case 3: return item.jlpt_level === 3 || item.id >= customVocabStartIndex
        case 2: return item.jlpt_level === 2 || item.id >= customVocabStartIndex
        case 1: return item.jlpt_level === 1 || item.id >= customVocabStartIndex
        case 7: return item.kana_only || item.id >= customVocabStartIndex
        case 6: return item.wk_level !== -1 || item.id >= customVocabStartIndex
      }
    }) */

    const meaning = []
    const reading = []
    const jpToJp = []
    for (let i = 0; i < rootState.srsStages.length; i++) {
      meaning[i] = { n5: 0, n4: 0, n3: 0, n2: 0, n1: 0, nx: 0, custom: 0 }
      reading[i] = { n5: 0, n4: 0, n3: 0, n2: 0, n1: 0, nx: 0, custom: 0 }
      jpToJp[i] = { n5: 0, n4: 0, n3: 0, n2: 0, n1: 0, nx: 0, custom: 0 }
    }

    state.vocab.forEach(item => {
      if (!item.archived) {
        if (item.id >= state.customVocabStartIndex) {
          meaning[item.streak_meaning].custom++
          reading[item.streak_reading].custom++
          if (!wanakana.isKana(item.vocab)) jpToJp[item.streak_jptojp].custom++
        } else {
          switch (item.jlpt_level) {
            case 5:
              meaning[item.streak_meaning].n5++
              reading[item.streak_reading].n5++
              if (item.streak_meaning > 0 && !wanakana.isKana(item.vocab)) jpToJp[item.streak_jptojp].n5++
              break
            case 4:
              meaning[item.streak_meaning].n4++
              reading[item.streak_reading].n4++
              if (item.streak_meaning > 0 && !wanakana.isKana(item.vocab)) jpToJp[item.streak_jptojp].n4++
              break
            case 3:
              meaning[item.streak_meaning].n3++
              reading[item.streak_reading].n3++
              if (item.streak_meaning > 0 && !wanakana.isKana(item.vocab)) jpToJp[item.streak_jptojp].n3++
              break
            case 2:
              meaning[item.streak_meaning].n2++
              reading[item.streak_reading].n2++
              if (item.streak_meaning > 0 && !wanakana.isKana(item.vocab)) jpToJp[item.streak_jptojp].n2++
              break
            case 1:
              meaning[item.streak_meaning].n1++
              reading[item.streak_reading].n1++
              if (item.streak_meaning > 0 && !wanakana.isKana(item.vocab)) jpToJp[item.streak_jptojp].n1++
              break
            default:
              meaning[item.streak_meaning].nx++
              reading[item.streak_reading].nx++
              if (item.streak_meaning > 0 && !wanakana.isKana(item.vocab)) jpToJp[item.streak_jptojp].nx++
          }
        }
      }
    })

    const reviewMode = rootState.settings.reviewMode
    if (reviewMode === 1) {
      return meaning
    } else if (reviewMode === 2) {
      return reading
    } else if (reviewMode === 3) {
      const combined = []
      for (let i = 0; i < meaning.length; i++) {
        combined[i] = {
          n5: meaning[i].n5 + reading[i].n5,
          n4: meaning[i].n4 + reading[i].n4,
          n3: meaning[i].n3 + reading[i].n3,
          n2: meaning[i].n2 + reading[i].n2,
          n1: meaning[i].n1 + reading[i].n1,
          nx: meaning[i].nx + reading[i].nx,
          custom: meaning[i].custom + reading[i].custom
        }
      }
      return combined
    } else if (reviewMode === 4) {
      return jpToJp
    } else {
      return meaning
    }
  }
}

const mutations = {
  init (state) {
    state.isInitialising = true
    state.vocab = []
  },
  initCompleted (state) {
    const customVocabLastIndex = state.vocab[state.vocab.length - 1].id
    state.customVocabLastIndex = customVocabLastIndex >= state.customVocabStartIndex ? customVocabLastIndex + 1 : state.customVocabStartIndex
    state.isInitialising = false
  },
  vocab (state, obj) {
    // state.vocab = [...state.vocab, ...obj]
    state.vocab = obj
    console.log('%cVocabulary & progress loaded.', 'color: green')
  },
  progress (state, { progress, vocabId }) {
    const index = state.vocab.findIndex(item => item.id === vocabId)
    for (const item in progress) {
      state.vocab[index][item] = progress[item]
    }
  },
  archive (state, vocabIds) {
    vocabIds.forEach(e => {
      const index = state.vocab.findIndex(el => el.id === e)
      state.vocab[index].archived = 1
    })
  },
  restore (state, vocabIds) {
    vocabIds.forEach(e => {
      const index = state.vocab.findIndex(el => el.id === e)
      state.vocab[index].archived = 0
    })
  },
  prioritize (state, { vocabIds, prioritized }) {
    vocabIds.forEach(e => {
      const index = state.vocab.findIndex(el => el.id === e)
      state.vocab[index].prioritized = prioritized ? 1 : 0
    })
  },
  downgrade (state, { vocabIds, targetSrsStage }) {
    const types = ['streak_meaning', 'streak_reading', 'streak_jptojp']
    const modifications = {}

    vocabIds.forEach(e => {
      const index = state.vocab.findIndex(el => el.id === e)
      // if (index < state.customVocabStartIndex) {
      modifications[e] = {}
      types.forEach(type => {
        const curStreakMeaning = state.vocab[index][type]
        const newStreakMeaning = curStreakMeaning >= targetSrsStage ? targetSrsStage : curStreakMeaning
        state.vocab[index][type] = newStreakMeaning
        modifications[e][type] = newStreakMeaning
      })
      // }
    })

    // Write to IndexedDB
    const regVocabIds = vocabIds.filter(vid => vid < state.customVocabStartIndex)
    const customVocabIds = vocabIds.filter(vid => vid >= state.customVocabStartIndex)
    // regular vocab
    let modCount = 0
    this._vm.$db.progress.where('vocab_id').anyOf(regVocabIds).modify(vocab => {
      types.forEach(type => {
        if (vocab[type] !== modifications[vocab.vocab_id][type]) {
          vocab[type] = modifications[vocab.vocab_id][type]
          modCount++
        }
      })
    }).then(modifyCountReg => {
      // custom vocab
      this._vm.$db.custom.where('id').anyOf(customVocabIds).modify(vocab => {
        types.forEach(type => {
          if (vocab[type] !== modifications[vocab.id][type]) {
            vocab[type] = modifications[vocab.id][type]
            modCount++
          }
        })
      }).then(modifyCountCustom => {
        const timestamp = this._vm.$date.utc().unix()
        if (regVocabIds > 0) this._vm.$db.sync.update(0, { progress: timestamp })
        if (customVocabIds > 0) this._vm.$db.sync.update(0, { custom: timestamp })
        console.log(`%cDowngraded ${modCount} and skipped ${(modifyCountReg + modifyCountCustom) * 3 - modCount} cards of ${modifyCountReg + modifyCountCustom} words, out of ${vocabIds.length} selected items, to ${targetSrsStage}.`, 'color: green')
        // add to sync queue
        this.dispatch('api/addToNetworkQueue', { type: 'vocab', data: vocabIds }, { root: true })
      })
    })
  },
  addCustom (state, customVocab) {
    state.vocab.push(customVocab)
    state.customVocabLastIndex++
  },
  modifyCustom (state, customVocab) {
    const index = state.vocab.findIndex(item => item.id === customVocab.id)
    this._vm.$set(state.vocab, index, customVocab)
  },
  deleteCustom (state, customVocabIds) {
    state.vocab = state.vocab.filter(vocab => !customVocabIds.includes(vocab.id))
  },
  updateSynonyms (state, { index, newSynonyms }) {
    state.vocab[index].synonyms = newSynonyms
  },
  updateReadings (state, { index, newReadings }) {
    state.vocab[index].readings = newReadings
  },
  notesEdit (state, { index, text }) {
    state.vocab[index].notes = text
  }
}

const actions = {
  init (context, payload) {
    context.commit('init')

    // Merging vocab and progress tables ON v.id = p.vocab_id,
    // then merging with custom vocab using spread operator
    const vocabComplete = [...payload.serverVocab.map(serverVocabItem => ({
      ...payload.progress.find((progressItem) => (progressItem.vocab_id === serverVocabItem.id) && progressItem),
      ...serverVocabItem
    })), ...payload.customVocab]

    context.commit('vocab', vocabComplete)
    context.commit('initCompleted')
  },
  progress (context, { progress, vocabId }) {
    context.commit('progress', { progress, vocabId })
    // Writing to IndexedDB
    // regular vocab
    if (vocabId < state.customVocabStartIndex) {
      this._vm.$db.progress.where('vocab_id').equals(vocabId).modify(progress).then(() => {
        console.log(`Updated progress of vocab # ${vocabId}.`)
        this._vm.$db.sync.update(0, { progress: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    // custom vocab
    } else {
      this._vm.$db.custom.where('id').equals(vocabId).modify(progress).then(() => {
        console.log(`Updated progress of custom vocab # ${vocabId}.`)
        this._vm.$db.sync.update(0, { custom: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    }
  },
  archive (context, vocabIds) {
    context.commit('archive', vocabIds)
    // Write to IndexedDB
    const regVocabIds = vocabIds.filter(vid => vid < state.customVocabStartIndex)
    const customVocabIds = vocabIds.filter(vid => vid >= state.customVocabStartIndex)
    // regular vocab
    this._vm.$db.progress.where('vocab_id').anyOf(regVocabIds).modify({ archived: 1 }).then(modifyCountReg => {
      // custom vocab
      this._vm.$db.custom.where('id').anyOf(customVocabIds).modify({ archived: 1 }).then(modifyCountCustom => {
        const timestamp = this._vm.$date.utc().unix()
        if (regVocabIds > 0) this._vm.$db.sync.update(0, { progress: timestamp })
        if (customVocabIds > 0) this._vm.$db.sync.update(0, { custom: timestamp })
        console.log(`%cArchived ${modifyCountReg + modifyCountCustom} of ${vocabIds.length} selected items.`, 'color: green')
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: vocabIds }, { root: true })
      })
    })
  },
  restore (context, vocabIds) {
    context.commit('restore', vocabIds)
    // Write to IndexedDB
    const regVocabIds = vocabIds.filter(vid => vid < state.customVocabStartIndex)
    const customVocabIds = vocabIds.filter(vid => vid >= state.customVocabStartIndex)
    // regular vocab
    this._vm.$db.progress.where('vocab_id').anyOf(regVocabIds).modify({ archived: 0 }).then(modifyCountReg => {
      // custom vocab
      this._vm.$db.custom.where('id').anyOf(customVocabIds).modify({ archived: 0 }).then(modifyCountCustom => {
        const timestamp = this._vm.$date.utc().unix()
        if (regVocabIds > 0) this._vm.$db.sync.update(0, { progress: timestamp })
        if (customVocabIds > 0) this._vm.$db.sync.update(0, { custom: timestamp })
        console.log(`%cUnarchived ${modifyCountReg + modifyCountCustom} of ${vocabIds.length} selected items.`, 'color: green')
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: vocabIds }, { root: true })
      })
    })
  },
  prioritize (context, { vocabIds, prioritized }) {
    context.commit('prioritize', { vocabIds, prioritized })
    // Write to IndexedDB
    const regVocabIds = vocabIds.filter(vid => vid < state.customVocabStartIndex)
    const customVocabIds = vocabIds.filter(vid => vid >= state.customVocabStartIndex)
    // regular vocab
    this._vm.$db.progress.where('vocab_id').anyOf(regVocabIds).modify({ prioritized: prioritized ? 1 : 0 }).then(modifyCountReg => {
      // custom vocab
      this._vm.$db.custom.where('id').anyOf(customVocabIds).modify({ prioritized: prioritized ? 1 : 0 }).then(modifyCountCustom => {
        const timestamp = this._vm.$date.utc().unix()
        if (regVocabIds > 0) this._vm.$db.sync.update(0, { progress: timestamp })
        if (customVocabIds > 0) this._vm.$db.sync.update(0, { custom: timestamp })
        console.log(`%cMoved ${modifyCountReg + modifyCountCustom} of ${vocabIds.length} items to front of lessons queue.`, 'color: green')
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: vocabIds }, { root: true })
      })
    })
  },
  downgrade (context, { vocabIds, targetSrsStage }) {
    context.commit('downgrade', { vocabIds, targetSrsStage })
  },
  addCustom (context, customVocab) {
    context.commit('addCustom', customVocab)
    // Writing to IndexedDB
    this._vm.$db.custom.add(customVocab).then(lastKey => {
      console.log(`%cAdded custom vocab #${customVocab.id}.`, 'color: green')
      this._vm.$db.sync.update(0, { custom: this._vm.$date.utc().unix() })
      // add to sync queue
      context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [customVocab.id] }, { root: true })
    })
  },
  modifyCustom (context, customVocab) {
    context.commit('modifyCustom', customVocab)
    // Writing to IndexedDB
    this._vm.$db.custom.where('id').equals(customVocab.id).modify(customVocab).then(() => {
      console.log(`%cModified custom vocab #${customVocab.id}.`, 'color: green')
      this._vm.$db.sync.update(0, { custom: this._vm.$date.utc().unix() })
      // add to sync queue
      context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [customVocab.id] }, { root: true })
    })
  },
  deleteCustom (context, customVocabIds) {
    context.commit('deleteCustom', customVocabIds)
    // Writing to IndexedDB
    this._vm.$db.custom.where('id').anyOf(customVocabIds).delete().then(deleteCount => {
      console.log(`%cDeleted ${deleteCount} of ${customVocabIds.length} custom vocab items.`, 'color: green')
      this._vm.$db.sync.update(0, { custom: this._vm.$date.utc().unix() })
      // add to sync queue
      context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: customVocabIds }, { root: true })
    })
  },
  addSynonym (context, { synonym, vocabId }) {
    const index = context.state.vocab.findIndex(item => item.id === vocabId)
    let newSynonyms = context.state.vocab[index].synonyms
    if (!newSynonyms) newSynonyms = ''
    newSynonyms = newSynonyms + synonym + ';'
    context.commit('updateSynonyms', { index, newSynonyms })
    context.dispatch('reviews/updateSynonyms', { vocabId, newSynonyms }, { root: true })

    // Writing to IndexedDB
    // regular vocab
    if (vocabId < context.state.customVocabStartIndex) {
      this._vm.$db.progress.where('vocab_id').equals(vocabId).modify({ synonyms: newSynonyms }).then(() => {
        this._vm.$db.sync.update(0, { progress: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    // custom vocab
    } else {
      this._vm.$db.custom.where('id').equals(vocabId).modify({ synonyms: newSynonyms }).then(() => {
        this._vm.$db.sync.update(0, { custom: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    }
  },
  removeSynonym (context, { synonym, vocabId }) {
    const index = context.state.vocab.findIndex(item => item.id === vocabId)
    let newSynonyms = context.state.vocab[index].synonyms.replace(synonym + ';', '')
    if (!newSynonyms) newSynonyms = null
    context.commit('updateSynonyms', { index, newSynonyms })
    context.dispatch('reviews/updateSynonyms', { vocabId, newSynonyms }, { root: true })

    // Writing to IndexedDB
    // regular vocab
    if (vocabId < context.state.customVocabStartIndex) {
      this._vm.$db.progress.where('vocab_id').equals(vocabId).modify({ synonyms: newSynonyms }).then(() => {
        this._vm.$db.sync.update(0, { progress: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    // custom vocab
    } else {
      this._vm.$db.custom.where('id').equals(vocabId).modify({ synonyms: newSynonyms }).then(() => {
        this._vm.$db.sync.update(0, { custom: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    }
  },
  addReading (context, { reading, vocabId }) {
    const index = context.state.vocab.findIndex(item => item.id === vocabId)
    let newReadings = context.state.vocab[index].readings
    if (!newReadings) newReadings = ''
    newReadings = newReadings + reading + ';'
    context.commit('updateReadings', { index, newReadings })
    context.dispatch('reviews/updateReadings', { vocabId, newReadings }, { root: true })

    // Writing to IndexedDB
    // regular vocab
    if (vocabId < context.state.customVocabStartIndex) {
      this._vm.$db.progress.where('vocab_id').equals(vocabId).modify({ readings: newReadings }).then(() => {
        this._vm.$db.sync.update(0, { progress: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    // custom vocab
    } else {
      this._vm.$db.custom.where('id').equals(vocabId).modify({ readings: newReadings }).then(() => {
        this._vm.$db.sync.update(0, { custom: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    }
  },
  removeReading (context, { reading, vocabId }) {
    const index = context.state.vocab.findIndex(item => item.id === vocabId)
    let newReadings = context.state.vocab[index].readings.replace(reading + ';', '')
    if (!newReadings) newReadings = null
    context.commit('updateReadings', { index, newReadings })
    context.dispatch('reviews/updateReadings', { vocabId, newReadings }, { root: true })

    // Writing to IndexedDB
    // regular vocab
    if (vocabId < context.state.customVocabStartIndex) {
      this._vm.$db.progress.where('vocab_id').equals(vocabId).modify({ readings: newReadings }).then(() => {
        this._vm.$db.sync.update(0, { progress: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    // custom vocab
    } else {
      this._vm.$db.custom.where('id').equals(vocabId).modify({ readings: newReadings }).then(() => {
        this._vm.$db.sync.update(0, { custom: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    }
  },
  notesEdit (context, { text, vocabId }) {
    if (!text || !text.trim()) text = null
    const index = context.state.vocab.findIndex(item => item.id === vocabId)
    context.commit('notesEdit', { index, text })
    context.dispatch('reviews/updateNotes', { vocabId, text }, { root: true })

    // Writing to IndexedDB
    // regular vocab
    if (vocabId < context.state.customVocabStartIndex) {
      this._vm.$db.progress.where('vocab_id').equals(vocabId).modify({ notes: text }).then(() => {
        this._vm.$db.sync.update(0, { progress: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    // custom vocab
    } else {
      this._vm.$db.custom.where('id').equals(vocabId).modify({ notes: text }).then(() => {
        this._vm.$db.sync.update(0, { custom: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'vocab', data: [vocabId] }, { root: true })
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
