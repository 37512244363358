<template>
<v-container fluid width="100%" height="100%" class="ma-0 pa-0">
  <v-toolbar v-if="!toolbarInline" dense dark>
    <v-tabs class="white--text" dark centered v-model="selectedTab">
      <v-tab>Vocab</v-tab>
      <v-tab>Context</v-tab>
      <v-tab>
        <v-badge :value="hasCustomContent" dot color="primary">
          Custom
        </v-badge>
      </v-tab>
    </v-tabs>
  </v-toolbar>
  <v-container fluid>
  <v-card class="overflow-y-auto">
    <v-toolbar v-if="toolbarInline" dense>
      <v-tabs centered v-model="selectedTab">
        <v-tab>Vocab</v-tab>
        <v-tab>Context</v-tab>
        <v-tab>
          <v-badge :value="hasCustomContent" dot color="primary">
            Custom
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-tabs-items v-model="selectedTab" class="pa-3">
      <v-tab-item>
        <v-container>
          <v-row v-if="showVocab" justify="center" class="pb-5">
            <KanjiFuriganaCompound
              large
              :kanji="vocabItem.vocab"
              :furigana="vocabItem.vocab_kana"
              :pitchAccent="vocabItem.accent"
            />
            <!--div :class="pitchAccentClass" class="text-center text-h5 text-md-h4 font-weight-bold pb-5">
              <span v-html="vocabKanji" />
            </div>
            <div v-if="!hideKana" :class="pitchAccentClass" class="text-center text-h5 text-md-h4 font-weight-thin pb-5 ms-3">
              【<span v-html="vocabReading" />】
            </div-->
          </v-row>
          <v-row v-if="showMeaning" dense justify="center" class="pb-5">
            <v-col cols="12">
              <div class="text-center text-h5 text-md-h4 font-weight-medium">{{ gloss }}</div>
            </v-col>
            <v-col cols="12">
              <div class="text-center text-h6 text-md-h5 mt-0">{{ synonymsJoined }}</div>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-chip v-if="vocabItem.jlpt_level" small label dark color="secondary" class="mx-1 my-1">JLPT N{{ vocabItem.jlpt_level }}</v-chip>
            <v-chip v-for="(item, i) in pos" :key="item" label small dark :color="posColor[i]" class="mx-1 my-1">{{ pos[i] }}</v-chip>
          </v-row>
          <v-row class="mt-5 mb-3">
            <v-col>
              <div class="subtitle-2 font-weight-light">Context Sentence</div>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
              <div class="title font-weight-bold">{{ vocabItem.sentence }}</div>
          </v-row>
          <v-row no-gutters justify="center">
            <div class="title font-weight-medium" @click="toggleSentenceVisibility">{{ sentenceOrPlaceholder }}</div>
          </v-row>
          <v-row v-if="info" class="mt-5 mb-3">
            <v-col>
              <div class="subtitle-2 font-weight-light">Additional Information</div>
              <v-divider></v-divider>
              <div class="text-center mt-3">{{ info }}</div>
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-col>
              <div class="subtitle-2 font-weight-light">Vocabulary Reading</div>
              <v-divider></v-divider>
              <v-btn v-if="!!vocabItem.vocab_audio" small text :disabled="!online" class="mx-1 my-1" @click="playWordAudio">
                <v-icon left>mdi-volume-high</v-icon>
                Native
              </v-btn>
              <v-btn
                v-for="(voice, index) in japanesePollyVoices"
                :key="index"
                small text
                :disabled="!online"
                class="mx-1 my-1"
                @click="genAudio(vocabItem.vocab, voice.name, voice.gender, voice.language, voice.service)">
                <v-icon left :color="voice.color">mdi-volume-high</v-icon>
                {{ voice.nickname }}
              </v-btn>
            </v-col>
            <v-col>
              <div class="subtitle-2 font-weight-light">Sentence Reading</div>
              <v-divider></v-divider>
              <div v-if="!!vocabItem.sentence">
                <v-btn v-if="!!vocabItem.sentence_audio" small text :disabled="!online" class="mx-1 my-1" @click.stop="playSentenceAudio">
                  <v-icon left>mdi-volume-high</v-icon>
                  Native
                </v-btn>
                <v-btn
                  v-for="(voice, index) in japanesePollyVoices"
                  :key="index"
                  small text
                  :disabled="!online"
                  class="mx-1 my-1"
                  @click="genAudio(vocabItem.sentence, voice.name, voice.gender, voice.language, voice.service)">
                  <v-icon left :color="voice.color">mdi-volume-high</v-icon>
                  {{ voice.nickname }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-col>
              <div class="subtitle-2 font-weight-light">Vocabulary Meaning</div>
              <v-divider></v-divider>
              <v-btn
                v-for="(voice, index) in englishPollyVoices"
                :key="index"
                small text
                :disabled="!online"
                class="mx-1 my-1"
                @click="genAudio(gloss, voice.name, voice.gender, voice.language, voice.service)">
                <v-icon left :color="voice.color">mdi-volume-high</v-icon>
                {{ voice.nickname }}
              </v-btn>
            </v-col>
            <v-col>
              <div class="subtitle-2 font-weight-light">Sentence Meaning</div>
              <v-divider></v-divider>
              <div v-if="!!vocabItem.sentence_meaning">
                <v-btn
                  v-for="(voice, index) in englishPollyVoices"
                  :key="index"
                  small text
                  :disabled="!online"
                  class="mx-1 my-1"
                  @click="genAudio(vocabItem.sentence_meaning, voice.name, voice.gender, voice.language, voice.service)">
                  <v-icon left :color="voice.color">mdi-volume-high</v-icon>
                  {{ voice.nickname }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-5 mb-1">
            <v-col>
              <div class="subtitle-2 font-weight-light">Open vocabulary in</div>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-0">
            <v-col v-if="vocabItem.wk_level === -1 && !$waka.isKana(vocabItem.vocab)">
              <v-btn rounded block :disabled="!online" class="mx-1 my-1" :href="openWaniKani()" target="_blank">
                <v-img src="@/assets/img/wk.png" max-width="20" max-height="20" class="mr-2"></v-img>
                WaniKani
              </v-btn>
            </v-col>
            <v-col>
              <v-btn rounded block :disabled="!online" class="mx-1 my-1" :href="openJisho()" target="_blank">
                <v-img src="@/assets/img/jisho.png" max-width="20" max-height="20" class="mr-2"></v-img>
                Jisho
              </v-btn>
            </v-col>
            <v-col>
              <v-btn rounded block :disabled="!online" class="mx-1 my-1" :href="openGoo()" target="_blank">
                <v-img src="@/assets/img/goo.png" max-width="20" max-height="20" class="mr-2"></v-img>
                Goo辞書
              </v-btn>
            </v-col>
            <v-col>
              <v-btn rounded block :disabled="!online" class="mx-1 my-1" :href="openTatoeba()" target="_blank">
                <v-img src="@/assets/img/tatoeba.png" max-width="20" max-height="20" class="mr-2"></v-img>
                Tatoeba
              </v-btn>
            </v-col>
            <v-col>
              <v-btn rounded block :disabled="!online" class="mx-1 my-1" :href="openGoogle()" target="_blank">
                <v-img src="@/assets/img/google.png" max-width="20" max-height="20" class="mr-2"></v-img>
                Images
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-col class="text-center ma-0 pa-0 mt-3">
          <v-dialog persistent v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text small v-bind="attrs" v-on="on" :disabled="!online || customVocab">
                <v-icon left>mdi-message-alert-outline</v-icon>
                Report item
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline background" primary-title>
                Report vocab item
              </v-card-title>
              <v-card-subtitle class="subtitle-1 background pt-2 pb-1">{{ vocabItem.vocab }} [{{ vocabItem.vocab_kana }}]</v-card-subtitle>
              <v-card-text class="mt-5">
                <v-form ref="formIssue" @submit.prevent>
                  <v-select v-model="issue" outlined :items="issues" label="Select the issue"></v-select>
                  <v-textarea
                    outlined
                    no-resize
                    clearable
                    rows="5"
                    v-model="issueText"
                    counter="300"
                    maxlength="300"
                    label="(Optional) Describe the issue"
                  ></v-textarea>
                </v-form>
                <v-alert v-if="issueResponse" outlined :type="issueSubmitSuccess ? 'success' : 'error'">{{ issueResponse }}</v-alert>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined color="primary" @click="closeIssueDialog">Cancel</v-btn>
                <v-btn depressed color="primary" :loading="sending" @click="submitIssue">Submit</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-title>Context sentences & phrases</v-card-title>
          <v-card-subtitle v-if="!prime && examples">
            Want these sentences with <strong>Japanese</strong> and <strong>English audio</strong>?<br />
            <router-link to="prime">Upgrade your account</router-link>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text v-if="examples">
            <div v-for="(ex, indx) in examples" :key="indx">
              <div class="subtitle-1 font-weight-bold">
                {{ ex.jp }}
                <span v-if="prime">
                  <v-btn
                    v-for="(voice, index) in japanesePollyVoices"
                    :key="index"
                    icon text
                    :disabled="!online"
                    :color="voice.color"
                    @click="genAudio(ex.jp, voice.name, voice.gender, voice.language, voice.service)">
                    <v-icon>mdi-volume-high</v-icon>
                  </v-btn>
                </span>
              </div>
              <div class="subtitle-1 font-weight-regular">
                {{ ex.en }}
                <span v-if="prime">
                  <v-btn
                    v-for="(voice, index) in englishPollyVoices"
                    :key="index"
                    icon text
                    :disabled="!online"
                    @click="genAudio(ex.en, voice.name, voice.gender, voice.language, voice.service)">
                    <v-icon>mdi-volume-high</v-icon>
                  </v-btn>
                </span>
              </div>
              <br />
            </div>
          </v-card-text>
          <v-card-text v-else>
            <div class="subtitle-1 font-weight-regular">
              Sorry, there aren't any context sentences available right now!
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat class="pa-3">
          <v-textarea
            v-model="notes"
            outlined
            no-resize
            clearable
            rows="5"
            :counter="notesCharLimit"
            :maxlength="notesCharLimit"
            label="Notes"
            placeholder="Add note or mnemonic"
            @blur="saveNotes"
          ></v-textarea>
          <v-row>
            <v-col cols="12" sm="6">
            <v-card flat>
              <v-card-title>Synonyms</v-card-title>
              <v-divider></v-divider>
              <v-card-actions>
                <v-text-field outlined dense clearable hide-details="auto"
                  autocomplete="off"
                  prepend-icon="mdi-plus"
                  placeholder="alt meaning"
                  v-model="syn"
                  :counter="synonymsCharCounter"
                  :maxlength="synonymsCharCounter"
                  :disabled="synonyms.length >= 5"
                  @click:prepend="addSynonym"
                  @keyup.enter="addSynonym"
                ></v-text-field>
              </v-card-actions>
              <v-chip v-for="(item, i) in synonyms" :key="item" label close class="mx-1 my-1" @click:close="removeSynonym(item)">{{synonyms[i]}}</v-chip>
            </v-card>
            </v-col>
            <v-col cols="12" sm="6">
            <v-card flat>
              <v-card-title>Readings</v-card-title>
              <v-divider></v-divider>
              <v-card-actions>
                <WanaKanaTextfield
                  :id="addReadingId"
                  outlined dense clearable hide-details="auto"
                  prepend-icon="mdi-plus"
                  placeholder="リーディング"
                  v-model="read"
                  :counter="readingsCharCounter"
                  :maxlength="readingsCharCounter"
                  :disabled="readings.length >= 5"
                  @click:prepend="addReading"
                  @keyup.enter="addReading"
                />
              </v-card-actions>
              <v-chip v-for="(item, i) in readings" :key="item" label close class="mx-1 my-1" @click:close="removeReading(item)">{{readings[i]}}</v-chip>
            </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
  </v-container>
</v-container>
</template>

<script>
import { jmdict } from '@/plugins/jmdict.js'
import { audio } from '@/plugins/audio.js'
import { pitchaccent } from '@/plugins/pitchaccent.js'
import WanaKanaTextfield from '@/components/WanaKanaTextfield.vue'
import KanjiFuriganaCompound from '@/components/KanjiFuriganaCompound.vue'

export default {
  props: {
    mode: String, // Can be: lessons, reviews, single
    vocab: Object,
    toolbarInline: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WanaKanaTextfield,
    KanjiFuriganaCompound
  },
  data () {
    return {
      addReadingId: 'customAddReading',
      showDialog: false,
      sending: false,
      synonymsCharCounter: 25,
      readingsCharCounter: 15,
      selectedTab: 0,
      syn: '',
      read: '',
      notes: '',
      issues: [
        'Sentences don\'t match the word',
        'Missing or broken audio',
        'Broken reference link(s)',
        'Other (specify below)'
      ],
      issue: '',
      issueText: '',
      issueResponse: '',
      issueSubmitSuccess: false
    }
  },
  computed: {
    online () {
      return this.$store.getters.online
    },
    hideKana () {
      return this.vocabItem.vocab === this.vocabItem.vocab_kana || this.$waka.isKana(this.vocabItem.vocab)
    },
    vocabItem () {
      switch (this.mode) {
        case 'lessons': return this.$store.state.lessons.curLesson
        case 'reviews': return this.$store.state.reviews.curReview
        case 'single': return this.vocab
        default: return null
      }
    },
    vocabKanji () {
      if (this.hideKana && this.vocabItem.accent) {
        return pitchaccent.generate(this.vocabItem.vocab, this.vocabItem.accent)
      } else {
        return this.vocabItem.vocab
      }
    },
    vocabReading () {
      if (this.vocabItem.accent) {
        return pitchaccent.generate(this.vocabItem.vocab_kana, this.vocabItem.accent)
      } else {
        return this.vocabItem.vocab_kana
      }
    },
    sentenceOrPlaceholder () {
      switch (this.mode) {
        case 'lessons': return this.$store.state.settings.lessonsHideSentence ? this.$store.state.settings.placeholder : this.vocabItem.sentence_meaning
        default: return this.vocabItem.sentence_meaning
      }
    },
    customVocab () {
      return this.vocabItem.id >= this.$store.state.vocabulary.customVocabStartIndex
    },
    japanesePollyVoices () {
      return this.$store.getters.japanesePollyVoices
    },
    englishPollyVoices () {
      return this.$store.getters.englishPollyVoices
    },
    showVocab () {
      return this.mode === 'single' ||
        (this.mode === 'reviews' && this.vocabItem.reviewMode.endsWith('ToJp'))
    },
    showMeaning () {
      return ['lessons', 'single'].includes(this.mode) ||
        (this.mode === 'reviews' && this.vocabItem.reviewMode.endsWith('ToEn'))
    },
    sense () {
      return JSON.parse(this.vocabItem.meaning)[this.vocabItem.jmdict_sense - 1]
    },
    gloss () {
      if (this.customVocab) return this.vocabItem.meaning
      else return jmdict.getGlossAsString(this.vocabItem)
    },
    info () {
      if (!this.customVocab) {
        const relevantInfo = jmdict.getRelevantInformation(this.vocabItem, this.vocabItem.jmdict_sense)
        if (this.sense.s_inf) {
          return [...relevantInfo, ...this.sense.s_inf].join('; ')
        } else if (relevantInfo.length > 0) {
          return relevantInfo.join('; ')
        }
      }

      return false
    },
    posDetails () {
      return jmdict.getPartsOfSpeech(this.vocabItem, this.vocabItem.jmdict_sense)
    },
    pos () {
      if (this.customVocab) return this.vocabItem.pos ? this.vocabItem.pos.split(',') : []
      else return this.posDetails.full
    },
    posColor () {
      if (this.customVocab) return this.vocabItem.pos ? 'brown' : ''
      else return this.posDetails.color
    },
    examples () {
      // Custom vocab has no example sentences
      if (!this.vocabItem.examples) return ''

      const arr = this.vocabItem.examples.split('<br>').map(item => item.trim())
      const obj = []
      for (const i in arr) {
        for (let a = 0, b = 1, c = 2; c < arr[i].length; a++, b++, c++) {
          if (this.$waka.isJapanese(arr[i].charAt(a)) &&
              arr[i].charAt(b) === ' ' &&
              this.$waka.isRomaji(arr[i].charAt(c))) {
            const jp = arr[i].slice(0, b)
            const en = arr[i].slice(c)
            obj.push({ jp, en })
            break
          }
        }
      }
      return obj
    },
    hasCustomContent () {
      return this.synonyms || this.readings || this.notes
    },
    notesCharLimit () {
      if (this.prime) return this.$store.state.limits.notesLength.pro
      else return this.$store.state.limits.notesLength.free
    },
    synonyms () {
      // Splitting synonyms string into single elements, trimming whitespace and filtering out empty elements
      if (this.vocabItem.synonyms) {
        return this.vocabItem.synonyms.split(';').map(item => item.trim()).filter(e => e)
      } else {
        return ''
      }
    },
    synonymsJoined () {
      return this.synonyms ? this.synonyms.join(', ') : ''
    },
    readings () {
      // Splitting readings string into single elements, trimming whitespace and filtering out empty elements
      if (this.vocabItem.readings) {
        return this.vocabItem.readings.split(';').map(item => item.trim()).filter(e => e)
      } else {
        return ''
      }
    },
    dialog: {
      get () {
        return this.showDialog
      },
      set (newState) {
        this.showDialog = newState
        this.$store.dispatch('setKeysEnabled', !newState)
      }
    },
    pitchAccentClass () {
      const theme = this.$vuetify.theme.dark ? 'dark' : 'light'
      return this.$store.state.settings.theme[theme].pitchAccentColors.enable
        ? 'pitch--colored'
        : 'pitch--plain'
    },
    prime () {
      return this.$store.getters.proUser
    }
  },
  watch: {
    vocabItem () {
      this.selectedTab = 0
      this.syn = ''
      this.read = ''
      this.notes = this.vocabItem.notes
    }
  },
  methods: {
    tabLeft () {
      if (this.selectedTab > 0) this.selectedTab--
      else this.selectedTab = 2
    },
    tabRight () {
      if (this.selectedTab < 2) this.selectedTab++
      else this.selectedTab = 0
    },
    playWordAudio () {
      audio.playVocabAudio(this.vocabItem.vocab_audio)
    },
    playSentenceAudio () {
      audio.playVocabAudio(this.vocabItem.sentence_audio)
    },
    genAudio (text, voice, gender, language, service) {
      this.$store.dispatch('apiPost/synthesizeSpeech', { text, voice, gender, language, service })
    },
    toggleSentenceVisibility () {
      this.$store.dispatch('settings/update', { lessonsHideSentence: !this.$store.state.settings.lessonsHideSentence })
    },
    // Called on blur
    saveNotes () {
      if (this.notes) this.notes = this.notes.trim()
      if (this.notes !== this.vocabItem.notes) {
        this.$store.dispatch('vocabulary/notesEdit', { text: this.notes, vocabId: this.vocabItem.id })
      }
    },
    addSynonym () {
      this.syn = this.syn.replace(/;/g, '').trim()

      if (this.syn && !this.synonyms.includes(this.syn)) {
        this.$store.dispatch('vocabulary/addSynonym', { synonym: this.syn, vocabId: this.vocabItem.id })
        this.syn = ''
      }
    },
    addReading () {
      this.read = this.$waka.toKana(this.read)
      this.read = this.read.replace(/;/g, '').trim()

      if (this.$waka.isKana(this.read) && !this.readings.includes(this.read)) {
        this.$store.dispatch('vocabulary/addReading', { reading: this.read, vocabId: this.vocabItem.id })
        this.read = ''
      }
    },
    removeSynonym (synonym) {
      this.$store.dispatch('vocabulary/removeSynonym', { synonym, vocabId: this.vocabItem.id })
    },
    removeReading (reading) {
      this.$store.dispatch('vocabulary/removeReading', { reading, vocabId: this.vocabItem.id })
    },
    openJisho () {
      return 'https://jisho.org/search/' + this.vocabItem.vocab
    },
    openGoo () {
      return 'https://dictionary.goo.ne.jp/srch/all/' + this.vocabItem.vocab + '/m0u/'
    },
    openTatoeba () {
      return 'https://tatoeba.org/eng/sentences/search?query=' + this.vocabItem.vocab + '&from=jpn&to=eng&orphans=no&unapproved=no'
    },
    openWaniKani () {
      return 'https://wanikani.com/vocabulary/' + this.vocabItem.vocab
    },
    openGoogle () {
      return 'https://google.co.jp/search?tbm=isch&q=' + this.vocabItem.vocab
    },
    closeIssueDialog () {
      this.$refs.formIssue.reset()
      this.issueResponse = ''
      this.dialog = false
    },
    submitIssue () {
      const contactEmail = 'N/A'
      const subject = this.issue
      const message = `${this.vocabItem.id} ${this.vocabItem.vocab} (${this.vocabItem.vocab_kana}) ${this.issueText}`

      if (subject) {
        this.sending = true
        this.$store.dispatch('apiPost/inquiry', { contactEmail, subject, message, files: [] }).then(response => {
          if (response.data.success) {
            this.issueSubmitSuccess = true
            this.issueResponse = 'Thank you, your inquiry has been received!'
          } else {
            this.issueSubmitSuccess = false
            this.issueResponse = response.data.error_description
          }

          this.sending = false
          this.$refs.formIssue.reset()
        })
      }
    },
    keyHandler (event) {
      // Return if key event handling is temporarily disabled (e.g. if v-dialog/v-overlay is active)
      if (!this.$store.state.keysEnabled) return

      // Return if textfield or textarea are focused
      const exclude = ['input', 'textarea']
      if (exclude.indexOf(event.target.tagName.toLowerCase()) !== -1) {
        return
      }

      if (event.code === 'KeyD' || event.code === 'ArrowRight') {
        this.tabRight()
      } else if (event.code === 'KeyA' || event.code === 'ArrowLeft') {
        this.tabLeft()
      }
    }
  },
  created () {
    window.addEventListener('keyup', this.keyHandler)
  },
  mounted () {
    this.notes = this.vocabItem.notes
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyHandler)
  }
}
</script>
