import vars from '@/vars.js'

const url = 'torii-srs.com'
const apiUrl = process.env.NODE_ENV === 'production' ? '/api/v2/' : '/api/dev/'
const cdn = 'https://d2oz1g6djsbmid.cloudfront.net/'
const audioUrl = cdn + 'audio/'
const contactEmail = 'hello@torii-srs.com'
const supportEmail = 'support@torii-srs.com'
const localStorageNetworkQueueKeyName = vars.localStorageKeys.sync

const state = {
  networkQueue: JSON.parse(localStorage.getItem(localStorageNetworkQueueKeyName)),
  url: url,
  repoUrl: 'https://github.com/rakantor/torii-srs-public',
  contactEmail: contactEmail,
  supportEmail: supportEmail
}

const getters = {
  itemsInQueue (state) {
    return state.networkQueue.vocab.length + state.networkQueue.custom.length
  }
}

const mutations = {
  initNetworkQueue (state) {
    // Integrity check
    if (!state.networkQueue) state.networkQueue = { vocab: [], custom: [], deckMeta: [] }
    if (!state.networkQueue.vocab) state.networkQueue.vocab = []
    if (!state.networkQueue.custom) state.networkQueue.custom = []
    if (!state.networkQueue.deckMeta) state.networkQueue.deckMeta = []
  },
  addToNetworkQueue (state, payload) {
    // if (!state.networkQueue) state.networkQueue = { vocab: [], custom: [] }
    if (payload.type === 'vocab') {
      const vocabIds = payload.data
      const regularVocab = state.networkQueue.vocab
      const customVocab = state.networkQueue.custom

      vocabIds.forEach(vocabId => {
        // regular vocab
        if (vocabId < this.state.vocabulary.customVocabStartIndex) {
          if (!regularVocab.includes(vocabId)) regularVocab.push(vocabId)
        // custom vocab
        } else {
          if (!customVocab.includes(vocabId)) customVocab.push(vocabId)
        }
      })

      console.log(`Added ${vocabIds.length} items to upload queue: ${vocabIds.join(',')}.`)
    } else if (payload.type === 'deckMeta') {
      const deckIds = payload.data
      deckIds.forEach(deckId => {
        if (!state.networkQueue.deckMeta.includes(deckId)) state.networkQueue.deckMeta.push(deckId)
      })
    }

    // write to local storage
    localStorage.setItem(localStorageNetworkQueueKeyName, JSON.stringify(state.networkQueue))
  },
  removeFromNetworkQueue (state, payload) {
    // if (!state.networkQueue) state.networkQueue = { vocab: [], custom: [] }
    if (payload.type === 'vocab') {
      const vocabIds = payload.data
      const regularVocab = state.networkQueue.vocab
      const customVocab = state.networkQueue.custom

      vocabIds.forEach(vocabId => {
        // regular vocab
        if (vocabId < this.state.vocabulary.customVocabStartIndex) {
          if (regularVocab.includes(vocabId)) regularVocab.splice(regularVocab.indexOf(vocabId), 1)
        // custom vocab
        } else {
          if (customVocab.includes(vocabId)) customVocab.splice(customVocab.indexOf(vocabId), 1)
        }
      })

      console.log(`Removed ${vocabIds.length} items from Network queue: ${vocabIds.join(',')}.`)
    } else if (payload.type === 'deckMeta') {
      const deckIds = payload.data
      deckIds.forEach(deckId => {
        if (state.networkQueue.deckMeta.includes(deckId)) state.networkQueue.deckMeta.splice(state.networkQueue.deckMeta.indexOf(deckId), 1)
      })
    }

    // write to local storage
    localStorage.setItem(localStorageNetworkQueueKeyName, JSON.stringify(state.networkQueue))
  },
  emptyNetworkQueue (state, type) {
    state.networkQueue = {
      vocab: ['vocab', 'all'].includes(type) ? [] : state.networkQueue.vocab,
      custom: ['custom', 'all'].includes(type) ? [] : state.networkQueue.custom,
      deckMeta: ['deckMeta', 'all'].includes(type) ? [] : state.networkQueue.deckMeta
    }

    // write to local storage
    localStorage.setItem(localStorageNetworkQueueKeyName, JSON.stringify(state.networkQueue))

    console.log(`Network queue [${type}] emptied.`)
  }
}

const actions = {
  initNetworkQueue (context) {
    context.commit('initNetworkQueue')
  },
  addToNetworkQueue (context, payload) {
    context.commit('addToNetworkQueue', payload)
  },
  removeFromNetworkQueue (context, payload) {
    context.commit('removeFromNetworkQueue', payload)
  },
  emptyNetworkQueue (context, type) {
    context.commit('emptyNetworkQueue', type)
  }
}

export default {
  namespaced: true,
  url,
  apiUrl,
  audioUrl,
  contactEmail,
  supportEmail,
  state,
  getters,
  mutations,
  actions
}
