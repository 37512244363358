<template>
<v-container fluid class="pa-0">
  <WelcomeDialog ref="wcd" />
  <div style="height: 6px;">
    <v-slide-x-transition>
      <v-progress-linear v-show="loading" indeterminate height="100%"></v-progress-linear>
    </v-slide-x-transition>
  </div>
  <QuickSettings v-if="!$vuetify.breakpoint.smAndDown" />
  <v-container>
    <v-alert v-model="maintenanceNotice" text outlined prominent dismissible type="error" :icon="false" border="left" transition="slide-y-transition">
      <div class="text-h6">Scheduled Maintenance - {{ maintenanceDate }}</div>
      <div>During the maintenance our website may be unavailable.</div>
      <div>You can continue to use Torii in <strong>Offline Mode</strong> during this time.</div>
    </v-alert>
    <v-alert v-model="showAnnouncement" text outlined prominent dismissible color="lessons" :icon="false" border="left" transition="slide-y-transition">
      <div class="text-h6">{{ announcement.title }}</div>
      <div>{{ announcement.body }}</div>
      <div v-if="announcement.readMore">
        <v-dialog v-model="showAnnouncementReadMore" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on"
              dark depressed color="lessons" class="mt-3"
              @click="showAnnouncementReadMore = true">
              Read more
            </v-btn>
          </template>
          <v-card>
            <v-card-title>{{ announcement.title }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text v-html="announcement.readMore"></v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined color="primary" @click="showAnnouncementReadMore = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-alert>
    <v-alert v-if="vacationMode" text outlined type="info" icon="mdi-palm-tree" border="left">Vacation mode is active. Enjoy your time off!</v-alert>
      <v-row>
        <template v-if="$vuetify.breakpoint.lgAndUp">
          <v-col cols="7">
            <v-row>
              <v-col cols="12"><DashboardCard /></v-col>
              <v-col cols="12"><ForecastChartParent /></v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <StudyCalendar />
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12">
            <v-row>
              <v-col cols="12"><DashboardCard /></v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <StudyCalendar />
          </v-col>
          <v-col cols="12">
            <ForecastChartParent />
          </v-col>
        </template>
        <v-col cols="12">
          <ProgressCardsBar ref="pcb" />
        </v-col>
      </v-row>
  </v-container>
  <v-snackbar
    :value="loading"
    multi-line
    text
    outlined
    :timeout="-1"
    border="left"
    color="primary"
    elevation="2"
    class="rounded-edges"
  >
    <v-row>
      <v-col cols="auto" shrink>
        <v-progress-circular indeterminate></v-progress-circular>
      </v-col>
      <v-col grow>
        <div>Synchronizing...</div>
        <div>{{ pendingTasks }}</div>
      </v-col>
    </v-row>
  </v-snackbar>
</v-container>
</template>

<script>
import QuickSettings from './QuickSettings.vue'
import ProgressCardsBar from './ProgressCardsBar.vue'
import StudyCalendar from './StudyCalendar.vue'
import DashboardCard from './DashboardCard.vue'
import WelcomeDialog from './WelcomeDialog.vue'
import ForecastChartParent from './ForecastChartParent.vue'
import moment from 'moment'

export default {
  components: {
    QuickSettings,
    ProgressCardsBar,
    StudyCalendar,
    DashboardCard,
    WelcomeDialog,
    ForecastChartParent
  },
  data () {
    return {
      key: 0,
      welcome: true,
      showAnnouncementReadMore: false
    }
  },
  computed: {
    loading () {
      return this.$store.getters['apiGet/syncing'] || this.$store.state.vocabulary.isInitialising
    },
    pendingTasks () {
      const op = this.$store.getters['apiGet/requestsPending'].length
      return `${op} task${op === 1 ? '' : 's'} remaining...`
    },
    maintenanceNotice: {
      get () {
        const hide = this.$store.state.settings.hideMaintenance
        return !!this.maintenanceDate && hide !== undefined && hide !== this.maintenanceDate
      },
      set (show) {
        this.$store.dispatch('settings/updateLocal', { hideMaintenance: this.maintenanceDate })
      }
    },
    maintenanceDate () {
      return this.$store.state.dashboard.maintenanceDate
    },
    showAnnouncement: {
      get () {
        const hide = this.$store.state.settings.hideAnnouncement
        return !!this.announcement.title && this.announcement.ed > moment.utc().unix() && hide !== undefined && hide !== this.announcement.ed
      },
      set (show) {
        this.$store.dispatch('settings/updateLocal', { hideAnnouncement: this.announcement.ed })
      }
    },
    announcement () {
      return this.$store.state.dashboard.announcement
    },
    vacationMode () {
      return this.$store.getters['user/vacationMode']
    }
  },
  created () {
    // this.$store.dispatch('apiGet/users')
    // Reloading UI if settings or vocab changed
    if (this.$store.state.requireReload) {
      this.$store.dispatch('reload')
    }
  }
}
</script>
