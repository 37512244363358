<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-slide-y-transition>
          <v-btn
            v-if="$store.state.settings.showQuickSettings"
            depressed
            :elevation="buttonStyle.elevation"
            :color="buttonStyle.color"
            :class="buttonStyle.class"
            :style="buttonStyle.style"
            v-bind="attrs" v-on="on"
          >
            <v-icon id="btnQuickSettings" color="white">mdi-cog</v-icon>
            <v-expand-x-transition v-if="floating">
              <span v-if="hover || menu" class="transition-fast-in-fast-out white--text ml-2">Quick Settings</span>
            </v-expand-x-transition>
          </v-btn>
        </v-slide-y-transition>
      </v-hover>
    </template>
    <v-card max-width="400">
      <v-card-title>
        <v-row no-gutters>
          <v-col class="grow">Quick Settings</v-col>
          <v-col class="shrink">
            <v-btn icon @click="menu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-title>Study Mode</v-list-item-title>
          <v-list-item-action>
            <v-text-field readonly dense hide-details
              v-model="studyMode"
              append-icon="mdi-cog"
              class="text-caption"
              @click:append="showStudyModeDialog"
            ></v-text-field>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Review Mode</v-list-item-title>
            <v-select dense
              :items="reviewModes"
              v-model="reviewMode"
              item-value="id"
              item-text="titleFull"
              class="text-caption"
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            Quick Review
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" right>mdi-help-circle-outline</v-icon>
              </template>
              Quick Review lets you review your items without typing in the answer.
            </v-tooltip>
          </v-list-item-title>
          <v-list-item-action>
            <v-switch v-model="flashcardMode" dense inset class="text-caption hide-messages">
            </v-switch>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-card-text>
        Tip: You can choose not to show the Quick Settings button.
        Just click on <code>
          <v-icon>{{ $store.state.navDrawerItems.appearance.icon }}</v-icon>
          {{ $store.state.navDrawerItems.appearance.title }}
        </code> in the navigation drawer.
      </v-card-text>
    </v-card>
    <StudyModeDialog ref="smd" />
  </v-menu>
</template>

<script>
import StudyModeDialog from './StudyModeDialog.vue'

export default {
  props: {
    floating: {
      Type: Boolean,
      default: true
    }
  },
  components: {
    StudyModeDialog
  },
  data () {
    return {
      menu: false,
      buttonStyle: {
        elevation: this.floating ? 2 : 0,
        color: this.floating ? 'rgba(0, 0, 0, 0.5)' : 'primary',
        style: this.floating ? 'position:absolute;top:0;left:0;z-index:5;' : '',
        class: this.floating ? 'rounded-l-0 rounded-t-0 rounded-edges' : ''
      }
    }
  },
  computed: {
    vacationMode () {
      return this.$store.getters['user/vacationMode']
    },
    studyMode () {
      return this.$store.getters['settings/decks'].find(x => x.id === this.$store.state.settings.studyMode.id).title
    },
    reviewModes () {
      return this.$store.state.reviewModes
    },
    reviewMode: {
      get () {
        return this.$store.state.settings.reviewMode
      },
      set (newMode) {
        this.$store.dispatch('settings/update', { reviewMode: newMode })
        // this.$store.dispatch('vocabulary/init')
      }
    },
    flashcardMode: {
      get () {
        return this.$store.state.settings.flashcardMode
      },
      set (enabled) {
        this.$store.dispatch('settings/updateLocal', { flashcardMode: enabled })
      }
    }
  },
  methods: {
    showStudyModeDialog () {
      this.menu = false
      this.$refs.smd.init()
    }
  }
}
</script>
