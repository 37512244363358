<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title class="headline">{{title}}</v-card-title>
      <v-card-text>{{text}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="leavePage">Understood</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      title: '',
      titles: {
        success: 'Success',
        error: 'Something went wrong'
      },
      text: '',
      texts: {
        success: 'You can now login with your new password.',
        error: 'Your password couldn\'t be changed. Please try again.'
      }
    }
  },
  methods: {
    init (success) {
      if (success) {
        this.title = this.titles.success
        this.text = this.texts.success
      } else {
        this.title = this.titles.error
        this.text = this.texts.error
      }
      this.dialog = true
    },
    leavePage () {
      this.dialog = false
      this.$router.push('login')
    }
  }
}
</script>
