<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div>
          <div class="d-block text-h5 font-weight-medium">よくできました。</div>
          <div class="d-block font-weight-regular">Here's your session summary.</div>
        </div>
        <v-spacer></v-spacer>
        <router-link to="dashboard">
      <v-img src="@/assets/img/logo.png" height="46" max-width="60" contain></v-img>
      </router-link>
      </v-card-title>
      <v-divider class="mb-2"></v-divider>
      <v-row v-if="incorrectItems.length">
        <v-col cols="12">
          <v-toolbar color="error" elevation="0">
            <v-toolbar-title class="text-h6 red--text text--darken-4 font-weight-bold">
              {{ incorrectItems.length }}
              <v-icon color="red darken-4">mdi-arrow-down-circle-outline</v-icon>
              Answered Incorrectly
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-card-text>
          <v-col v-for="(element, i) in incorrectItemsBySrsStage" :key="i" cols="12">
            <div class="text-subtitle-1">
              <strong>{{ element.items.length }}→</strong>
              {{ srsStageName(element.srsStage) }}</div>
            <v-tooltip v-for="(item, index) in element.items" :key="index" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" label :color="srsStageColor(element.srsStage)" class="my-1 mr-1" style="max-width: 100px;">
                  <span class="d-inline-block text-truncate">{{ chipText(item) }}</span>
                </v-chip>
              </template>
              <span>{{ chipTooltip(item) }}</span>
            </v-tooltip>
          </v-col>
        </v-card-text>
      </v-row>
      <v-row v-if="correctItems.length">
        <v-col cols="12">
          <v-toolbar color="success" elevation="0">
            <v-toolbar-title class="text-h6 green--text text--darken-4 font-weight-bold">
              {{ correctItems.length }}
              <v-icon color="green darken-4">mdi-arrow-up-circle-outline</v-icon>
              Answered Correctly
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-card-text>
          <v-col v-for="(element, i) in correctItemsBySrsStage" :key="i" cols="12">
            <div class="text-subtitle-1">
              <strong>{{ element.items.length }}→</strong>
              {{ srsStageName(element.srsStage) }}</div>
            <v-tooltip v-for="(item, index) in element.items" :key="index" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" label :color="srsStageColor(element.srsStage)" class="my-1 mr-1" style="max-width: 100px;">
                  <span class="d-inline-block text-truncate">{{ chipText(item) }}</span>
                </v-chip>
              </template>
              <span>{{ chipTooltip(item) }}</span>
            </v-tooltip>
          </v-col>
        </v-card-text>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" to="dashboard">
          <v-icon left>mdi-home</v-icon>
          Dashboard
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { jmdict } from '@/plugins/jmdict.js'

export default {
  data () {
    return {}
  },
  computed: {
    incorrectItems () {
      return this.$store.state.reviews.sessionAnsweredIncorrectly
    },
    correctItems () {
      return this.$store.state.reviews.sessionAnsweredCorrectly
    },
    incorrectItemsBySrsStage () {
      return this.sortItemsBySrsStage(this.incorrectItems)
    },
    correctItemsBySrsStage () {
      return this.sortItemsBySrsStage(this.correctItems)
    }
  },
  methods: {
    isCustomVocab (vocab) {
      return vocab.id >= this.$store.state.vocabulary.customVocabStartIndex
    },
    srsStageName (stageId) {
      return this.$store.getters.srsStageName(stageId)
    },
    srsStageColor (stageId) {
      const grpId = this.$store.state.srsStages.find(stage => stage.id === stageId).group
      return this.$store.state.srsGroups.find(grp => grp.id === grpId).color
    },
    chipText (item) {
      switch (item.reviewMode) {
        case 'JpToEn':
        case 'JpToJp': return item.vocab
        case 'EnToJp': return this.isCustomVocab(item) ? item.meaning : jmdict.getGlossAsString(item)
      }
    },
    chipTooltip (item) {
      switch (item.reviewMode) {
        case 'JpToEn':
        case 'JpToJp': return this.isCustomVocab(item) ? item.meaning : jmdict.getGlossAsString(item)
        case 'EnToJp': return item.vocab
      }
    },
    sortItemsBySrsStage (items) {
      const obj = []
      this.$store.state.srsStages.forEach(stage => {
        obj.push({ srsStage: stage.id, items: [] })
      })

      items.forEach(item => {
        let stage = -1
        switch (item.reviewMode) {
          case 'JpToEn': stage = item.streak_meaning
            break
          case 'EnToJp': stage = item.streak_reading
            break
          case 'JpToJp': stage = item.streak_jptojp
            break
        }

        obj[obj.findIndex(el => el.srsStage === stage)].items.push(item)
      })

      return obj.filter(el => { return el.items.length > 0 })
    }
  }
}
</script>
