import vars from '@/vars.js'

const state = {
  name: 'User',
  accountCreated: undefined,
  lastOnline: undefined,
  dailyStats: [],
  studyStreakBest: 0,
  timeIdle: 0,
  timeLessons: 0,
  timeReviews: 0,
  vacationStart: 0,
  vacationEnd: 0
}

const getters = {
  vacationMode (state) {
    return (state.vacationEnd < state.vacationStart) || (state.vacationStart && !state.vacationEnd)
  },
  dailyLessonsRemaining (state, getters, rootState, rootGetters) {
    const lessonsCompletedToday = state.dailyStats.length > 0 ? state.dailyStats[state.dailyStats.length - 1].lessons : 0
    const lessonsRemainingToday = rootState.settings.dailyLimit - lessonsCompletedToday
    const lessonsRemainingOverall = rootGetters['vocabulary/lessons'].length

    if (lessonsRemainingOverall < lessonsRemainingToday) return lessonsRemainingOverall
    else return lessonsRemainingToday > 0 ? lessonsRemainingToday : 0
  }
}

const mutations = {
  init (state, obj) {
    for (const item in obj) {
      state[item] = obj[item]
    }

    console.log('%cUser loaded.', 'color: green')
    // Loading settings from IndexedDB after user has been initialized
    // this.dispatch('settings/init')
  },
  update (state, obj) {
    for (const item in obj) {
      if (typeof obj[item] === 'boolean') obj[item] = obj[item] ? 1 : 0
      state[item] = obj[item]
    }
  },
  updateDailyStats (state, reviewSessionMode) {
    switch (reviewSessionMode) {
      case vars.reviewSessionModes.lessons: state.dailyStats[state.dailyStats.length - 1].lessons++
        break
      case vars.reviewSessionModes.recentLessons: state.dailyStats[state.dailyStats.length - 1].recentLessons++
        break
      case vars.reviewSessionModes.recentMistakes: state.dailyStats[state.dailyStats.length - 1].recentMistakes++
        break
      default: state.dailyStats[state.dailyStats.length - 1].reviews++
    }
  }
}

const actions = {
  init (context, user) {
    const arrDailyStats = user.daily_stats
      ? user.daily_stats.split(';').filter(e => e).map(day => {
        const b = day.split(',')
        return {
          date: b[0],
          lessons: parseInt(b[1]),
          reviews: parseInt(b[2]),
          selfStudy: parseInt(b[3]),
          recentLessons: parseInt(b[4]),
          recentMistakes: parseInt(b[5])
        }
      })
      : []

    const now = this._vm.$date.utc().unix()
    const obj = {
      name: user.username || 'User',
      accountCreated: user.created,
      lastOnline: now,
      dailyStats: arrDailyStats,
      studyStreakBest: user.study_streak_best,
      timeIdle: user.torii_time,
      timeLessons: user.study_time,
      timeReviews: user.review_time,
      vacationStart: user.vacation_start,
      vacationEnd: user.vacation_end
    }

    context.commit('init', obj)
  },
  update (context, obj) {
    context.commit('update', obj)
    context.dispatch('save')
  },
  updateDailyStats (context) {
    // Resetting daily lesson limit if required
    const today = this._vm.$date.utc().add(context.rootState.settings.timezone, 'hours').toISOString().substr(0, 10)
    if (context.state.dailyStats.length === 0 || context.state.dailyStats[context.state.dailyStats.length - 1].date !== today) {
      console.log('First login today.')
      const dailyStats = Array.from(context.state.dailyStats)
      dailyStats.push({ date: today, lessons: 0, reviews: 0, selfStudy: 0, recentLessons: 0, recentMistakes: 0 })
      context.commit('update', { dailyStats })
    }

    context.commit('updateDailyStats', context.rootState.reviews.mode)
    context.dispatch('save')
  },
  save (context) {
    let strDailyStats = ''
    context.state.dailyStats.forEach(e => {
      strDailyStats = `${strDailyStats}${e.date},${e.lessons || 0},${e.reviews || 0},${e.selfStudy || 0},${e.recentLessons || 0},${e.recentMistakes || 0};`
    })

    const set = {
      username: context.state.name,
      last_online_web: context.state.lastOnline,
      daily_stats: strDailyStats,
      study_streak_best: context.state.studyStreakBest,
      torii_time: context.state.timeIdle,
      study_time: context.state.timeLessons,
      review_time: context.state.timeReviews,
      vacation_start: context.state.vacationStart,
      vacation_end: context.state.vacationEnd
    }

    // Save to IndexedDB
    this._vm.$db.user.update(0, set).then(updated => {
      if (updated) {
        const now = this._vm.$date.utc().unix()
        this._vm.$db.sync.update(0, { user: now })
        // Save to server
        context.dispatch('apiPost/updateUserInfo', null, { root: true })
      }
    })
  },
  vacationMode (context, curState) {
    return new Promise((resolve, reject) => {
      const now = this._vm.$date.utc().unix()

      // Vacation mode is active --> deactivate
      if (curState) {
        context.dispatch('update', { vacationEnd: now })
        const diffSec = context.state.vacationEnd - context.state.vacationStart
        // Vacation mode must have been active for > 1 hour to affect the SRS
        if (diffSec > 3600) {
          // custom vocab
          this._vm.$db.custom.toCollection().modify(item => {
            item.due_meaning += diffSec
            item.due_reading += diffSec
            item.due_jptojp += diffSec
          })
          // regular vocab
          this._vm.$db.progress.toCollection().modify(item => {
            item.due_meaning += diffSec
            item.due_reading += diffSec
            item.due_jptojp += diffSec
          }).then(() => {
            context.dispatch('requireReload', null, { root: true })
            resolve()
          })
        } else {
          resolve()
        }
      // Vacation mode is inactive --> activate
      } else {
        context.dispatch('update', { vacationStart: now })
        resolve()
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
