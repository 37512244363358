<template>
<v-dialog
  v-model="show"
  scrollable
>
  <v-card>
    <v-card-title>1. Install the fonts you want</v-card-title>
    <v-card-subtitle>A great resource is <a href="https://FreeJapaneseFont.com" target="_blank">FreeJapaneseFont.com</a>.</v-card-subtitle>
    <v-card-title>2. Add name of font to the list below</v-card-title>
    <v-card-title>3. Restart your browser</v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle class="text-subtitle-2">Installed Fonts</v-card-subtitle>
    <v-card-text>
      <div v-for="font of installedFonts" :key="font" :style="{ fontFamily: font }">
        <v-icon left color="success" @click="removeFont(font)">mdi-check</v-icon>
        {{ font + ' [あいうえお アイウエオ 日本語]' }}
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-subtitle class="text-subtitle-2">Commonly Used Fonts</v-card-subtitle>
    <v-card-text>
      <div v-for="font of fonts" :key="font">
        <v-icon left @click="removeFont(font)">mdi-delete</v-icon>
        {{ font }}
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-text-field v-model="textField" placeholder="Font name"></v-text-field>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined @click="show = false">Close</v-btn>
      <v-btn color="primary" @click="addFont">Add Font</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      textField: '',
      fonts: [
        // Default Windows fonts
        'Meiryo',
        'メイリオ',
        'MS PGothic',
        'ＭＳ Ｐゴシック',
        'MS Gothic',
        'ＭＳ ゴック',
        'MS PMincho',
        'ＭＳ Ｐ明朝',
        'MS Mincho',
        'ＭＳ 明朝',
        'Yu Gothic',
        'Yu Mincho',
        // Default OS X fonts
        'Hiragino Kaku Gothic Pro',
        'ヒラギノ角ゴ Pro W3',
        'Hiragino Maru Gothic Pro',
        'ヒラギノ丸ゴ Pro W3',
        'Hiragino Mincho Pro',
        'ヒラギノ明朝 Pro W3',
        // Common Linux fonts
        'Takao Gothic',
        'Takao Mincho',
        'Sazanami Gothic',
        'Sazanami Mincho',
        'Kochi Gothic',
        'Kochi Mincho',
        'Dejima Mincho',
        'Ume Gothic',
        'Ume Mincho',
        // Other Japanese fonts people use
        'Noto Sans JP',
        'Noto Sans CJK JP',
        'EPSON 行書体Ｍ',
        'EPSON 正楷書体Ｍ',
        'EPSON 教科書体Ｍ',
        'EPSON 太明朝体Ｂ',
        'EPSON 太行書体Ｂ',
        'EPSON 丸ゴシック体Ｍ',
        'cinecaption',
        'nagayama_kai',
        'A-OTF Shin Maru Go Pro',
        'Hosofuwafont',
        'ChihayaGothic',
        'darts font',
        'santyoume-font',
        'FC-Flower',
        'ArmedBanana',
        'HakusyuKaisyoExtraBold_kk',
        'aoyagireisyosimo2',
        'AoyagiKouzanFont2OTF',
        'aquafont'
      ],
      installedFonts: []
    }
  },
  methods: {
    checkInstalledFonts () {
      this.installedFonts = []

      for (const font of this.fonts) {
        const installed = document.fonts.check(`12px ${font}`)
        if (installed) this.installedFonts.push(font)
      }
    },
    removeFont (name) {
      this.fonts.splice(this.fonts.indexOf(name), 1)
      this.checkInstalledFonts()
      this.$store.dispatch('settings/updateLocal', { japaneseFonts: this.fonts })
    },
    addFont () {
      this.fonts.push(this.textField)
      this.textField = ''
      this.checkInstalledFonts()
      this.$store.dispatch('settings/updateLocal', { japaneseFonts: this.fonts })
    }
  },
  mounted () {
    if (this.$store.state.settings.japaneseFonts.length !== 0) this.fonts = Array.from(this.$store.state.settings.japaneseFonts)
    this.checkInstalledFonts()
  }
}
</script>
