<template>
<v-container>
  <v-card>
    <v-tabs v-model="selectedTab" fixed-tabs>
      <v-tab>Add New</v-tab>
      <v-tab :disabled="!$store.getters.online">Browse Dictionary</v-tab>
    </v-tabs>
    <v-card-subtitle>
      You have <strong>{{ customVocabCount }}</strong> custom vocab items.<br />
      You can <strong>view</strong> and <strong>edit</strong> them
      <router-link :to="{ name: 'browse', params: { selListId: 'custom' }}"><strong>here.</strong></router-link>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-tabs-items
      v-model="selectedTab"
      style="background-color: var(--v-background-base) !important;"
    >
      <v-overlay v-if="!creationEnabled" absolute>
        <v-card max-width="90%" class="mx-auto">
          <v-card-title>Oh no, you've reached your limit... 😓</v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle>Please note that there is a custom vocab limit of {{ $store.state.limits.customVocab.free }} words for free accounts.
            If you need more space, please consider upgrading to Pro 🙇‍♂️</v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" to="/prime">Features & Pricing</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-overlay>
      <v-tab-item>
        <ManualTab :item="item" />
      </v-tab-item>
      <v-tab-item>
        <DictionaryTab @edit="edit" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</v-container>
</template>

<script>
import ManualTab from '@/vocab/ManualTab.vue'
import DictionaryTab from '@/vocab/DictionaryTab.vue'

export default {
  components: {
    ManualTab,
    DictionaryTab
  },
  data () {
    return {
      selectedTab: 0, // initially selected tab; set to 1 for dictionary tab
      item: null
    }
  },
  computed: {
    customVocabCount () {
      return this.$store.getters['vocabulary/customVocab'].length
    },
    creationEnabled () {
      return this.editing ||
        this.$store.getters['vocabulary/customVocab'].length < this.$store.state.limits.customVocab.free ||
        this.$store.getters.proUser
    }
  },
  methods: {
    edit (vocab) {
      this.item = vocab
      this.selectedTab = 0
    }
  }
}
</script>
