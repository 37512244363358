<template>
<v-container fill-height>
  <v-row justify="center" align="center">
    <v-col>
      <v-card max-width="500px" color="rgb(255, 255, 255, 0.8)" elevation="12" class="mx-auto">
        <v-card-title class="text-h4">
          おかえり～  ฅʕᵔᴥᵔʔ
        </v-card-title>
        <v-divider class="mx-5" />
        <v-card-text>
          <v-form v-model="valid" ref="loginForm">
            <v-text-field
              v-model="email"
              :rules="loginRules"
              label="Email"
              prepend-icon="mdi-account-circle"
              @keyup.enter="login"
            />
            <v-text-field
              v-model="password"
              :rules="loginRules"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="login"
            />
          </v-form>
          <v-btn block depressed color="primary" :loading="loading" :disabled="loading" @click="login">Sign in</v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-btn v-if="signupEnabled" text block color="primary" @click="$emit('showSignUp')">Don't have an account?</v-btn>
          <v-btn text block color="primary" @click="$refs.pwResetDialog.show = true">Forgot your password?</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <ResetPasswordDialog ref="pwResetDialog" @emailSent="showResetPwSuccessToast"/>
</v-container>
</template>

<script>
import ResetPasswordDialog from './ResetPasswordDialog.vue'

export default {
  data () {
    return {
      defaultErrorReqField: 'Required',
      loading: false,
      valid: false,
      showPassword: false,
      email: '',
      password: '',
      loginRules: [v => !!v || this.defaultErrorReqField]
    }
  },
  components: {
    ResetPasswordDialog
  },
  computed: {
    signupEnabled () {
      // return process.env.VUE_APP_BUILD_TARGET !== 'BETA'
      return true
    }
  },
  methods: {
    login () {
      this.$refs.loginForm.validate()
      if (this.valid) {
        this.loading = true
        this.$store.dispatch('apiPost/login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.success) {
            // Successful login
            this.$router.push('home')
          } else {
            // Unsuccessful login
            this.$snackbar.showMessage({ content: response.data.error_description, color: 'error' })
          }
        }).then(() => {
          this.loading = false
        })
      }
    },
    showResetPwSuccessToast (status) {
      if (status === 'success') this.$snackbar.showMessage({ content: 'We\'ve sent a link to reset your password to your email address.', color: 'success' })
      else if (status === 'invalid') this.$snackbar.showMessage({ content: 'Email not found.', color: 'error' })
      else if (status === 'undefined') this.$snackbar.showMessage({ content: 'Something went wrong.', color: 'error' })
    }
  }
}
</script>
