import axios from 'axios'
import vars from '@/vars.js'
const localStorageKeyAnnouncement = vars.localStorageKeys.announcement
const localStorageKeyMaintenance = vars.localStorageKeys.maintenance

const state = {
  maintenanceDate: '',
  announcement: {
    title: '',
    body: '',
    readMore: '',
    ed: 0
  }
}

const mutations = {
  setAnnouncement (state, obj) {
    state.announcement = obj
    localStorage.setItem(localStorageKeyAnnouncement, JSON.stringify(obj))
  },
  setMaintenanceDate (state, dateString) {
    state.maintenanceDate = dateString
    localStorage.setItem(localStorageKeyMaintenance, JSON.stringify(dateString))
  }
}

const actions = {
  fetchServerNotifications (context) {
    const ann = JSON.parse(localStorage.getItem(localStorageKeyAnnouncement))
    if (ann) context.commit('setAnnouncement', ann)

    const md = JSON.parse(localStorage.getItem(localStorageKeyMaintenance))
    if (md) context.commit('setMaintenanceDate', md)

    axios.get(process.env.BASE_URL + 'notifications.json')
      .then(response => {
        context.commit('setAnnouncement', response.data.announcement)
        context.commit('setMaintenanceDate', response.data.maintenanceDate)
      }).catch(error => {
        console.log(error)
      })
  },
  forecastChartData (context, data) {
    context.commit('forecastChartData', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
