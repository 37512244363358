<template>
<v-container>
  <LoadingOverlay v-if="!cookiesDisabled" />
  <v-dialog v-if="cookiesDisabled" persistent>
    <v-card>
      <v-card-title class="headline">Want some Cookies?</v-card-title>
      <v-img
        class="white--text align-end"
        src="@/assets/img/cookies-disabled.jpeg"
      ></v-img>
      <v-card-text>
        <br/><b>Cookies are disabled</b> in your browser.<br/><br/>
        Torii doesn't work without Cookies. We use Cookies to store session information and to securely authenticate you with the server.<br/><br/>
        Please <b>enable Cookies</b> in your browser settings and then reload this page.<br/><br/>
        <a @click.stop="$refs.cookies.show = true"><strong>How we use Cookies</strong></a>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click="reloadPage">Reload page</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <CookiesPolicy ref="cookies" />
</v-container>
</template>

<script>
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import CookiesPolicy from '@/components/legal/CookiesPolicy.vue'

export default {
  data () {
    return {
      cookiesDisabled: false
    }
  },
  components: {
    LoadingOverlay,
    CookiesPolicy
  },
  methods: {
    reloadPage () {
      this.$router.go(0)
    },
    init () {
      if (!this.$store.state.initialSyncCompleted) {
        this.$store.dispatch('dashboard/fetchServerNotifications')
        this.$store.dispatch('initialSyncDone')

        this.$db.sync.count(count => {
          if (count === 0) {
            console.log('%cInitial setup => pulling db from server...', 'color: yellow')
            this.$db.sync.put({ id: 0, vocabulary: 0, custom: 0, decks: 0, progress: 0, user: 0, settings: 0 })
            this.$store.dispatch('apiGet/vocabularyDb')
            this.$store.dispatch('apiGet/customVocabularyDb')
            this.$store.dispatch('apiGet/decksDb')
            this.$store.dispatch('apiGet/progressDb')
            this.$store.dispatch('apiGet/userInfo')
            this.$store.dispatch('apiGet/userSettings')
          } else {
            this.$store.dispatch('reload')
            this.$store.dispatch('apiGet/synced')
          }
        })
      }
    },
    offline () {
      this.$db.vocabulary.count(count => {
        if (count > 0) {
          this.$store.dispatch('setLoggedIn', true)
          this.init()
          this.$router.push('dashboard')
        } else {
          this.$router.push('lp')
        }
      })
    }
  },
  mounted () {
    if (navigator.cookieEnabled) {
      if (navigator.onLine) {
        this.$store.dispatch('apiPost/session')
          .then((response) => {
            if (response.data.success) {
              this.$store.dispatch('setLoggedIn', true)
              this.$store.dispatch('setProExpiryDate', response.data.pro_exp_date)
              this.init()
              this.$router.push('dashboard')
            } else {
              console.log(response.data.error_description)
              this.$router.push('lp')
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              // API request successful, but responded with status code 401 (Unauthorized)
              console.log('Unauthorized - login required.')
              this.$router.push('lp')
            } else {
              // User offline or server down
              this.offline()
            }
          })
      } else {
        // handle offline
        this.offline()
      }
    } else {
      this.cookiesDisabled = true
    }
  }
}
</script>
