<template>
<v-container class="pa-0">
  <v-list>
    <v-subheader>Review Mode</v-subheader>
    <v-list-item>
      <v-list-item-action>
        <v-select
          :items="reviewModes"
          v-model="reviewMode"
          item-value="id"
          item-text="titleFull"
          :menu-props="{ bottom: true, offsetY: true }"
        ></v-select>
      </v-list-item-action>
    </v-list-item>
    <v-list-item
      link
      :three-line="$vuetify.breakpoint.xsOnly"
      :input-value="flashcardMode"
      @click="flashcardMode = !flashcardMode"
    >
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Quick Review</v-list-item-title>
          <v-list-item-subtitle>Lets you review your items without typing in the answer.</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
  </v-list>
  <v-divider></v-divider>
  <v-list three-line>
    <v-subheader>Japanese Font</v-subheader>
    <v-list-item link :input-value="reviewsFontRandomizer" @click="reviewsFontRandomizer = !reviewsFontRandomizer">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Font Randomizer</v-list-item-title>
          <v-list-item-subtitle>Changes the font to a random Japanese font that is installed on your system.</v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-btn
              color="primary"
              text
              class="ma-0 pa-0"
              @click="$refs.fontRand.show = true"
            >
              How does it work?
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
    <FontRandomizer ref="fontRand" />
  </v-list>
  <v-divider></v-divider>
  <v-list>
    <v-subheader>Input</v-subheader>
    <v-list-item
      link
      :three-line="$vuetify.breakpoint.xsOnly"
      :input-value="reviewsLightningMode"
      @click="reviewsLightningMode = !reviewsLightningMode"
    >
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Lightning Mode</v-list-item-title>
          <v-list-item-subtitle>Immediately move on to the next item after a correct answer.</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-list-item link three-line :input-value="reviewsAutoSubmit" @click="reviewsAutoSubmit = !reviewsAutoSubmit">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>[Experimental] Auto submit</v-list-item-title>
          <v-list-item-subtitle>
            Checks your input against the expected answer while typing.
              If your input is correct, the answer is commited automatically.
              Skip the Enter button.
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-list-item link :input-value="reviewsAutoExpandCorrect" @click="reviewsAutoExpandCorrect = !reviewsAutoExpandCorrect">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Show item info if answer is correct</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-list-item link :input-value="reviewsAutoExpand" @click="reviewsAutoExpand = !reviewsAutoExpand">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Show item info if answer is wrong</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-list-item
      link
      class="ps-10"
      :disabled="!reviewsVocabAudioAutoplay || !reviewsAutoExpand"
      :input-value="reviewsAudioPlayOnIncorrect"
      @click="reviewsAudioPlayOnIncorrect = !reviewsAudioPlayOnIncorrect"
    >
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :disabled="!reviewsVocabAudioAutoplay" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Auto-play Japanese audio</v-list-item-title>
          <v-list-item-subtitle v-if="!reviewsVocabAudioAutoplay && reviewsAutoExpand">
            (Turn on 'Auto-play word audio' below)
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
  </v-list>
  <v-divider></v-divider>
  <v-list>
    <v-subheader>Pitch Accent</v-subheader>
    <v-list-item link :input-value="reviewsPitchAccent" @click="reviewsPitchAccent = !reviewsPitchAccent">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Enable Pitch Accent Graphs</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-divider></v-divider>
    <v-subheader>Furigana</v-subheader>
    <v-list-item link :input-value="reviewsHideKana" @click="reviewsHideKana = !reviewsHideKana">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Hide Furigana</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-list-item link
      class="ps-10"
      :disabled="!reviewsHideKana"
      :input-value="reviewsKanaReveal"
      @click="reviewsKanaReveal = !reviewsKanaReveal">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :disabled="!reviewsHideKana" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Reveal after answer</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
  </v-list>
  <v-divider></v-divider>
  <v-list :three-line="$vuetify.breakpoint.xsOnly">
    <v-subheader>Japanese Audio</v-subheader>
    <v-list-item link :input-value="reviewsVocabAudioAutoplay" @click="reviewsVocabAudioAutoplay = !reviewsVocabAudioAutoplay">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Autoplay vocab audio</v-list-item-title>
          <v-list-item-subtitle>Audio for vocabulary will autoplay when toggled.</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-list-item
      link
      :three-line="$vuetify.breakpoint.smAndDown"
      :input-value="reviewsSentenceAudioAutoplay"
      :disabled="!reviewsVocabAudioAutoplay"
      class="ps-10"
      @click="reviewsSentenceAudioAutoplay = !reviewsSentenceAudioAutoplay"
    >
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active" :disabled="!reviewsVocabAudioAutoplay"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Autoplay sentence audio</v-list-item-title>
          <v-list-item-subtitle>Audio for context sentences will autoplay after vocabulary when toggled.</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-list-item
      link
      :three-line="$vuetify.breakpoint.xsOnly"
      class="ps-10"
      :disabled="!reviewsVocabAudioAutoplay"
      :input-value="reviewsAudioPlayAtStart"
      @click="reviewsAudioPlayAtStart = !reviewsAudioPlayAtStart"
    >
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :disabled="!reviewsVocabAudioAutoplay" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Play at start of review</v-list-item-title>
          <v-list-item-subtitle>
            Note: Autoplay is disabled if Furigana is toggled off.
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-list-item link
      class="ps-10"
      :disabled="!reviewsVocabAudioAutoplay"
      :input-value="reviewsAudioPlayAtEnd"
      @click="reviewsAudioPlayAtEnd = !reviewsAudioPlayAtEnd"
    >
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :disabled="!reviewsVocabAudioAutoplay" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Play after submitting an answer</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
  </v-list>
  <v-divider></v-divider>
  <v-subheader>Audio Reviews (JP → EN)</v-subheader>
  <v-container class="mx-2">
    <v-radio-group v-model="audioReviewMode">
      <v-radio label="Off" :value="0"></v-radio>
      <v-radio label="Alternate" :value="1"></v-radio>
      <v-radio label="On" :value="2"></v-radio>
    </v-radio-group>
  </v-container>
</v-container>
</template>

<script>
import FontRandomizer from '@/components/FontRandomizer.vue'

export default {
  components: {
    FontRandomizer
  },
  computed: {
    reviewModes () {
      return this.$store.state.reviewModes
    },
    reviewMode: {
      get () {
        return this.$store.state.settings.reviewMode
      },
      set (newMode) {
        this.$store.dispatch('settings/update', { reviewMode: newMode })
        // this.$store.dispatch('vocabulary/init')
      }
    },
    flashcardMode: {
      get () {
        return this.$store.state.settings.flashcardMode
      },
      set (enabled) {
        this.$store.dispatch('settings/updateLocal', { flashcardMode: enabled })
      }
    },
    reviewsFontRandomizer: {
      get () {
        return this.$store.state.settings.reviewsFontRandomizer
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsFontRandomizer: value })
      }
    },
    reviewsLightningMode: {
      get () {
        return this.$store.state.settings.reviewsLightningMode
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsLightningMode: value })
      }
    },
    reviewsAutoSubmit: {
      get () {
        return this.$store.state.settings.reviewsAutoSubmit
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsAutoSubmit: value })
      }
    },
    reviewsAutoExpand: {
      get () {
        return this.$store.state.settings.reviewsAutoExpand
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsAutoExpand: value })
      }
    },
    reviewsAutoExpandCorrect: {
      get () {
        return this.$store.state.settings.reviewsAutoExpandCorrect
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsAutoExpandCorrect: value })
      }
    },
    reviewsPitchAccent: {
      get () {
        return this.$store.state.settings.reviewsPitchAccent
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsPitchAccent: value })
      }
    },
    reviewsHideKana: {
      get () {
        return this.$store.state.settings.reviewsHideKana
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsHideKana: value })
      }
    },
    reviewsKanaReveal: {
      get () {
        return this.$store.state.settings.reviewsKanaReveal
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsKanaReveal: value })
      }
    },
    reviewsVocabAudioAutoplay: {
      get () {
        return this.$store.state.settings.reviewsVocabAudioAutoplay
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsVocabAudioAutoplay: value })
      }
    },
    reviewsSentenceAudioAutoplay: {
      get () {
        return this.$store.state.settings.reviewsSentenceAudioAutoplay
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsSentenceAudioAutoplay: value })
      }
    },
    reviewsAudioPlayAtStart: {
      get () {
        return this.$store.state.settings.reviewsAudioPlayAtStart
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsAudioPlayAtStart: value })
      }
    },
    reviewsAudioPlayAtEnd: {
      get () {
        return this.$store.state.settings.reviewsAudioPlayAtEnd
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsAudioPlayAtEnd: value })
      }
    },
    reviewsAudioPlayOnIncorrect: {
      get () {
        return this.$store.state.settings.reviewsAudioPlayOnIncorrect
      },
      set (value) {
        this.$store.dispatch('settings/update', { reviewsAudioPlayOnIncorrect: value })
      }
    },
    audioReviewMode: {
      get () {
        return this.$store.state.settings.reviewAudioMode
      },
      set (mode) {
        this.$store.dispatch('settings/update', { reviewAudioMode: mode })
      }
    }
  }
}
</script>
