<template>
<v-card>
  <v-card-title>Acknowledgements</v-card-title>
  <v-divider></v-divider>
  <v-card-text>
  <p>
    Our biggest inspiration is <a :href="wanikaniUrl" target="_blank">WaniKani.com</a>
    and the <a :href="wanikaniCommunityUrl" target="_blank">WaniKani Community</a>.
    WaniKani is a powerful web application for learning Japanese Kanji and vocabulary,
    created by <a :href="tofuguUrl" target="_blank">Tofugu</a>.
  </p>
  <p>
    This web app uses the <a :href="jmdictUrl" target="_blank">JMdict</a> dictionary file.
    JMdict, started by Jim Breen in 1991 and managed by the Electronic Dictionary Research and Development Group,
    is a large machine-readable multilingual Japanese dictionary.
    It is free to use with attribution (<a :href="jmdictLicenseOverviewUrl" target="_blank">
    Creative Commons Attribution-ShareAlike 3.0 Unported</a> [<a :href="jmdictLicenseLegalUrl" target="_blank">CC BY-SA 3.0</a>] )
    and the project's master database is continuously being updated.
  </p>
  <p>
    Example sentences come from the <a :href="tatoebaUrl" target="_blank">Tatoeba project</a> and are licensed under
    <a :href="tatoebaLicenseOverviewUrl" target="_blank">Creative Commons Attribution 2.0</a>
    (<a :href="tatoebaLicenseLegalUrl" target="_blank">CC BY 2.0 FR)</a>.
    Tatoeba, founded by Trang Ho in 2006, is a large ever-growing database of sentences and translations.
  </p>
  <p>
    <a :href="jlptUrl" target="_blank">JLPT</a> data comes from <a :href="tanosUrl" target="_blank">Jonathan Waller's JLPT Resources page</a>.
  </p>
  <p>
    For better user experience, we provide hyperlinks to the following external resources:
    <ul>
      <li><a :href="wanikaniUrl" target="_blank">WaniKani</a></li>
      <li><a :href="jishoUrl" target="_blank">Jisho</a> - a powerful Japanese-English dictionary</li>
      <li><a :href="gooUrl" target="_blank">Goo辞書</a> - Japanese-English dictionary</li>
      <li><a :href="tatoebaUrl" target="_blank">Tatoeba</a></li>
      <li><a :href="googleImagesUrl" target="_blank">Google Images</a></li>
    </ul>
  </p>
  <!--
  <p>
    This web application is developed with <a :href="vueUrl" target="_blank">Vue.js</a> and <a :href="vuetifyUrl" target="_blank">Vuetify</a>.
  </p>
  -->
  </v-card-text>
  <v-divider></v-divider>
  <v-col>
  <p class="text-center text-h6 text--secondary">
    ❤️ <strong>Thank you</strong> to everyone who has supported us over the years ❤️
  </p>
  </v-col>
</v-card>
</template>

<script>
export default {
  data () {
    return {
      googleImagesUrl: 'https://www.google.co.jp/',
      gooUrl: 'https://dictionary.goo.ne.jp/',
      jishoUrl: 'https://jisho.org',
      jlptUrl: 'https://www.jlpt.jp/e/',
      jmdictUrl: 'https://www.edrdg.org/wiki/index.php/JMdict-EDICT_Dictionary_Project',
      jmdictLicenseOverviewUrl: 'https://creativecommons.org/licenses/by-sa/3.0/',
      jmdictLicenseLegalUrl: 'https://creativecommons.org/licenses/by-sa/3.0/legalcode',
      tanosUrl: 'http://www.tanos.co.uk/jlpt/',
      tatoebaUrl: 'https://tatoeba.org/eng/',
      tatoebaLicenseOverviewUrl: 'https://creativecommons.org/licenses/by/2.0/fr/',
      tatoebaLicenseLegalUrl: 'https://creativecommons.org/licenses/by/2.0/fr/legalcode',
      tofuguUrl: 'https://www.tofugu.com/',
      vueUrl: 'https://vuejs.org/',
      vuetifyUrl: 'https://vuetifyjs.com/en/',
      wanikaniUrl: 'https://wanikani.com',
      wanikaniCommunityUrl: 'https://community.wanikani.com'
    }
  }
}
</script>
