import api from './api'
import axios from 'axios'
import Vue from 'vue'
import store from '@/store/store'

const chunkSize = 200

function bulkAddVocabInChunks (data) {
  if (data.length > chunkSize) {
    Vue.prototype.$db.vocabulary.bulkAdd(data.slice(0, chunkSize)).then(() => {
      bulkAddVocabInChunks(data.slice(chunkSize, data.length))
    })
  } else {
    Vue.prototype.$db.vocabulary.bulkAdd(data)
    store.commit('apiGet/pendingRequestsRemove', 'vocabulary')
    console.log('%cCreated table: Vocabulary.', 'color: green')
  }
}

function bulkAddProgressInChunks (data) {
  if (data.length > chunkSize) {
    Vue.prototype.$db.progress.bulkAdd(data.slice(0, chunkSize)).then(() => {
      bulkAddProgressInChunks(data.slice(chunkSize, data.length))
    })
  } else {
    Vue.prototype.$db.progress.bulkAdd(data)
    store.commit('apiGet/pendingRequestsRemove', 'progress')
    console.log('%cCreated table: Progress.', 'color: green')
  }
}

const state = {
  tasks: 6,
  pendingRequests: []
}

const getters = {
  syncing (state) {
    return state.pendingRequests.length > 0
  },
  requestsPending (state) {
    return state.pendingRequests
  }
}

const mutations = {
  pendingRequestsAdd (state, type) {
    state.pendingRequests.push(type)
  },
  pendingRequestsRemove (state, type) {
    state.tasks--
    state.pendingRequests.splice(state.pendingRequests.indexOf(type), 1)

    // Reloading data into store (settings, vocab, progress, etc)
    if (state.tasks === 0) store.dispatch('reload')
  }
}

const actions = {
  /* Requires admin privileges.
   * If successful, returns array of objects containing
   * user IDs and host db IDs.
   */
  users (context) {
    axios.get(
      api.apiUrl + 'users'
    ).then(response => {
      if (response.data.success) {
        // console.log(response.data.users)
        const queries = []
        for (const user of response.data.users) {
          queries.push(`SET @curIncValue = (SELECT AUTO_INCREMENT FROM INFORMATION_SCHEMA.TABLES WHERE TABLE_SCHEMA = 'torii_users${user.host_db}' AND TABLE_NAME = 'custom_${user.ID}'); SET @newIncValue = CASE WHEN @curIncValue IS NULL THEN 10000 WHEN @curIncValue < 10000 THEN @curIncValue + 10000 ELSE @curIncValue END; SET @sql = CONCAT('ALTER TABLE torii_users${user.host_db}.custom_${user.ID} AUTO_INCREMENT=', @newIncValue); PREPARE st FROM @sql; EXECUTE st; UPDATE torii_users${user.host_db}.custom_${user.ID} SET id = CASE WHEN id < 10000 THEN id + 10000 ELSE id END;`)
        }
        console.log(queries)
      } else {
        console.log(response.data.error_description)
      }
    }).catch(error => {
      console.log(error)
    })
  },
  synced (context) {
    context.commit('pendingRequestsAdd', 'sync')

    axios.get(
      api.apiUrl + 'sync'
    ).then(response => {
      if (response.data.success) {
        this.dispatch('userEmail', response.data.email, { root: true })

        Vue.prototype.$db.sync.toArray(array => {
          if (array[0].vocabulary === response.data.synced_vocab) {
            console.log('%cVOCAB table in-sync with server.', 'color: green')
          } else if (array[0].vocabulary < response.data.synced_vocab) {
            console.log('%cUpdating VOCAB table...', 'color: orange')
            context.dispatch('vocabularyDb')
          }

          if (array[0].custom === response.data.synced_custom) {
            if (context.rootState.api.networkQueue.custom.length > 0) {
              console.log('%cPushing CUSTOM VOCAB table to server [upload queue not empty]...', 'color: orange')
              context.dispatch('apiPost/updateCustomVocab', context.rootState.api.networkQueue.custom, { root: true })
            } else {
              console.log('%cCUSTOM VOCAB table in-sync with server.', 'color: green')
            }
          } else if (array[0].custom < response.data.synced_custom) {
            console.log('%cUpdating CUSTOM VOCAB table...', 'color: orange')
            context.dispatch('customVocabularyDb')
          } else {
            console.log('%cPushing CUSTOM VOCAB table to server...', 'color: orange')
            context.dispatch('apiPost/updateCustomVocab', context.rootState.api.networkQueue.custom, { root: true })
          }

          if (array[0].decks === response.data.synced_decks) {
            console.log('%cDECKS in-sync with server.', 'color: green')
            context.dispatch('api/emptyNetworkQueue', 'deckMeta', { root: true })
          } else if (!array[0].decks || array[0].decks < response.data.synced_decks) {
            console.log('%cUpdating DECKS table...', 'color: orange')
            context.dispatch('decksDb')
          } else {
            console.log('%cPushing DECKS table to server...', 'color: orange')
            context.dispatch('apiPost/updateDeckMeta', context.rootState.api.networkQueue.deckMeta, { root: true })
          }

          if (array[0].progress === response.data.synced_progress) {
            if (context.rootState.api.networkQueue.vocab.length > 0) {
              console.log('%cPushing PROGRESS table to server [upload queue not empty]...', 'color: orange')
              context.dispatch('apiPost/updateVocab', context.rootState.api.networkQueue.vocab, { root: true })
            } else {
              console.log('%cPROGRESS table in-sync with server.', 'color: green')
            }
          } else if (array[0].progress < response.data.synced_progress) {
            console.log('%cUpdating PROGRESS table...', 'color: orange')
            context.dispatch('progressDb')
          } else {
            console.log('%cPushing PROGRESS table to server...', 'color: orange')
            context.dispatch('apiPost/updateVocab', context.rootState.api.networkQueue.vocab, { root: true })
          }

          if (array[0].user === response.data.synced_user) {
            console.log('%cUSER table in-sync with server.', 'color: green')
          } else if (array[0].user < response.data.synced_user) {
            console.log('%cUpdating USER table...', 'color: orange')
            context.dispatch('userInfo')
          } else {
            console.log('%cPushing USER table to server...', 'color: orange')
            context.dispatch('apiPost/updateUserInfo', null, { root: true })
          }

          if (array[0].settings === response.data.synced_settings) {
            console.log('%cSETTINGS table in-sync with server.', 'color: green')
          } else if (array[0].settings < response.data.synced_settings) {
            console.log('%cUpdating SETTINGS table...', 'color: orange')
            context.dispatch('userSettings')
          } else {
            console.log('%cPushing SETTINGS table to server...', 'color: orange')
            context.dispatch('apiPost/updateSettings', null, { root: true })
          }
        })
      } else {
        console.error('Network error: Could not sync tables with server.')
      }
    }).catch(error => {
      console.error(error)
    }).then(() => {
      context.commit('pendingRequestsRemove', 'sync')
    })
  },
  vocabularyDb (context) {
    Vue.prototype.$db.vocabulary.clear()
    context.commit('pendingRequestsAdd', 'vocabulary')

    axios.get(
      api.apiUrl + 'vocabulary-cdn'
    ).then(response => {
      if (response.data.success) {
        // Splitting bulkAdd() into chunks of 200 rows to avoid UI blocking
        bulkAddVocabInChunks(response.data.vocabulary[0].data)
        // bulkAddVocabInChunks(response.data.vocabulary)
        Vue.prototype.$db.sync.update(0, { vocabulary: response.data.synced })

        /* Vue.prototype.$db.vocabulary.bulkAdd(response.data.vocabulary).then(lastKey => {
          console.log('%cCreated vocab db.', 'color: green')
          Vue.prototype.$db.sync.update(0, { vocabulary: response.data.synced })
        }) */
      } else {
        console.log(response.data.error_description)
      }
    }).catch(error => {
      console.log(error)
    })
  },
  customVocabularyDb (context) {
    Vue.prototype.$db.custom.clear()
    context.commit('pendingRequestsAdd', 'custom')

    axios.get(
      api.apiUrl + 'custom'
    ).then(response => {
      if (response.data.success) {
        Vue.prototype.$db.custom.bulkAdd(response.data.custom).then(lastKey => {
          console.log('%cCreated table: Custom vocabulary.', 'color: green')
          Vue.prototype.$db.sync.update(0, { custom: response.data.synced })
        })
      } else {
        console.log(response.data.error_description)
      }
    }).catch(error => {
      console.log(error)
    }).then(() => {
      context.commit('pendingRequestsRemove', 'custom')
    })

    context.dispatch('api/emptyNetworkQueue', 'custom', { root: true })
  },
  decksDb (context) {
    Vue.prototype.$db.decksMetadata.clear()
    Vue.prototype.$db.decks.clear()
    context.commit('pendingRequestsAdd', 'decks')

    axios.get(
      api.apiUrl + 'decks'
    ).then(response => {
      if (response.data.success) {
        Vue.prototype.$db.decksMetadata.bulkAdd(response.data.decks_metadata).then(lastKey => {
          console.log('%cCreated table: Decks Metadata.', 'color: green')
          // Vue.prototype.$db.sync.update(0, { decks: response.data.synced })

          Vue.prototype.$db.decks.bulkAdd(response.data.decks).then(lastKey => {
            console.log('%cCreated table: Decks.', 'color: green')
            Vue.prototype.$db.sync.update(0, { decks: response.data.synced })
          })
        })
      } else {
        console.log(response.data.error_description)
      }
    }).catch(error => {
      console.log(error)
    }).then(() => {
      context.commit('pendingRequestsRemove', 'decks')
    })

    context.dispatch('api/emptyNetworkQueue', 'deckMeta', { root: true })
  },
  progressDb (context) {
    Vue.prototype.$db.progress.clear()
    context.commit('pendingRequestsAdd', 'progress')

    axios.get(
      api.apiUrl + 'progress'
    ).then(response => {
      if (response.data.success) {
        // Splitting bulkAdd() into chunks of 200 rows to avoid UI blocking
        bulkAddProgressInChunks(response.data.progress)
        Vue.prototype.$db.sync.update(0, { progress: response.data.synced })

        /* Vue.prototype.$db.progress.bulkAdd(response.data.progress).then(lastKey => {
          console.log('%cCreated progress db.', 'color: green')
          Vue.prototype.$db.sync.update(0, { progress: response.data.synced })
        }) */
      } else {
        console.log(response.data.error_description)
      }
    }).catch(error => {
      console.log(error)
    })

    context.dispatch('api/emptyNetworkQueue', 'vocab', { root: true })
  },
  userInfo (context) {
    Vue.prototype.$db.user.clear()
    context.commit('pendingRequestsAdd', 'user')

    axios.get(
      api.apiUrl + 'user'
    ).then(response => {
      if (response.data.success) {
        response.data.user[0].id = 0
        Vue.prototype.$db.user.put(response.data.user[0]).then(lastKey => {
          console.log('%cCreated table: User.', 'color: green')
          Vue.prototype.$db.sync.update(0, { user: response.data.synced })
        })
      } else {
        console.log(response.data.error_description)
      }
    }).catch(error => {
      console.log(error)
    }).then(() => {
      context.commit('pendingRequestsRemove', 'user')
    })
  },
  userSettings (context) {
    Vue.prototype.$db.settings.clear()
    context.commit('pendingRequestsAdd', 'settings')

    axios.get(
      api.apiUrl + 'settings'
    ).then(response => {
      if (response.data.success) {
        response.data.settings[0].id = 0
        Vue.prototype.$db.settings.put(response.data.settings[0]).then(lastKey => {
          console.log('%cCreated table: Settings.', 'color: green')
          Vue.prototype.$db.sync.update(0, { settings: response.data.synced })
        })
      } else {
        console.log(response.data.error_description)
      }
    }).catch(error => {
      console.log(error)
    }).then(() => {
      context.commit('pendingRequestsRemove', 'settings')
    })
  },
  membershipPlans (context) {
    return axios.get(
      api.apiUrl + 'plans'
    ).then(response => {
      if (response.data.success) {
        return response.data.plans[0]
      } else {
        console.log(response.data.error_description)
      }
    }).catch(error => {
      console.log(error)
    })
  },
  pitchAccent (context, { vocab, reading }) {
    return axios.get(
      api.apiUrl + 'accent', {
        params: { vocab, reading }
      }
    ).then(response => {
      if (response.data.success) {
        return response.data.word
      } else {
        console.log(response.data.error_description)
      }
    }).catch(error => {
      console.log(error)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
