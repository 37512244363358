<template>
<v-container class="pa-0">
  <v-dialog persistent scrollable v-model="show">
    <v-card class="mx-auto">
      <v-card-title>Select Vocabulary Deck</v-card-title>
      <v-card-subtitle>No worries, you can change your mind any time.</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-list rounded :three-line="$vuetify.breakpoint.xs">
          <v-alert outlined text type="info">
            New lessons are sorted by JLPT level, so you'll learn N5 vocabulary first and N1 vocab last.<br/><br/>
            <u>Coming from WaniKani.com?</u><br/>
            If you select the <strong>WaniKani Supplement</strong> deck, new lessons are instead sorted by the WaniKani level of the kanji.
            That means you'll first learn words that are made up of level 1 kanji (e.g. 上り, 下り) and lastly words made up of level 60 kanji (e.g. 謹慎).<br/><br/>
            Are you looking for a way to supplement your WaniKani studies but you'd rather have your lessons sorted by JLPT level?
            Just select <strong>Core 10k</strong> (or any of the JLPT decks) and untick <strong>Include WaniKani vocabulary</strong>.
          </v-alert>
          <v-divider class="mb-3"></v-divider>
          <v-list-item-group v-model="model" subheader mandatory color="primary">
            <v-list-item v-for="item in decks" :key="item.title">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                  <span class="font-weight-light">({{ item.count }} words)</span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-item-group>
        </v-list>
        <v-list rounded>
            <v-list-item v-if="model !== 2" link :input-value="kana" @click="kana = !kana" color="primary">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Include kana-only vocabulary</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item v-if="model !== 1 && model !== 2" link :input-value="wk" @click="wk = !wk" color="primary">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Include WaniKani vocabulary</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click.stop="show = false">Close</v-btn>
        <v-btn depressed color="primary" @click.stop="save">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      model: '',
      kana: false,
      wk: false
    }
  },
  computed: {
    decks () {
      return this.$store.getters['settings/decks']
    }
  },
  methods: {
    init () {
      this.model = this.decks.findIndex(x => x.id === this.$store.state.settings.studyMode.id)
      this.kana = this.$store.state.settings.studyMode.includeKanaOnly
      this.wk = this.$store.state.settings.studyMode.includeWk
      this.show = true
    },
    save () {
      const newStudyModeId = this.decks[this.model].id
      this.$store.dispatch('settings/update', { studyMode: { id: newStudyModeId, includeKanaOnly: this.kana, includeWk: this.wk } })
      // this.$store.dispatch('vocabulary/init')
      this.show = false
      this.$snackbar.showMessage({ content: 'Your lesson queue has been updated!', color: 'success' })
    }
  }
}
</script>
