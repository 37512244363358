<template>
<v-dialog v-model="show" scrollable>
  <template v-slot:activator="{ on, attrs }">
    <v-btn v-bind="attrs" v-on="on" depressed color="primary" class="ml-2">
      Preview
    </v-btn>
  </template>
  <v-card>
    <v-card-title>Preview: Downgrade {{ selectedItems.length }} items to "{{ $store.getters.srsStageName(targetSrsStage) }}"</v-card-title>
    <v-divider></v-divider>
    <v-card-text v-html="downgradePreview"></v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        @click="show = false"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        depressed
        @click="$emit('downgrade')"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  props: {
    selectedItems: Array,
    targetSrsStage: Number
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    downgradePreview () {
      let downgradePreviewText = ''
      const itemIds = []
      this.selectedItems.forEach(vocab => itemIds.push(vocab.id))

      const stylize = (targetStage, currentStage) => {
        const targetStageName = this.$store.getters.srsStageName(targetStage)
        if (targetStage !== currentStage) return `<strong style="color:red">${targetStageName}</strong>`
        else return targetStageName
      }

      this.$store.getters['vocabulary/learnedVocab'].filter(vocab => itemIds.includes(vocab.id)).forEach(vocab => {
        const newStreakMeaning = vocab.streak_meaning >= this.targetSrsStage ? this.targetSrsStage : vocab.streak_meaning
        const newStreakReading = vocab.streak_reading >= this.targetSrsStage ? this.targetSrsStage : vocab.streak_reading
        const newStreakJpToJp = vocab.streak_jptojp >= this.targetSrsStage ? this.targetSrsStage : vocab.streak_jptojp
        downgradePreviewText += `<div><strong>${vocab.vocab}</strong><br/>
          JP-EN:  ${this.$store.getters.srsStageName(vocab.streak_meaning)} → ${stylize(newStreakMeaning, vocab.streak_meaning)}<br/>
          EN-JP:  ${this.$store.getters.srsStageName(vocab.streak_reading)} → ${stylize(newStreakReading, vocab.streak_reading)}<br/>
          JP-JP:  ${this.$store.getters.srsStageName(vocab.streak_jptojp)} → ${stylize(newStreakJpToJp, vocab.streak_jptojp)}<br/><br/></div>`
      })

      return downgradePreviewText
    }
  }
}
</script>
