<template>
<v-card justify="center" align="center" height="100%">
  <v-card-title>
    <v-row no-gutters>
      <v-col class="grow text-left text-no-wrap">
        {{ $t('studyCalendar.title') }}
      </v-col>
      <v-col class="shrink text-right text-no-wrap mr-2">
        <span class="text--secondary font-weight-light ml-2">{{ selectedDateString }}</span>
      </v-col>
    </v-row>
  </v-card-title>
  <v-card-text>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="6" lg="12">
        <v-date-picker full-width
          v-model="calendarModel"
          first-day-of-week="1"
          :show-current="today"
          :events="events"
          header-color="primary"
          no-title
        ></v-date-picker>
      </v-col>
      <v-col cols="12" sm="6" lg="12" class="text-no-wrap">
        <v-row>
          <v-col cols="12" class="shrink">
            <div class="d-flex align-end justify-space-between">
              <div class="d-flex">
                <v-sheet rounded color="lessons" width="7" class="mb-1"></v-sheet>
                <span class="text-h6 text--primary ml-2">{{ lessonsCompleted }}</span>
              </div>
              <span class="text-body-2">Lessons completed</span>
            </div>
            <v-divider class="mb-2"></v-divider>
            <div class="d-flex align-end justify-space-between">
              <div class="d-flex">
                <v-sheet rounded color="reviews" width="7" class="mb-1"></v-sheet>
                <span class="text-h6 text--primary ml-2">{{ reviewsCompleted }}</span>
              </div>
              <span class="text-body-2">Reviews completed</span>
            </div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="shrink">
            <div v-for="(entry, i) in dataaa" :key="i">
              <div class="d-flex align-end justify-space-between">
                <span><strong class="text-h6 text--primary">{{ entry.count }}</strong> days</span>
                <span class="text-body-2">{{ entry.title }}</span>
              </div>
              <v-divider class="mb-2"></v-divider>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>

<script>
export default {
  data () {
    return {
      selectedDate: '',
      clock: '',
      clockIntervalTimer: null
    }
  },
  computed: {
    today () {
      return this.$date.utc().add(this.$store.state.settings.timezone, 'hours').toISOString().substr(0, 10)
    },
    dailyStats () {
      return this.$store.state.user.dailyStats
    },
    daysStudied () {
      return this.dailyStatsData.daysStudied
    },
    currentStreak () {
      return this.dailyStatsData.currentStreak
    },
    bestStreak () {
      return this.dailyStatsData.bestStreak
    },
    dataaa () {
      return [
        { title: 'Studied', count: this.daysStudied },
        { title: 'Best Streak', count: this.bestStreak },
        { title: 'Current Streak', count: this.currentStreak }
      ]
    },
    selectedDateString () {
      if (this.clock) {
        const daysBetweenSelectedDateAndToday = this.$date(this.today).diff(this.$date(this.calendarModel), 'days')
        switch (daysBetweenSelectedDateAndToday) {
          case 0: return `Today, ${this.clock}`
          case 1: return `Yesterday, ${this.clock}`
          default: return `${this.calendarModel}, ${this.clock}`
        }
      } else {
        return ''
      }
    },
    lessonsCompleted () {
      const selDate = this.dailyStats.find(item => item.date === this.calendarModel)
      return selDate ? selDate.lessons : '0'
    },
    reviewsCompleted () {
      const selDate = this.dailyStats.find(item => item.date === this.calendarModel)
      return selDate ? selDate.reviews : '0'
    },
    dailyStatsData () {
      let daysStudied = 0
      let currentStreak = 0
      let bestStreak = 0
      let prevDay = {}
      function resetStreak () {
        if (currentStreak >= bestStreak) {
          bestStreak = currentStreak
        }
        currentStreak = 0
      }

      for (const day of this.dailyStats) {
        if (prevDay) {
          const daysBetweenLastLoginAndToday = this.$date(day.date).diff(this.$date(prevDay.date), 'days')

          // Reset streak if user hasn't logged in for 1 day or longer OR
          // user logged in yesterday but did not complete any lessons or reviews
          if (daysBetweenLastLoginAndToday > 1 ||
            (daysBetweenLastLoginAndToday === 1 && (prevDay.lessons === 0 && prevDay.reviews === 0))) {
            resetStreak()
          }
        }

        if (day.lessons > 0 || day.reviews > 0) {
          daysStudied++
          currentStreak++
        }

        prevDay = Object.assign({}, day)
      }

      if (currentStreak >= bestStreak) bestStreak = currentStreak
      return { daysStudied, currentStreak, bestStreak }
    },
    calendarModel: {
      get () {
        return this.selectedDate || this.today
      },
      set (date) {
        this.selectedDate = date
      }
    },
    events () {
      const obj = {}
      this.$store.state.user.dailyStats.forEach(e => {
        obj[e.date] = []
        if (e.lessons > 0) obj[e.date].push('lessons')
        if (e.reviews > 0) obj[e.date].push('reviews')
      })
      return obj
    }
  },
  methods: {
    renderClock () {
      if (this.$store.state.settings.time24HourFormat) {
        this.clock = this.$date.utc().add(this.$store.state.settings.timezone, 'hours').format('HH:mm:ss')
      } else {
        this.clock = this.$date.utc().add(this.$store.state.settings.timezone, 'hours').format('h:mm:ss A')
      }
    }
  },
  created () {
    clearInterval(this.clockIntervalTimer)
    this.clockIntervalTimer = setInterval(this.renderClock, 1000)
  }
}
</script>
