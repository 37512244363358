<template>
  <v-text-field
    v-bind="$props"
    v-on="$listeners"
    :value="value"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    :maxlength="maxlength"
    :prepend-inner-icon="icon"
    :class="{ animate: applyShake }"
    @click:prepend-inner="onClickPrependInner"
    @animationend="applyShake = false"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib'

export default {
  extends: VTextField,
  props: {
    active: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    indicator: {
      type: Boolean,
      default: false
    },
    maxlength: [Number, String],
    value: String
  },
  data () {
    return {
      isActive: this.active,
      applyShake: false,
      defaultInputListener: null,
      cursorPos: 0
    }
  },
  computed: {
    icon () {
      if (this.$props.prependInnerIcon !== undefined) return this.$props.prependInnerIcon
      else if (this.indicator) return this.isActive ? 'mdi-syllabary-hiragana' : 'mdi-alphabetical'
      else return ''
    }
  },
  watch: {
    active (val) {
      this.isActive = val
    },
    isActive (val) {
      if (val) this.bindWanaKana()
      else this.unbindWanaKana()
    }
  },
  methods: {
    onInput ({ target }) {
      const self = this
      if (document.getElementById(this.id).selectionStart < self.cursorPos) self.cursorPos = 0
      if (self.isActive) {
        const oldCursorPos = document.getElementById(this.id).selectionStart
        const oldInputLength = target.value.length

        const str1 = target.value.substring(0, self.cursorPos)
        const str2 = target.value.substring(self.cursorPos)
        const newInput = str1 + self.$waka.toKana(str2, { IMEMode: true })
        const newInputLength = newInput.length

        target.value = newInput

        const newCursorPos = oldCursorPos - (oldInputLength - newInputLength)
        document.getElementById(this.id).setSelectionRange(newCursorPos, newCursorPos)
      }
    },
    onClickPrependInner () {
      if (!this.$listeners['click:prepend-inner']) {
        this.isActive = !this.isActive
      }
    },
    clear () {
      this.$emit('input', '')
      this.focus()
    },
    focus () {
      this.$nextTick(() => {
        document.getElementById(this.id).focus()
      })
    },
    shake () {
      this.applyShake = true
    },
    bindWanaKana () {
      const dl = this.defaultInputListener
      if (dl) {
        dl.target.removeEventListener(dl.type, dl.listener)
        // console.log(`WanaKanaTextfield #${this.id}: Default listener removed.`)

        // this.$waka.bind(document.getElementById(this.id))
        const element = document.getElementById(this.id)
        element.setAttribute('lang', 'ja')
        element.setAttribute('autoCapitalize', 'none')
        element.setAttribute('autoCorrect', 'off')
        element.setAttribute('autoComplete', 'off')
        element.setAttribute('spellCheck', 'false')
        element.addEventListener('input', this.onInput)

        this.cursorPos = document.getElementById(this.id).selectionStart
        console.log(`WanaKanaTextfield #${this.id}: Bound.`)

        dl.target.addEventListener(dl.type, dl.listener)
        // console.log(`WanaKanaTextfield #${this.id}: Default listener added.`)
      } else {
        console.log(`WanaKanaTextfield #${this.id}: Default listener undefined!`)
      }
    },
    unbindWanaKana () {
      // this.$waka.unbind(document.getElementById(this.id))
      document.getElementById(this.id).removeEventListener('input', this.onInput)
      console.log(`WanaKanaTextfield #${this.id}: Unbound.`)
    }
  },
  beforeCreate () {
    const self = this

    if (!EventTarget.prototype.addEventListenerBase) EventTarget.prototype.addEventListenerBase = EventTarget.prototype.addEventListener
    EventTarget.prototype.addEventListener = function (type, listener) {
      if (!self.defaultInputListener && this.id === self.id && type === 'input') {
        self.defaultInputListener = { target: this, type, listener }
        console.log(`WanaKanaTextfield #${this.id}: Default listener set!`)
      }
      // _listeners.push({ target: this, type: type, listener: listener }) // Add ALL event listeners to array
      this.addEventListenerBase(type, listener)
    }

    // console.log(_listeners)
  },
  mounted () {
    if (this.isActive) this.bindWanaKana()
  },
  beforeDestroy () {
    if (EventTarget.prototype.addEventListenerBase) {
      EventTarget.prototype.addEventListener = EventTarget.prototype.addEventListenerBase
      EventTarget.prototype.addEventListenerBase = undefined
    }
  }
}
</script>

<style scoped>
@keyframes shake {
  10%, 90% {
    transform: translate3d(-3px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(6px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-12px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(12px, 0, 0);
  }
}

.animate:deep(input) {
    animation: shake 0.50s cubic-bezier(.36,.07,.19,.97) both;
}

.centered-input:deep(input) {
  text-align: center;
}
</style>
