<script>
import { Bar } from 'vue-chartjs'
import moment from 'moment'

export default {
  extends: Bar,
  data () {
    return {
      min: 0
    }
  },
  computed: {
    max () {
      return this.$store.state.settings.forecastChartSlider
    },
    gridLineColor () {
      return this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
    },
    zeroLineColor () {
      return this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.24)' : 'rgba(0, 0, 0, 0.24)'
    },
    fontColor () {
      return this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)'
    },
    forecastChartData () {
      // moment.js
      const now = moment.utc()
      const nextFullHour = now.minute() || now.second() || now.millisecond() ? now.clone().add(1, 'hours').startOf('hours') : now.startOf('hours')
      const forecastData = {
        labels: [],
        data: {
          jpToEn: [],
          enToJp: [],
          jpToJp: []
        }
      }
      for (let f = 0; f <= 72; f++) {
        // add() will change the original moment.js object because mutability; need to call clone() first
        forecastData.labels.push(nextFullHour.clone().add(f, 'hours').format())
        forecastData.data.jpToEn.push(0)
        forecastData.data.enToJp.push(0)
        forecastData.data.jpToJp.push(0)
      }

      // Generate review forecast data

      // vocabFilteredByStudyMode.forEach(e => {
      this.$store.state.vocabulary.vocab.forEach(e => {
        if (e.streak_meaning > 0 && !e.archived) {
          const timeDiff = moment.unix(e.due_meaning).utc().diff(nextFullHour, 'hours')
          if (timeDiff <= 0) {
            // count reviews available right now
            // forecastData.data[0]++
          } else if (timeDiff >= 1 && timeDiff <= 72) {
            forecastData.data.jpToEn[timeDiff]++
          }
        }

        if (e.streak_reading > 0 && !e.archived) {
          const timeDiff = moment.unix(e.due_reading).utc().diff(nextFullHour, 'hours')
          if (timeDiff <= 0) {
            // count reviews available right now
            // forecastData.data[0]++
          } else if (timeDiff >= 1 && timeDiff <= 72) {
            forecastData.data.enToJp[timeDiff]++
          }
        }

        if (e.streak_meaning > 0 && !this.$waka.isKana(e.vocab) && !e.archived) {
          const timeDiff = moment.unix(e.due_jptojp).utc().diff(nextFullHour, 'hours')
          if (timeDiff <= 0) {
            // count reviews available right now
            // forecastData.data[0]++
          } else if (timeDiff >= 1 && timeDiff <= 72) {
            forecastData.data.jpToJp[timeDiff]++
          }
        }
      })

      return forecastData
    },
    chartData () {
      return {
        labels: this.forecastChartData.labels,
        datasets: [
          // JP -> JP
          {
            label: this.$store.state.reviewModes.find(mode => mode.id === 4).titleShort,
            backgroundColor: '#C5CAE9', // indigo lighten-4
            borderColor: '#7986CB', // indigo lighten-2
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            // Data to be represented on y-axis
            data: this.$store.getters['user/vacationMode'] ? [] : this.forecastChartData.data.jpToJp
          },
          // EN -> JP
          {
            label: this.$store.state.reviewModes.find(mode => mode.id === 2).titleShort,
            backgroundColor: '#B2DFDB', // teal lighten-4
            borderColor: '#4DB6AC', // teal lighten-2
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            // Data to be represented on y-axis
            data: this.$store.getters['user/vacationMode'] ? [] : this.forecastChartData.data.enToJp
          },
          // JP -> EN
          {
            label: this.$store.state.reviewModes.find(mode => mode.id === 1).titleShort,
            backgroundColor: '#C8E6C9', // green lighten-4
            borderColor: '#81C784', // green lighten-2
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            // Data to be represented on y-axis
            data: this.$store.getters['user/vacationMode'] ? [] : this.forecastChartData.data.jpToEn
          }
        ]
      }
    },
    options () {
      return {
        scales: {
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              suggestedMax: 10,
              precision: 0,
              fontColor: this.fontColor
            },
            gridLines: {
              display: true,
              color: this.gridLineColor,
              zeroLineColor: this.zeroLineColor
            }
          }],
          xAxes: [{
            stacked: true,
            type: 'time',
            time: {
              unit: 'hour',
              tooltipFormat: 'YYYY-MM-DD hA',
              displayFormats: {
                hour: 'hA'
              }
            },
            ticks: {
              min: this.chartData.labels[this.min],
              max: this.chartData.labels[this.max],
              fontColor: this.fontColor
            },
            gridLines: {
              display: false
            },
            offset: true
          }]
        },
        legend: {
          display: true,
          reverse: true,
          labels: {
            fontColor: this.fontColor
          }
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            beforeLabel: (tooltipItem, data) => {
              return data.datasets[tooltipItem.datasetIndex].label
            },
            label: (tooltipItem, data) => {
              const sumNewReviews = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b, i) => i <= tooltipItem.index ? a + b : a, 0)
              const newReviews = `New: ${tooltipItem.yLabel}`

              let reviewsAvailable
              switch (tooltipItem.datasetIndex) {
                case 2:
                  reviewsAvailable = this.$store.getters['vocabulary/reviewsJpToEn'].length
                  break
                case 1:
                  reviewsAvailable = this.$store.getters['vocabulary/reviewsEnToJp'].length
                  break
                case 0:
                  reviewsAvailable = this.$store.getters['vocabulary/reviewsJpToJp'].length
                  break
              }

              const totalReviews = `Total: ${reviewsAvailable + sumNewReviews}`
              return [newReviews, totalReviews]
            }/* ,
            footer: (tooltipItems, data) => {
              let sumNewReviews = 0
              for (let t = 0; t < data.datasets.length; t++) {
                sumNewReviews += data.datasets[t].data.reduce((a, b, i) => i <= tooltipItems[0].index ? a + b : a, 0)
              }
              const newReviews = `Global New: ${tooltipItems[0].yLabel}`
              const totalr = this.$store.getters['vocabulary/reviewsJpToEn'].length +
                this.$store.getters['vocabulary/reviewsEnToJp'].length +
                this.$store.getters['vocabulary/reviewsJpToJp'].length
              const totalReviews = `Global Total: ${totalr + sumNewReviews}`
              return [newReviews, totalReviews]
            } */
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    },
    options () {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
