<template>
  <span v-if="!kanaOnly" class="d-inline-block text--primary">
    <span :class="furiganaStyle" class="d-block text-center" v-html="kana"></span>
    <span :class="kanjiStyle" class="d-block font-weight-bold text-center">{{ kanji }}</span>
  </span>
  <span v-else class="d-inline-block text--primary">
    <span :class="kanjiStyle" class="d-block font-weight-bold text-center" v-html="kana"></span>
  </span>
</template>

<script>
import { pitchaccent } from '@/plugins/pitchaccent.js'

export default {
  props: {
    kanji: {
      type: String,
      require: true
    },
    furigana: {
      type: String,
      require: true
    },
    pitchAccent: [String, Number],
    small: Boolean,
    large: Boolean
  },
  data () {
    return {
      styles: {
        kanji: {
          small: 'text-subtitle-1',
          large: 'text-h5 text-md-h4'
        },
        furigana: {
          small: 'text-caption',
          large: 'text-subtitle-1 text-md-h6'
        }
      }
    }
  },
  computed: {
    kanjiStyle () {
      let style
      if (this.large) style = this.styles.kanji.large
      else style = this.styles.kanji.small

      if (this.kanaOnly) style = `${this.pitchAccentClass} ${style}`

      return style
    },
    furiganaStyle () {
      let style
      if (this.large) style = this.styles.furigana.large
      else style = this.styles.furigana.small

      return `${this.pitchAccentClass} ${style}`
    },
    kanaOnly () {
      return this.kanji === this.furigana || this.$waka.isKana(this.kanji)
    },
    pitchAccentClass () {
      const theme = this.$vuetify.theme.dark ? 'dark' : 'light'
      const pClass = this.$store.state.settings.theme[theme].pitchAccentColors.enable
        ? 'pitch--colored'
        : 'pitch--plain'
      return this.pitchAccent ? pClass : ''
    },
    kana () {
      const kana = this.kanaOnly ? this.kanji : this.furigana
      if (this.pitchAccent) {
        return pitchaccent.generate(kana, this.pitchAccent)
      } else {
        return kana
      }
    }
  }
}
</script>
