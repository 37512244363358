<template>
  <v-tour name="myTour" :steps="steps" :options="myOptions">
    <template slot-scope="tour">
      <transition name="fade">
        <v-step
          v-if="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :step="tour.steps[tour.currentStep]"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
          :highlight="tour.highlight"
        >
          <template v-if="tour.currentStep === 3">
            <div slot="content" class="mb-3">
              Quick Settings <v-icon color="white">mdi-cog</v-icon>
            </div>
          </template>
          <template v-if="tour.isLast">
            <div slot="content" class="mb-3">
              <ul class="list mb-2">
                <li><v-icon small color="white">mdi-library</v-icon>&nbsp;Study on your terms and at your own pace,</li>
                <li><v-icon small color="white">mdi-database-plus</v-icon>&nbsp;add your own vocabulary words,</li>
                <li><v-icon small color="white">mdi-book-open-page-variant</v-icon>&nbsp;add mnemonics and notes,</li>
                <li><v-icon small color="white">mdi-chart-bar</v-icon>&nbsp;track your progress,</li>
                <li><v-icon small color="white">mdi-palette</v-icon>&nbsp;customize your UI...</li>
              </ul>
              There's so much you can do with Torii. If you need help, open up
              <code><v-icon small color="white">mdi-frequently-asked-questions</v-icon>&nbsp;Knowledge Base</code>
              and submit a support ticket.
            </div>
          </template>
          <!--<template v-if="tour.currentStep === 1">-->
          <div slot="actions">
            <v-btn v-if="!tour.isLast" small outlined color="primary" @click="tour.skip" class="mx-1">
              {{ tour.labels.buttonSkip }}
            </v-btn>
            <v-btn v-if="!tour.isFirst" small depressed color="primary" @click="tour.previousStep" class="mx-1">
              {{ tour.labels.buttonPrevious }}
            </v-btn>
            <v-btn v-if="tour.isLast" small depressed color="primary" @click="tour.stop" class="mx-1">
              {{ tour.labels.buttonStop }}
            </v-btn>
            <v-btn v-else small depressed color="primary" @click="tour.nextStep" class="mx-1">
              {{ tour.labels.buttonNext }}
            </v-btn>
          </div>
          <!--</template>-->
        </v-step>
      </transition>
    </template>
  </v-tour>
</template>

<script>
export default {
  data () {
    return {
      myOptions: {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish'
        }
      },
      steps: [
        {
          target: '#btnLessons',
          header: {
            title: '1. <strong>Learn</strong> new words'
          },
          content: `Your lessons reset daily. Complete as many lessons as you like - 5, 10, 20...
            Pick a number that seems reasonable to you and stick to it on a daily basis.<br/>
            <strong>Be consistent!</strong>`,
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#btnReviews',
          header: {
            title: '2. <strong>Review</strong> what you\'ve learned'
          },
          content: 'Our SRS , To make full use of the SRS, you should check your reviews at least once or twice each day.',
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#cardExtraStudy',
          header: {
            title: '3. Take that extra step'
          },
          content: 'Extra Study lets you practice your most recent lessons and mistakes. It\'s completely optional.',
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#btnQuickSettings',
          header: {
            title: '4. <strong>Customize</strong>'
          }
        },
        {
          target: '#navDrawer',
          header: {
            title: 'Explore Torii\'s full potential'
          },
          params: {
            placement: 'right',
            highlight: false
          }
        }
      ]
    }
  },
  methods: {
    start () {
      this.$tours.myTour.start()
    }
  }
}
</script>

<style>
.list {
  list-style-type: none;
  display: table;
  margin: 0 auto;
  text-align: left;
}
</style>
