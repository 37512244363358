<template>
<div>
<v-menu open-on-hover offset-y>
  <template v-slot:activator="{ on, attrs, value }">
    <v-btn
      v-bind="attrs"
      v-on="on"
      light
      depressed
      color="primary"
    >
      Bulk Actions
      <v-icon v-if="value" right>mdi-chevron-up</v-icon>
      <v-icon v-else right>mdi-chevron-down</v-icon>
    </v-btn>
  </template>
  <v-list>
    <v-list-item
      v-for="(item, index) in actions"
      :key="index"
      @click.stop="initAction(item)"
    >
      <v-list-item-title>{{ item }}</v-list-item-title>
    </v-list-item>
  </v-list>
</v-menu>
<v-dialog v-model="showBulkActionsDialog">
  <v-card>
    <v-card-title>{{ bulkActionsDialogTitle }}</v-card-title>
    <v-card-text>
      <v-select
        v-if="curAction === actionsList.downgrade"
        outlined
        dense
        label="Target SRS Stage"
        :items="srsStages"
        v-model="srsStage"
        item-value="id"
        :item-text="itemText"
      ></v-select>
      <span v-html="bulkActionsDialogText"></span>
      <v-alert v-if="bulkActionsWarningText" type="warning" text outlined>{{ bulkActionsWarningText }}</v-alert>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        @click="showBulkActionsDialog = false"
      >
        Cancel
      </v-btn>
      <DowngradeDialog v-if="curAction === actionsList.downgrade" :selectedItems="selectedItems" :targetSrsStage="srsStage" @downgrade="processAction" />
      <v-btn v-else
        color="primary"
        depressed
        @click="processAction"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</div>
</template>

<script>
import DowngradeDialog from './DowngradeDialog.vue'

export default {
  props: {
    listSelected: String,
    selectedItems: Array
  },
  components: {
    DowngradeDialog
  },
  data () {
    return {
      curAction: '',
      bulkActionsDialogTitle: '',
      bulkActionsDialogText: '',
      bulkActionsWarningText: '',
      showBulkActionsDialog: false,
      srsStage: 0,
      actionsList: {
        archive: 'Archive',
        restore: 'Unarchive',
        prioritize: 'Move to front of lesson queue',
        unprioritize: 'Remove from front of lesson queue',
        delete: 'Delete',
        downgrade: 'Downgrade'
      }
    }
  },
  computed: {
    actions () {
      switch (this.listSelected) {
        case 'learned': return [this.actionsList.downgrade, this.actionsList.archive]
        case 'custom': return [this.actionsList.delete, this.actionsList.prioritize, this.actionsList.archive]
        case 'archived': return [this.actionsList.restore]
        case 'priority': return [this.actionsList.unprioritize, this.actionsList.archive]
        default: return [this.actionsList.prioritize, this.actionsList.archive]
      }
    },
    srsStages () {
      return this.$store.state.srsStages.slice(0, this.$store.state.srsStages.length - 1)
    }
  },
  methods: {
    initAction (action) {
      if (!this.selectedItems.length) return
      this.curAction = action

      if (this.curAction === this.actionsList.archive) {
        this.bulkActionsDialogTitle = `Archive ${this.selectedItems.length} items?`
        this.bulkActionsDialogText = 'You can unarchive these items at any time.'
        this.bulkActionsWarningText = ''
        this.showBulkActionsDialog = true
      } else if (this.curAction === this.actionsList.restore) {
        this.bulkActionsDialogTitle = `Unarchive ${this.selectedItems.length} items?`
        this.bulkActionsDialogText = ''
        this.bulkActionsWarningText = ''
        this.showBulkActionsDialog = true
      } else if (this.curAction === this.actionsList.delete) {
        this.bulkActionsDialogTitle = `Delete ${this.selectedItems.length} custom items?`
        this.bulkActionsDialogText = ''
        this.bulkActionsWarningText = 'This action cannot be undone.'
        this.showBulkActionsDialog = true
      } else if (this.curAction === this.actionsList.prioritize) {
        this.bulkActionsDialogTitle = `Move ${this.selectedItems.length} items to the front of your lesson queue?`
        this.bulkActionsDialogText =
          `<p>This action tags the selected words as "Priority" items.
          These items will appear first in your lessons, in random order.</p>`
        this.bulkActionsWarningText = ''
        this.showBulkActionsDialog = true
      } else if (this.curAction === this.actionsList.unprioritize) {
        this.bulkActionsDialogTitle = `Remove ${this.selectedItems.length} items from the front of your lesson queue?`
        this.bulkActionsDialogText = ''
        this.bulkActionsWarningText = ''
        this.showBulkActionsDialog = true
      } else if (this.curAction === this.actionsList.downgrade) {
        this.bulkActionsDialogTitle = `Downgrade ${this.selectedItems.length} items?`
        this.bulkActionsDialogText =
          `<p>Affects the item as a whole.</p>
          <p>Items can only be downgraded. The opposite will not work.</p>`
        this.bulkActionsWarningText = 'This action cannot be undone. An "Upgrade" function does not exist. The SRS stage of an item can be increased only by completing your reviews.'
        this.showBulkActionsDialog = true
      }
    },
    processAction () {
      const itemIds = []
      this.selectedItems.forEach(vocab => itemIds.push(vocab.id))
      // const itemIdsWithoutCustomVocab = itemIds.filter(item => item < this.$store.state.vocabulary.customVocabStartIndex)

      if (this.curAction === this.actionsList.archive) {
        // this.$store.dispatch('vocabulary/archive', itemIdsWithoutCustomVocab)
        this.$store.dispatch('vocabulary/archive', itemIds)
      } else if (this.curAction === this.actionsList.restore) {
        // this.$store.dispatch('vocabulary/restore', itemIdsWithoutCustomVocab)
        this.$store.dispatch('vocabulary/restore', itemIds)
      } else if (this.curAction === this.actionsList.delete) {
        this.$store.dispatch('vocabulary/deleteCustom', itemIds)
      } else if (this.curAction === this.actionsList.prioritize) {
        // this.$store.dispatch('vocabulary/prioritize', { vocabIds: itemIdsWithoutCustomVocab, prioritized: true })
        this.$store.dispatch('vocabulary/prioritize', { vocabIds: itemIds, prioritized: true })
      } else if (this.curAction === this.actionsList.unprioritize) {
        // this.$store.dispatch('vocabulary/prioritize', { vocabIds: itemIdsWithoutCustomVocab, prioritized: false })
        this.$store.dispatch('vocabulary/prioritize', { vocabIds: itemIds, prioritized: false })
      } else if (this.curAction === this.actionsList.downgrade) {
        this.$store.dispatch('vocabulary/downgrade', { vocabIds: itemIds, targetSrsStage: this.srsStage })
      }

      this.$emit('actionProcessed')
      this.showBulkActionsDialog = false
    },
    itemText (item) {
      return `${this.$store.getters.srsStageName(item.id)} (next review: ${item.nextReviewText})`
    }
  }
}
</script>
