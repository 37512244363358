<template>
<v-container>
  <v-row>
    <v-col v-if="isLoggedIn" cols="12">
      <v-alert prominent type="warning" text outlined border="left">
        <v-row dense align="center">
          <v-col cols="12">
            <span class="text-h6 font-weight-regular">
              <strong>See what's new</strong> in Torii SRS.
            </span>
          </v-col>
          <v-col>
            <v-btn to="/updates" depressed color="primary" class="mx-1">
              Release Notes
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert prominent type="info" text outlined border="left">
        <v-row dense align="center">
          <v-col cols="12">
            <span class="text-h6 font-weight-regular">
              You can find our <strong>issue and feature request tracker</strong> on GitHub!
            </span>
          </v-col>
          <v-col>
            <v-btn depressed color="secondary" :href="$store.state.api.repoUrl" target="_blank" class="mx-1">
              <v-icon left>mdi-github</v-icon>
              View on GitHub
              <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col v-if="isLoggedIn" cols="12" sm="6">
      <v-card class="fill-height">
        <v-card-title class="justify-center pb-0 mb-0">
          <v-icon x-large color="brown" class="mx-1">mdi-spider-thread</v-icon>
          <v-icon x-large color="orange" class="mx-1">mdi-lightbulb-on</v-icon>
        </v-card-title>
        <v-card-title class="justify-center pt-0 mt-0">
          Have a problem? Feature request?
        </v-card-title>
        <v-card-text class="text-center">
          Came across a bug or having a technical issue? Have a great idea you'd like us to know about?
        </v-card-text>
        <v-card-actions class="justify-center">
          <ContactForm />
          <v-btn depressed color="secondary" :href="issueTrackerUrl" target="_blank" class="mx-1">
            <v-icon left>mdi-github</v-icon>
            Open Issue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col v-if="isLoggedIn" cols="12" sm="6">
      <v-card class="fill-height">
        <v-card-title class="justify-center pb-0 mb-0">
          <v-icon x-large color="green" class="mx-1">mdi-message-reply-text-outline</v-icon>
          <v-icon x-large color="deep-orange" class="mx-1">mdi-message-question-outline</v-icon>
        </v-card-title>
        <v-card-title class="justify-center pt-0 mt-0">Have a question? Feedback?</v-card-title>
        <v-card-text class="text-center">Looking for help? Want to give us your feedback or just want to say hi?</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn depressed color="primary" :href="mailtoLink" class="mx-1">
            <v-icon left>mdi-email-outline</v-icon>
            Email us
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title class="justify-center">Questions & Answers</v-card-title>
        <v-divider></v-divider>
        <div v-for="(section, index) in sections" :key="index">
        <v-card-title>{{ section.title }}</v-card-title>
        <v-card-text>
          <v-expansion-panels accordion focusable>
            <v-expansion-panel v-for="(item, i) in section.items" :key="i">
              <v-expansion-panel-header>
                <strong>{{ item.q }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <component :is="test(item.a)" />
                <v-progress-circular v-if="item.q == laif()" indeterminate color="primary" class="mt-3"></v-progress-circular>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        </div>
      </v-card>
    </v-col>
    <v-col v-if="isLoggedIn" cols="12">
      <AboutComponent />
    </v-col>
    <v-col cols="12">
      <v-card color="primary" class="text-center">
        <TermsOfUse />
        <PrivacyPolicy />
        <CookiesPolicy />
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import AboutComponent from './AboutComponent.vue'
import ContactForm from './ContactForm.vue'
import TermsOfUse from '@/components/legal/TermsOfUse.vue'
import PrivacyPolicy from '@/components/legal/PrivacyPolicy.vue'
import CookiesPolicy from '@/components/legal/CookiesPolicy.vue'

export default {
  components: {
    AboutComponent,
    ContactForm,
    TermsOfUse,
    PrivacyPolicy,
    CookiesPolicy
  },
  data () {
    return {
      sections: [
        {
          title: 'Getting Started',
          items: [
            {
              q: 'What is Torii SRS?',
              a: `<p><strong>Torii SRS</strong> is a free Japanese vocabulary learning web app that uses <a href="https://en.wikipedia.org/wiki/Spaced_repetition" target="_blank">spaced repetition</a> to make learning vocabulary simple and effective.</p>
                <p>Torii SRS was first released in June 2018 for Desktop and Android devices.
                The web app beta was launched in June 2021.</p>`
            },
            {
              q: 'How does studying with Torii work?',
              a: `<p>It's quite simple:
                <ol><li>Do your daily lessons</li>
                <li>Do your reviews</li>
                <li>Repeat each and every day</li></ol></p>
                <p>By default, you are required to type in the answer to an item when reviewing.</p>
                <p>If you prefer the more classical style of reviewing your flashcards (à la Anki), feel free to enable <strong>"Quick Review"</strong> in settings.</p>`
            },
            {
              q: 'Do I need to know Japanese to use Torii?',
              a: `We <strong>highly</strong> recommend you learn Hiragana & Katakana first, as Torii does not use Romaji.
                In fact, most Japanese learning resources require you to know Hiragana. It's essentially the first step to learn Japanese.
                If you do it the right way, you should be able to learn everything in a couple of days. 
                <br/><strong><a href="https://www.tofugu.com/japanese/learn-hiragana/" target="_blank">Learn Hiragana: The Ultimate Guide (by Tofugu)</a></strong>`
            },
            {
              q: 'Do I have to install a Japanese keyboard (IME)?',
              a: 'No. Torii automatically converts Romaji input to Kana wherever necessary.'
            },
            {
              q: 'Is there audio?',
              a: 'Yes, all words and sentences have Japanese and English audio.'
            },
            {
              q: 'Can I use Torii without internet connection?',
              a: `<strong>YES</strong>, Torii is an offline-first progressive web app!
                After logging in and pulling your user data from the server once, you can open up the app any time and study without worrying about connectivity.
                There are a few things that do require internet connection, though.`
            },
            {
              q: 'What can I NOT do when offline?',
              a: `<ul><li>Sync your data with the server</li>
                <li>Playback audio recordings</li>
                <li>Add vocabulary from a dictionary</li>
                <li>Use contact forms</li>
                <li>Use certain account services (change email/password, sign out, purchase Pro)</li></ul>`
            },
            {
              q: 'How can I support the developers?',
              a: `For starters, you can send us your honest feedback and ideas on how to improve our web app. What additional features would you like to see?
                We value user feedback a lot. Seriously. Most of our features were built upon user feedback.<br/><br/>
                If you enjoy learning with Torii and you'd like to help out even more, please consider upgrading to Torii Pro.
                It is our only source of income and your contribution helps to cover our monthly hosting costs.<br/>
                <strong><router-link to="prime">Torii Pro features & pricing</router-link></strong>`
            }
          ]
        },
        {
          title: 'Lessons',
          items: [
            {
              q: 'Why is there a daily limit for lessons?',
              a: `The limit is meant to protect you from burn out. Completing dozens of lessons each and every day will result in hundreds of daily reviews quickly.<br/><br/>
                <i>"Language learning is a marathon, not a sprint."</i> ~ Buddha (probably)`
            },
            {
              q: 'When does the daily lesson limit reset?',
              a: 'Every night at midnight. Torii\'s default timezone is UTC+0. You can change the timezone in settings.'
            },
            {
              q: 'Can I hide words I already know?',
              a: `Sure. In both lesson and review sessions there's an "Archive item" button located in the top toolbar.
                Archiving removes the item from your lessons and review queue.
                You should archive an item only if you're 100% certain that you won't ever forget it.`
            },
            {
              q: 'Does archiving vocab items count against my daily limit?',
              a: 'Nope.'
            },
            {
              q: 'Can I import my own vocabulary decks?',
              a: `No, but you can <router-link to="add">add your own words & phrases</router-link>
                and import vocabulary from a dictionary easily.`
            },
            {
              q: 'Can I export my progress?',
              a: 'Exporting your progress is currently not supported.'
            }
          ]
        },
        {
          title: 'Reviews',
          items: [
            {
              q: 'How can I type small vowels?',
              a: `Prefixing x will generate the small variant character.
                <ul><li>xa → ぁ</li><li>xtsu → っ</li><li>xyu → ゅ</li><li>...</li></ul>`
            },
            {
              q: 'How can I type in Katakana?',
              a: `Torii automatically converts Romaji input to Kana wherever necessary.
              Lowercase letters will be converted to Hiragana und <strong>uppercase letters</strong> to Katakana.`
            },
            {
              q: 'Can I take a break from my reviews?',
              a: `The spaced repetition system works best if you work through your reviews every day.
                If you're going to be away from Torii for several days or longer, you can freeze the SRS
                by activating Vacation Mode in <router-link to="settings">Settings</router-link>.`
            }
          ]
        },
        {
          title: 'Spaced Repetition System',
          items: [
            {
              q: 'What is "spaced repetition"?',
              a: `<p><strong>Spaced repetition</strong> is an evidence-based learning technique that is usually performed with flashcards.
                Newly introduced and more difficult flashcards are shown more frequently, while older and less difficult flashcards are shown less frequently
                in order to exploit the psychological spacing effect. The use of spaced repetition has been proven to increase rate of learning.</p>`
            },
            {
              q: 'How does it work?',
              a: `<p>There are ${this.$store.state.srsStages.length - 1} SRS stages, plus the LESSON stage (=stage zero): ${this.stages()}</p>
                <p>When you complete a lesson, the word is moved to SRS stage #1 and added to your review queue.</p>
                <p>When you review the item later on and you get the answer right, the item moves up 1 stage.</p>
                <p>If your answer is wrong, the item moves down
                <ul><li>1 stage IF current stage is smaller or equal 4</li><li>2 stages IF current stage is bigger or equal 5</li></ul></p>`
            },
            {
              q: 'What are the actual SRS timings?',
              a: '<img src="img/srs-stages.png" height="auto" width="100%" />'
            },
            {
              q: 'How long does it take for an item to get to the max SRS stage?',
              a: 'It takes <i>at least</i> 174 days and 12 hours, assuming you do your reviews as soon as they become available and that you get every review right.'
            }
          ]
        },
        {
          title: 'Account & Membership',
          items: [
            {
              q: 'Should I get Torii Pro?',
              a: `If you want to get more out of your experience and at the same time support those who make Torii SRS, it might be worth considering.<br/>
              <router-link to="prime">Torii Pro features & pricing</router-link>`
            },
            {
              q: 'Which personal data do you collect?',
              a: `We collect only your email address. We need your email to be able to send you a link to reset your password in case you forget it.
                We promise to not share your data with anyone.
                Please refer to our Privacy Policy for more details.`
            },
            {
              q: 'How can I delete my account?',
              a: `If you really want to leave us, for whatever reason, you can delete your account in the 
                <router-link to="settings">Settings (Danger Zone)</router-link>.<br />Once an account has been deleted, 
                there is no way to restore it. The data is gone forever.`
            }
          ]
        },
        {
          title: 'Technical',
          items: [
            {
              q: 'Which web browsers do you support?',
              a: `We support the latest versions of <strong>modern</strong> browsers, such as Chrome, Firefox, Safari and Edge.<br/>
                <strong>Internet Explorer is not supported.</strong>`
            },
            {
              q: 'Why won\'t the site work in Private Browsing / Incognito Mode?',
              a: `Our web app uses client-side storage technologies to function even when you're offline.
                Most browsers, however, restrict or disable client-side storage while private browsing is enabled.`
            },
            {
              q: `${this.laif()}`,
              a: ''
            }
          ]
        }/* TODO: add after retiring java apps ,
        {
          title: 'Mobile & Desktop Apps',
          items: [
            {
              q: 'Are there Android and iOS apps?',
              a: `No, there are no official mobile apps. You can, however, add Torii to your home screen.
                How you can do that exactly depends on your browser.`
            },
            {
              q: 'Where can I download the old apps?',
              a: 'The old apps can no longer be downloaded or used.'
            }
          ]
        } */
      ]
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.loggedIn
    },
    issueTrackerUrl () {
      return `${this.$store.state.api.repoUrl}/issues`
    },
    mailtoLink () {
      return `mailto:${this.$store.state.api.contactEmail}`
    }
  },
  methods: {
    laif () {
      return 'What\'s the meaning of life?'
    },
    stages () {
      let str = ''
      for (let i = 1; i < this.$store.state.srsStages.length; i++) {
        str += `<li>${this.$store.getters.srsStageName(i)}</li>`
      }
      return `<ol>${str}</ol>`
    },
    test (str) {
      return {
        template: `<div>${str}</div>`
      }
    }
  }
}
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
</style>
