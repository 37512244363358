<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <span class="text-h5">
          Standard Decks
          <v-btn depressed color="primary" class="rounded-edges" @click="$refs.addDeckDialog.show = true">
            <v-icon size="32" left color="white">mdi-plus</v-icon>
            Add
          </v-btn>
        </span>
      </v-col>
      <v-col v-for="deck in decks" :key="deck.id" cols="6" md="4" lg="3">
        <DeckCard
          :deck="deck"
          :elevation="isEditing(deck.id) ? 16 : 0"
          :disabled="editing && !isEditing(deck.id)"
          @isEditing="(v) => editing = v ? deck.id : null"
          @removed="editing = null"
        ></DeckCard>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6">
      <v-col cols="12">
        <span class="text-h5">
          System-generated Decks
        </span>
      </v-col>
      <v-col cols="12">
        <span class="text-subtitle-1 text--secondary">
          These decks change automatically as you progress.
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="deck in systemDecks" :key="deck.id" cols="6" md="4" lg="3">
        <DeckCard
          :deck="deck"
          :elevation="isEditing(deck.id) ? 16 : 0"
          :disabled="editing && !isEditing(deck.id)"
          :readonly="true"
        ></DeckCard>
      </v-col>
    </v-row>
    <AddDeckDialog ref="addDeckDialog" />
  </v-container>
</template>

<script>
import DeckCard from './DeckCard.vue'
import AddDeckDialog from './AddDeckDialog.vue'

export default {
  components: {
    DeckCard,
    AddDeckDialog
  },
  data () {
    return {
      editing: null
    }
  },
  computed: {
    decks () {
      return [...this.$store.getters['decks/decks']].sort((a, b) => b.id - a.id)
    },
    systemDecks () {
      return [...this.$store.getters['decks/systemGeneratedDecks']].sort((a, b) => b.id - a.id)
    }
  },
  methods: {
    isEditing (deckId) {
      return this.editing === deckId
    }
  }
}
</script>
