<template>
  <v-dialog v-model="show" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        depressed
        v-bind="attrs"
        v-on="on"
        :disabled="!$store.getters.online"
        class="mx-1"
      >
        <v-icon left>mdi-file-document-edit-outline</v-icon>
        Write us
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Contact us</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="contactForm" v-model="valid" class="my-2">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                outlined
                hide-details=""
                label="Contact email (optional)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="topic"
                outlined
                hide-details
                :items="['Bug Report', 'Technical Issue', 'Feature Request']"
                label="Subject (required)"
                :rules="topicRules"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="text"
                outlined
                no-resize
                clearable
                rows="5"
                counter="1500"
                maxlength="1500"
                label="Your message (required)"
                :rules="messageRules"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="files"
                chips multiple show-size counter
                accept="image/*,.pdf,.txt,.doc,.docx,.odt,.rtf"
                label="Attach files"
                :rules="fileRules"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-form>
        <v-alert v-if="submitResponse" outlined text :type="submitSuccess ? 'success' : 'error'" class="multi-line">{{ submitResponse }}</v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          @click="close"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="sending"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { logger } from '@/plugins/logger'

export default {
  data () {
    return {
      show: false,
      valid: false,
      email: '',
      topic: '',
      topicRules: [
        v => !!v || ''
      ],
      text: '',
      messageRules: [
        v => (v && v.length >= 10) || 'Message must be at least 10 characters.'
      ],
      files: [],
      fileRules: [
        v => !v.length || v.reduce((size, file) => size + file.size, 0) < 5000000 || 'Total file size should be less than 5 MB!'
      ],
      sending: false,
      submitSuccess: false,
      submitResponse: ''
    }
  },
  methods: {
    submit () {
      this.validate()
      this.submitResponse = ''

      if (this.valid) {
        const logFile = logger.generateLogFile()

        this.sending = true
        this.$store.dispatch('apiPost/inquiry', {
          contactEmail: this.email,
          subject: this.topic,
          message: this.text,
          files: [...this.files, ...[logFile]]
        }).then(response => {
          if (response.data.success) {
            this.submitSuccess = true
            this.submitResponse = 'Thank you, your inquiry has been received!'
            this.reset()
          } else {
            this.submitSuccess = false
            this.submitResponse = response.data.error_description
          }

          this.sending = false
        })
      }
    },
    validate () {
      this.$refs.contactForm.validate()
    },
    reset () {
      this.$refs.contactForm.reset()
    },
    close () {
      this.$refs.contactForm.resetValidation()
      this.files = []
      this.show = false
    }
  }
}
</script>
