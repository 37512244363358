<template>
  <v-container>
    <v-card>
      <v-tabs v-model="selectedTab" fixed-tabs>
        <v-tab>Account</v-tab>
        <v-tab>Lessons</v-tab>
        <v-tab>Reviews</v-tab>
      </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item>
            <AccountTab />
          </v-tab-item>
          <v-tab-item>
            <LessonsTab />
          </v-tab-item>
          <v-tab-item>
            <ReviewsTab />
          </v-tab-item>
        </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import AccountTab from './AccountTab.vue'
import LessonsTab from './LessonsTab.vue'
import ReviewsTab from './ReviewsTab.vue'

export default {
  components: {
    AccountTab,
    LessonsTab,
    ReviewsTab
  },
  data () {
    return {
      selectedTab: 0
    }
  }
}
</script>
