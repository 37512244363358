import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import AcornIcon from '@/components/icons/AcornIcon.vue'
import SeedlingIcon from '@/components/icons/SeedlingIcon.vue'
import LeafIcon from '@/components/icons/LeafIcon.vue'
import TreeIcon from '@/components/icons/TreeIcon.vue'
import ToriiGateIcon from '@/components/icons/ToriiGateIcon.vue'
import StripeIcon from '@/components/icons/payment/StripeIcon.vue'
import ApplePayIcon from '@/components/icons/payment/ApplePayIcon.vue'
import GooglePayIcon from '@/components/icons/payment/GooglePayIcon.vue'
import VisaIcon from '@/components/icons/payment/VisaIcon.vue'
import MasterCardIcon from '@/components/icons/payment/MasterCardIcon.vue'
import AmexIcon from '@/components/icons/payment/AmexIcon.vue'
import DiscoverIcon from '@/components/icons/payment/DiscoverIcon.vue'
import JCBIcon from '@/components/icons/payment/JCBIcon.vue'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { far } from '@fortawesome/free-regular-svg-icons'

// Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
// library.add(far) // Include needed icons

Vue.use(Vuetify)

export default new Vuetify({
  // Vuetify defaults:
  // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/presets/default/index.ts
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1246
    },
    scrollBarWidth: 16
  },
  icons: {
    iconfont: 'mdi',
    values: {
      acorn: {
        component: AcornIcon
      },
      seedling: {
        component: SeedlingIcon
      },
      leaf: {
        component: LeafIcon
      },
      tree: {
        component: TreeIcon
      },
      toriigate: {
        component: ToriiGateIcon
      },
      stripe: {
        component: StripeIcon
      },
      applepay: {
        component: ApplePayIcon
      },
      googlepay: {
        component: GooglePayIcon
      },
      visa: {
        component: VisaIcon
      },
      mastercard: {
        component: MasterCardIcon
      },
      amex: {
        component: AmexIcon
      },
      discover: {
        component: DiscoverIcon
      },
      jcb: {
        component: JCBIcon
      }
    }
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
      variations: false
    },
    themes: {
      light: {
        // Default (static) vars
        primaryDefault: colors.blue.darken2, // Vuetify default
        secondaryDefault: colors.grey.darken3, // Vuetify default
        // fontDefault: '#000000', // Vuetify default
        accentDefault: colors.blue.accent1, // Vuetify default
        errorDefault: colors.red.accent2, // Vuetify default
        infoDefault: colors.blue.base, // Vuetify default
        successDefault: colors.green.base, // Vuetify default
        warningDefault: colors.orange.darken1, // Vuetify default
        backgroundDefault: colors.grey.lighten4,
        backgroundSecondaryDefault: colors.grey.lighten5,
        lessonsDefault: colors.blue.base,
        reviewsDefault: colors.purple.accent4,
        // lessons: '#ee009e', // original
        // reviews: '#9e00ee', // original
        mizukiDefault: colors.pink.accent2,
        takumiDefault: colors.blue.accent3,
        amyDefault: '#012169', // Royal Blue
        matthewDefault: '#B22234', // Old Glory Red
        goldDefault: '#D4AF37',
        dummyColorDefault: '#EEEEEE',
        heibanDefault: '#005CE6',
        atamadakaDefault: '#E60000',
        nakadakaDefault: '#E68A00',
        odakaDefault: '#00802B',
        borderDefault: colors.grey.darken4,
        // Dynamic vars
        get primary () { return this.primaryDefault },
        get secondary () { return this.secondaryDefault },
        // get font () { return this.fontDefault },
        get accent () { return this.accentDefault },
        get error () { return this.errorDefault },
        get info () { return this.infoDefault },
        get success () { return this.successDefault },
        get warning () { return this.warningDefault },
        get background () { return this.backgroundDefault },
        get backgroundSecondary () { return this.backgroundSecondaryDefault },
        get lessons () { return this.lessonsDefault },
        get reviews () { return this.reviewsDefault },
        get mizuki () { return this.mizukiDefault },
        get takumi () { return this.takumiDefault },
        get amy () { return this.amyDefault },
        get matthew () { return this.matthewDefault },
        get gold () { return this.goldDefault },
        get dummyColor () { return this.dummyColorDefault },
        get heiban () { return this.heibanDefault },
        get atamadaka () { return this.atamadakaDefault },
        get nakadaka () { return this.nakadakaDefault },
        get odaka () { return this.odakaDefault },
        get border () { return this.borderDefault }
      },
      dark: {
        // Default (static) vars
        primaryDefault: colors.blue.base, // Vuetify default
        secondaryDefault: colors.grey.darken3, // Vuetify default
        fontDefault: '#FFFFFF', // Vuetify default
        accentDefault: colors.pink.accent2, // Vuetify default
        errorDefault: colors.red.accent2, // Vuetify default
        infoDefault: colors.blue.base, // Vuetify default
        successDefault: colors.green.base, // Vuetify default
        warningDefault: colors.orange.darken1, // Vuetify default
        backgroundDefault: '#121212', // Vuetify default
        lessonsDefault: colors.blue.darken2,
        reviewsDefault: colors.purple.darken2,
        mizukiDefault: colors.pink.accent2,
        takumiDefault: colors.blue.accent2,
        amyDefault: '#012169', // Royal Blue
        matthewDefault: '#B22234', // Old Glory Red
        goldDefault: '#D4AF37',
        dummyColorDefault: '#424242',
        heibanDefault: '#005CE6',
        atamadakaDefault: '#E60000',
        nakadakaDefault: '#E68A00',
        odakaDefault: '#00802B',
        borderDefault: colors.shades.white,
        // Dynamic vars
        get primary () { return this.primaryDefault },
        get secondary () { return this.secondaryDefault },
        get font () { return this.fontDefault },
        get accent () { return this.accentDefault },
        get error () { return this.errorDefault },
        get info () { return this.infoDefault },
        get success () { return this.successDefault },
        get warning () { return this.warningDefault },
        get background () { return this.backgroundDefault },
        // get backgroundSecondary () { return this.backgroundSecondaryDefault },
        get lessons () { return this.lessonsDefault },
        get reviews () { return this.reviewsDefault },
        get mizuki () { return this.mizukiDefault },
        get takumi () { return this.takumiDefault },
        get amy () { return this.amyDefault },
        get matthew () { return this.matthewDefault },
        get gold () { return this.goldDefault },
        get dummyColor () { return this.dummyColorDefault },
        get heiban () { return this.heibanDefault },
        get atamadaka () { return this.atamadakaDefault },
        get nakadaka () { return this.nakadakaDefault },
        get odaka () { return this.odakaDefault },
        get border () { return this.borderDefault }
      }
    }
  }
})
