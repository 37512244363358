import axios from 'axios'
import api from './api'
import { audio } from '@/plugins/audio'

const state = {
}

const mutations = {
}

const actions = {
  session () {
    return axios.get(api.apiUrl + 'session')
  },
  signup ({ commit }, { email, password }) {
    return axios.post(api.apiUrl + 'signup', {
      email: email,
      password: password
    }).then(response => {
      return response
    })
  },
  resetInit ({ commit }, { email }) {
    return axios.post(api.apiUrl + 'reset-init', {
      email: email
    }).then(response => {
      return response
    })
  },
  resetSubmit ({ commit }, { token, password }) {
    return axios.post(api.apiUrl + 'reset-submit', {
      token: token,
      password: password
    }).then(response => {
      return response
    })
  },
  login ({ commit }, { email, password }) {
    return axios.post(api.apiUrl + 'auth', {
      email: email,
      password: password,
      beta: process.env.VUE_APP_BUILD_TARGET === 'BETA'
    }).then(response => {
      return response
    })
  },
  logout () {
    return axios.post(api.apiUrl + 'deauth', {
    }).then(response => {
      return response
    })
  },
  resetProgress () {
    return axios.post(api.apiUrl + 'reset', {
    }).then(response => {
      return response
    })
  },
  deleteAccount () {
    return axios.post(api.apiUrl + 'delete', {
    }).then(response => {
      return response
    })
  },
  inquiry (context, { contactEmail, subject, message, files }) {
    const formData = new FormData()
    formData.append('contactemail', contactEmail)
    formData.append('subject', subject)
    formData.append('message', message)
    files.forEach(file => formData.append('file[]', file))

    return axios.post(api.apiUrl + 'inquiry',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then(response => {
      return response
    })
  },
  betaSignup (context, { email, browser, desktopOS, mobileOS }) {
    return axios.post(api.apiUrl + 'beta-signup', {
      email,
      browser,
      desktopos: desktopOS,
      mobileos: mobileOS
    }).then(response => {
      return response
    })
  },
  email (context, { newEmail, password }) {
    return axios.post(api.apiUrl + 'email', {
      current_email: this.state.userEmail,
      new_email: newEmail,
      password: password
    }).then(response => {
      return response
    })
  },
  synthesizeSpeech (context, { text, voice, gender, language, service }) {
    if (language === 'ja-JP') text = text.replace(/\s+/g, '') // Remove whitespace in Japanese sentences

    return axios.post(api.apiUrl + service, {
      text,
      voice,
      gender,
      language
    },
    {
      responseType: 'blob'
    }).then(response => {
      const audioblob = new Blob([response.data], { type: 'audio/mpeg' })
      const url = URL.createObjectURL(audioblob)
      audio.playAudio(url)
    })
  },
  paypalInit (context, reference) {
    return axios.post(api.apiUrl + 'paypal-init', {
      reference: reference
    }).then(response => {
      return response
    })
  },
  paypalCaptureTransaction (context, orderID) {
    return axios.post(api.apiUrl + 'paypal-capture-transaction', {
      orderID: orderID
    }).then(response => {
      return response
    })
  },
  stripeCreateCheckoutSession (context, reference) {
    return axios.post(api.apiUrl + 'stripe-init', {
      reference
    }).then(response => {
      return response
    })
  },
  updateUserInfo (context) {
    this._vm.$db.sync.get(0).then(sync => {
      const unixTimestamp = sync.user
      this._vm.$db.user.get(0).then(user => {
        delete user.id
        delete user.created

        axios.post(api.apiUrl + 'update/user', {
          unixTimestamp,
          user
        }).then(response => {
          console.log('%cPushed USER table to server.', 'color: green')
        }).catch(error => {
          console.log(error)
        })
      })
    })
  },
  updateSettings (context) {
    this._vm.$db.sync.get(0).then(sync => {
      const unixTimestamp = sync.settings
      this._vm.$db.settings.get(0).then(settings => {
        delete settings.id

        axios.post(api.apiUrl + 'update/settings', {
          unixTimestamp,
          settings
        }).then(response => {
          console.log('%cPushed SETTINGS table to server.', 'color: green')
        }).catch(error => {
          console.log(error)
        })
      })
    })
  },
  updateVocab (context, vocabIds) {
    this._vm.$db.sync.get(0).then(sync => {
      const unixTimestamp = sync.progress
      this._vm.$db.progress.where('vocab_id').anyOf(vocabIds).toArray(vocab => {
        axios.post(api.apiUrl + 'update/vocab', {
          unixTimestamp,
          vocab
        }).then(response => {
          console.log('%cSent PROGRESS changes to server.', 'color: green')
          if (response.data.success) {
            context.dispatch('api/removeFromNetworkQueue', { type: 'vocab', data: response.data.vocab_ids }, { root: true })
          }
        }).catch(error => {
          console.log(error)
        })
      })
    })
  },
  updateCustomVocab (context, vocabIds) {
    this._vm.$db.sync.get(0).then(sync => {
      const unixTimestamp = sync.custom
      this._vm.$db.custom.where('id').anyOf(vocabIds).toArray(vocab => {
        const updateIds = vocab.map(x => x.id)
        const deleteIds = vocabIds.filter(x => !updateIds.includes(x))

        // Send IDs of deleted custom vocab to server
        if (deleteIds.length > 0) {
          axios.post(api.apiUrl + 'delete/custom', {
            unixTimestamp,
            vocabIds: deleteIds
          }).then(response => {
            console.log(`%cSent CUSTOM VOCAB changes to server [D:${deleteIds.length}].`, 'color: green')
            if (response.data.success) {
              context.dispatch('api/removeFromNetworkQueue', { type: 'vocab', data: response.data.vocab_ids }, { root: true })
            } else {
              console.error(response.data.error_description)
            }
          }).catch(error => {
            console.log(error)
          })
        }

        // Push updated custom vocab to server
        if (vocab.length > 0) {
          axios.post(api.apiUrl + 'update/custom', {
            unixTimestamp,
            vocab
          }).then(response => {
            console.log(`%cSent CUSTOM VOCAB changes to server [CU:${vocab.length}].`, 'color: green')
            if (response.data.success) {
              context.dispatch('api/removeFromNetworkQueue', { type: 'vocab', data: response.data.vocab_ids }, { root: true })
            } else {
              console.error(response.data.error_description)
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })
    })
  },
  updateDeckMeta (context, deckIds) {
    this._vm.$db.sync.get(0).then(sync => {
      const unixTimestamp = sync.decks
      this._vm.$db.decksMetadata.where('id').anyOf(deckIds).toArray(decks => {
        const updateIds = decks.map(deck => deck.id)
        const deleteIds = deckIds.filter(deck => !updateIds.includes(deck))

        // Send IDs of deleted decks to server
        if (deleteIds.length > 0) {
          axios.post(api.apiUrl + 'delete/decks', {
            unixTimestamp,
            deckIds: deleteIds
          }).then(response => {
            console.log(`%cSent DECK METADATA changes to server [D:${deleteIds.length}].`, 'color: green')
            if (response.data.success) {
              context.dispatch('api/removeFromNetworkQueue', { type: 'deckMeta', data: response.data.deck_ids }, { root: true })
            } else {
              console.error(response.data.error_description)
            }
          }).catch(error => {
            console.log(error)
          })
        }

        // Push updated decks to server
        if (decks.length > 0) {
          axios.post(api.apiUrl + 'update/deck-metadata', {
            unixTimestamp,
            decks
          }).then(response => {
            console.log(`%cSent DECK METADATA changes to server [U:${decks.length}].`, 'color: green')
            if (response.data.success) {
              context.dispatch('api/removeFromNetworkQueue', { type: 'deckMeta', data: response.data.deck_ids }, { root: true })
            } else {
              console.error(response.data.error_description)
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
