<template>
<v-container>
  <v-card>
    <v-row no-gutters align="center">
      <v-col class="grow text-left">
        <v-card-title>Self-Study</v-card-title>
        <v-card-subtitle>
          Review what you have learned so far - outside the spaced repetition schedule.
        </v-card-subtitle>
      </v-col>
      <v-col class="shrink text-right text-no-wrap"><v-card-title>
        <v-btn depressed large color="reviews" class="white--text"
          :disabled="!readyToStart"
          @click="startSession">
          <v-icon left>mdi-cards</v-icon>
          Start Quiz
        </v-btn></v-card-title>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-card-subtitle>Vocabulary</v-card-subtitle>
    <v-card-text>
      <v-row no-gutters>
        <v-col>
          <v-checkbox
            v-model="learnedVocab"
            label="Learned words"
            :disabled="learnedVocabCount < 1"
            class="text-no-wrap"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="customVocab"
            label="My words"
            :disabled="customVocabCount < 1"
            class="text-no-wrap"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="archivedVocab"
            label="Archived words"
            :disabled="archivedVocabCount < 1"
            class="text-no-wrap"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-subtitle>Quiz Modes</v-card-subtitle>
    <v-card-text>
      <v-row no-gutters>
        <v-col v-for="mode in reviewModes" :key="mode.id">
          <v-checkbox
            v-model="selectedReviewModes"
            :label="mode.titleFull"
            :value="mode.id"
            class="text-no-wrap"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-subtitle>
      SRS Stages
    </v-card-subtitle>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <v-checkbox
            v-model="selectAllReviewModes"
            label="Select all"
            @change="onChangeSelectAllReviewModes"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col v-for="stage in srsStages" :key="stage.id" cols="6" md="4">
          <v-checkbox
            v-model="selectedSrsStages"
            :label="srsStageName(stage.id)"
            :value="stage.id"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-subtitle>Preferences</v-card-subtitle>
    <v-card-text>
      <v-row no-gutters>
        <v-col>
          <v-switch v-model="flashcardMode" class="d-flex justify-center" label="Quick Review"></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import vars from '@/vars.js'

export default {
  data () {
    return {
      availableReviewModes: [1, 2, 4]
    }
  },
  computed: {
    learnedVocab: {
      get () {
        return this.learnedVocabCount > 0 ? this.$store.state.settings.selfStudy.learnedVocab : false
      },
      set (include) {
        this.$store.dispatch('settings/updateLocal', { selfStudy: { learnedVocab: include } })
      }
    },
    learnedVocabCount () {
      return this.$store.getters['vocabulary/learnedVocab'].length
    },
    customVocab: {
      get () {
        return this.customVocabCount > 0 ? this.$store.state.settings.selfStudy.customVocab : false
      },
      set (include) {
        this.$store.dispatch('settings/updateLocal', { selfStudy: { customVocab: include } })
      }
    },
    customVocabCount () {
      return this.$store.getters['vocabulary/customVocab'].length
    },
    archivedVocab: {
      get () {
        return this.archivedVocabCount > 0 ? this.$store.state.settings.selfStudy.archivedVocab : false
      },
      set (include) {
        this.$store.dispatch('settings/updateLocal', { selfStudy: { archivedVocab: include } })
      }
    },
    archivedVocabCount () {
      return this.$store.getters['vocabulary/archivedVocab'].length
    },
    reviewModes () {
      return this.$store.state.reviewModes.filter(mode => this.availableReviewModes.indexOf(mode.id) !== -1)
    },
    selectedReviewModes: {
      get () {
        return this.$store.state.settings.selfStudy.reviewModes
      },
      set (reviewModesIds) {
        this.$store.dispatch('settings/updateLocal', { selfStudy: { reviewModes: reviewModesIds } })
      }
    },
    selectAllReviewModes: {
      get () {
        return this.selectedSrsStages.length === this.srsStages.length
      },
      set () {
        // Nothing to do here...
        // Need setter to prevent console.err()
      }
    },
    srsStages () {
      return this.$store.state.srsStages.slice(1)
    },
    selectedSrsStages: {
      get () {
        return this.$store.state.settings.selfStudy.srsStages
      },
      set (srsStagesIds) {
        this.$store.dispatch('settings/updateLocal', { selfStudy: { srsStages: srsStagesIds } })
      }
    },
    flashcardMode: {
      get () {
        return this.$store.state.settings.selfStudy.flashcardMode
      },
      set (enabled) {
        this.$store.dispatch('settings/updateLocal', { selfStudy: { flashcardMode: enabled } })
      }
    },
    readyToStart () {
      return (this.learnedVocab || this.customVocab || this.archivedVocab) &&
        this.selectedReviewModes.length > 0 &&
        this.selectedSrsStages.length > 0
    }
  },
  methods: {
    srsStageName (stageId) {
      return this.$store.getters.srsStageName(stageId)
    },
    onChangeSelectAllReviewModes (value) {
      this.selectedSrsStages = value ? this.srsStages.map(srsStage => srsStage.id) : []
    },
    startSession () {
      this.$router.push({ name: 'reviews', params: { mode: vars.reviewSessionModes.selfStudy } })
    }
  }
}
</script>
