import { utils } from '@/plugins/utils.js'

const jmdictDtd = parseDtd()
const posShort = {
  noun: 'Noun',
  adjective: 'Adjective',
  adverb: 'Adverb',
  verb: 'Verb',
  numeric: 'Numeric'
}
const posColors = {
  noun: 'blue',
  adjective: 'green',
  adverb: 'orange',
  verb: 'red',
  numeric: 'teal',
  default: 'brown'
}

function parseDtd () {
  // Convert JMDICT DTD to JS OBJECT
  // eslint-disable-next-line import/no-webpack-loader-syntax
  const jmdictDtdRaw = require('raw-loader!@/assets/jmdict_dtd')
  const jmdictDtd = {}
  // console.log(jmdictDtdRaw.default)
  for (const element of jmdictDtdRaw.default.split('<!-- ')) {
    if (element.startsWith('<')) {
      const elementName = element.substring(1, element.indexOf('>'))
      jmdictDtd[elementName] = []

      for (const entity of element.split('\n')) {
        if (entity.startsWith('<!ENTITY')) {
          const entityName = entity.substring(9, entity.indexOf('"')).trim()
          const entityValue = entity.substring(entity.indexOf('"') + 1, entity.lastIndexOf('"'))
          jmdictDtd[elementName].push({ [entityName]: entityValue })
        }
      }
    }
  }
  // console.log(jmdictDtd)
  return jmdictDtd
}

export const jmdict = {
  getGlossAsArray (vocab) {
    const gloss = JSON.parse(vocab.meaning)[vocab.jmdict_sense - 1].gloss
    /* Some "glosses" within "sense" elements in the JMdict database contain
        objects with the following syntax:
        { _: string,
          $: { g_type: 'expl' }
        }
    /* The forEach() below handles those objects by extracting the needed string */
    gloss.forEach((meaning, index) => {
      if (typeof meaning !== 'string') {
        gloss[index] = meaning._.replaceAll(',', ';')
      }
    })
    return gloss
  },
  getGlossAsString (vocab) {
    return this.getGlossAsArray(vocab).join(', ')
  },
  getPartsOfSpeech (vocab, sense) {
    const vocabSenses = JSON.parse(vocab.meaning)
    const pos = vocabSenses[sense - 1].pos
    const posObj = { full: [], short: [], color: [] }

    if (pos) {
      for (let p = 0; p < pos.length; p++) {
        const curPos = pos[p]
        const curPosTrimmed = curPos.substring(1, curPos.length - 1) // every starts with '&' and ends with ';' ==> must be omitted to find match

        posObj.full[p] = Object.values(jmdictDtd.pos.find(e => Object.keys(e)[0] === curPosTrimmed))[0]
        posObj.full[p] = utils.capitalizeFirstLetter(posObj.full[p])

        // short used during reviews and
        // color used for v-icon
        if (curPosTrimmed.startsWith('num')) {
          posObj.short[p] = posShort.numeric
          posObj.color[p] = posColors.numeric
        } else if (curPosTrimmed.startsWith('n')) {
          posObj.short[p] = posShort.noun
          posObj.color[p] = posColors.noun
        } else if (curPosTrimmed.startsWith('v')) {
          posObj.short[p] = posShort.verb
          posObj.color[p] = posColors.verb
        } else if (curPosTrimmed.startsWith('adv')) {
          posObj.short[p] = posShort.adverb
          posObj.color[p] = posColors.adverb
        } else if (curPosTrimmed.startsWith('adj')) {
          posObj.short[p] = posShort.adjective
          posObj.color[p] = posColors.adjective
        } else {
          posObj.short[p] = posObj.full[p]
          posObj.color[p] = posColors.default
        }
      }

      // Remove duplicates from array
      posObj.short = [...new Set(posObj.short)]
    }

    return posObj
  },
  getRelevantInformation (vocab, sense) {
    const vocabSenses = JSON.parse(vocab.meaning)
    const info = vocabSenses[sense - 1].misc

    if (info) {
      for (let p = 0; p < info.length; p++) {
        const curPos = info[p]
        info[p] = Object.values(jmdictDtd.misc.find(e => Object.keys(e)[0] === curPos.substring(1, curPos.length - 1)))[0]
      }

      return info
    } else {
      return []
    }
  }
}
