import api from '@/store/modules/api/api'

const sounds = []
let currentIndex = 0

function onended (evt) {
  currentIndex++
  sounds[currentIndex].play()
}

export const audio = {
  playVocabAudio (...audioIds) {
    this.stop()
    currentIndex = 0
    for (let i = 0; i < audioIds.length; i++) {
      if (!audioIds[i]) return // cancel attempt to play audio if vocab item has no audio

      sounds[i] = new Audio(api.audioUrl + audioIds[i])
      if (i < audioIds.length - 1) sounds[i].onended = onended
    }
    sounds[0].play()
  },
  playAudio (url) {
    this.stop()
    currentIndex = 0
    sounds[0] = new Audio(url)
    sounds[0].play()
  },
  stop () {
    if (sounds[currentIndex]) {
      sounds[currentIndex].pause()
      sounds[currentIndex].currentTime = 0
    }
  }
}
