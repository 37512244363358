<template>
  <v-overlay color="background" opacity="1">
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <half-circle-spinner
          :animation-duration="1000"
          :size="64"
          :color="color"
        />
      </v-col>
      <v-col cols="12">
        <div class="text-h5 text-center primary--text">
          {{ message }}
        </div>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'

export default {
  props: {
    message: {
      type: String,
      default: 'ローディング'
    }
  },
  components: {
    HalfCircleSpinner
  },
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    color () {
      return this.$vuetify.theme.themes[this.theme].primary
    }
  }
}
</script>
