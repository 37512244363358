export default {
  localStorageKeys: {
    log: 'torii_srs:console_log',
    announcement: 'torii_srs:announcement',
    maintenance: 'torii_srs:maintenance',
    sync: 'torii_srs:network_queue',
    prefs: 'torii_srs:local_prefs',
    lang: 'torii_srs:lang'
  },
  reviewSessionModes: {
    lessons: 'lessons',
    selfStudy: 'selfStudy',
    recentLessons: 'recentLessons',
    recentMistakes: 'recentMistakes'
  }
}
