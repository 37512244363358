<template>
<v-container fluid fill-height>
  <v-row align="start" justify="start">
    <v-col>
      <v-card max-width="500px" color="rgb(255, 255, 255, 0.8)" elevation="12" class="mx-auto">
        <v-card-title class="text-h4">
          ようこそ！ ʕ•ᴥ•ʔ
        </v-card-title>
        <v-divider class="mx-5" />
        <v-card-text>
          <v-form v-model="valid" ref="signupForm">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email"
              prepend-icon="mdi-account-circle"
              @keyup.enter="signUp"
            />
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="signUp"
            />
            <v-text-field
              v-model="passwordConfirm"
              :rules="passwordConfirmRules"
              :type="showPassword ? 'text' : 'password'"
              label="Confirm Password"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="signUp"
            />
            <v-checkbox
              v-model="checkbox"
              :rules="[v => !!v || 'You must agree to continue!']"
              required
            >
              <template v-slot:label>
                <div>
                  I agree to the
                  <TermsOfUse ref="terms" text-link color="primary" />
                  and
                  <PrivacyPolicy ref="privpol" text-link color="primary" />
                  policy.
                </div>
              </template>
            </v-checkbox>
          </v-form>
          <v-btn block depressed color="primary" :loading="loading" :disabled="loading" @click="signUp">Sign up</v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-btn text block color="primary" :href="openWKtopic()" target="_blank">What's this?</v-btn>
          <v-btn text block color="primary" @click="$emit('showSignIn')">Already have an account?</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import TermsOfUse from '@/components/legal/TermsOfUse.vue'
import PrivacyPolicy from '@/components/legal/PrivacyPolicy.vue'

export default {
  data () {
    return {
      defaultErrorReqField: 'Field can\'t be empty',
      loading: false,
      valid: false,
      showPassword: false,
      email: '',
      emailRules: [
        v => !!v || this.defaultErrorReqField,
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'E-mail must be valid'
      ],
      password: '',
      passwordRules: [
        v => !!v || this.defaultErrorReqField,
        v => /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,64}$/.test(v) || '8-64 characters, at least 1 letter and 1 number'
      ],
      passwordConfirm: '',
      passwordConfirmRules: [
        v => !!v || this.defaultErrorReqField,
        v => v === this.password || 'Passwords must match'
      ],
      checkbox: false
    }
  },
  components: {
    TermsOfUse,
    PrivacyPolicy
  },
  methods: {
    signUp () {
      this.$refs.signupForm.validate()
      if (this.valid) {
        const mail = this.email
        const pw = this.password

        this.loading = true
        this.$store.dispatch('apiPost/signup', {
          email: mail,
          password: pw
        }).then(response => {
          if (response.data.success) {
            // Successful login
            this.$snackbar.showMessage({ content: 'Account created! Logging you in right now...', color: 'success' })
            this.login(mail, pw)
          } else {
            // Unsuccessful login
            this.$snackbar.showMessage({ content: response.data.error_description, color: 'error' })
          }
        }).then(() => {
          this.loading = false
        })
      }
    },
    login (mail, pw) {
      this.loading = true
      this.$store.dispatch('apiPost/login', {
        email: mail,
        password: pw
      }).then(response => {
        if (response.data.success) {
          // Successful login
          this.$router.push('home')
        } else {
          // Unsuccessful login
          this.$snackbar.showMessage({ content: response.data.error_description, color: 'error' })
        }
      }).then(() => {
        this.loading = false
      })
    },
    openWKtopic () {
      return 'https://community.wanikani.com/t/torii-srs-learning-application-for-vocabulary/31295'
    }
  }
}
</script>
