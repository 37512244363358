<template>
  <v-row justify="center">
    <v-dialog v-model="show">
      <v-card>
        <v-card-title>
          <span class="headline">Reset Password</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form" @submit.prevent="resetPassword">
            <v-text-field autofocus v-model="email" label="Email" required :rules="emailRule"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="show = false">Cancel</v-btn>
          <v-btn color="primary" depressed :loading="loading" :disabled="loading" @click="resetPassword">Send Link</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      loading: false,
      valid: false,
      email: '',
      emailRule: [
        v => !!v || 'Field can\'t be empty'
      ]
    }
  },
  methods: {
    resetPassword () {
      this.$refs.form.validate()
      if (this.valid) {
        this.loading = true

        this.$store.dispatch('apiPost/resetInit', {
          email: this.email
        }).then(response => {
          if (response.data.success) {
            this.$emit('emailSent', 'success')
          } else {
            if (response.data.error_code === 'invalid') {
              this.$emit('emailSent', 'invalid')
            } else {
              this.$emit('emailSent', 'undefined')
            }
          }
        }).then(() => {
          this.loading = false
          this.show = false
        })
      }
    }
  }
}
</script>
