<template>
<v-container fluid class="pa-0">
<v-row dense>
  <v-col v-for="item in cards" :key="item.srsGroup" cols="6" sm="6" md="4" lg="auto" class="grow">
    <v-tooltip bottom :open-delay="tooltipOpenDelay">
      <template v-slot:activator="{on}">
        <v-card v-on="on" justify="center" align="center">
          <v-row no-gutters align="center" class="flex-nowrap">
            <v-col cols="auto">
              <v-avatar size="80" rounded :color="srsGroupColor(item.srsGroup)" class="ma-0 rounded-l-1 rounded-r-0">
                <v-icon light large color="white" class="rounded-0">{{ item.icon }}</v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="auto" class="text-center grow">
              <div class="text-h5 text--primary">{{ srsGroupCount(item.srsStages) }}</div>
              <div class="text-subtitle-1 text--secondary">{{ srsGroupName(item.srsGroup) }}</div>
            </v-col>
          </v-row>
        </v-card>
      </template>
      <v-data-table dense
        hide-default-footer
        :headers="headers"
        :items="items(item.srsStages)"
        mobile-breakpoint="0"
      ></v-data-table>
    </v-tooltip>
  </v-col>
</v-row>
</v-container>
</template>

<script>
export default {
  data () {
    return {
      tooltipOpenDelay: 150,
      cards: [
        { srsGroup: 1, srsStages: [1, 2, 3, 4], icon: '$vuetify.icons.acorn' },
        { srsGroup: 2, srsStages: [5, 6], icon: '$vuetify.icons.seedling' },
        { srsGroup: 3, srsStages: [7], icon: '$vuetify.icons.leaf' },
        { srsGroup: 4, srsStages: [8], icon: '$vuetify.icons.tree' },
        { srsGroup: 5, srsStages: [9], icon: '$vuetify.icons.toriigate' }
      ],
      headers: [
        { text: '', value: 'name', sortable: false },
        { text: 'N5', value: 'n5', sortable: false },
        { text: 'N4', value: 'n4', sortable: false },
        { text: 'N3', value: 'n3', sortable: false },
        { text: 'N2', value: 'n2', sortable: false },
        { text: 'N1', value: 'n1', sortable: false },
        { text: 'N-', value: 'nx', sortable: false },
        { text: 'Custom', value: 'custom', sortable: false }
      ]
    }
  },
  computed: {
  },
  methods: {
    srsGroupName (groupId) {
      return this.$store.state.srsGroups.find(srsGroup => srsGroup.id === groupId).name
    },
    srsGroupColor (groupId) {
      return this.$store.state.srsGroups.find(srsGroup => srsGroup.id === groupId).color
    },
    srsGroupCount (srsStagesIds) {
      let counter = 0
      srsStagesIds.forEach(stageId => {
        for (const value of Object.values(this.$store.getters['vocabulary/srsStagesCount'][stageId])) {
          counter += value
        }
      })

      return counter
    },
    items (srsStagesIds) {
      const items = []
      srsStagesIds.forEach(stageId => {
        items.push({
          name: this.$store.getters.srsStageName(stageId),
          n5: this.$store.getters['vocabulary/srsStagesCount'][stageId].n5,
          n4: this.$store.getters['vocabulary/srsStagesCount'][stageId].n4,
          n3: this.$store.getters['vocabulary/srsStagesCount'][stageId].n3,
          n2: this.$store.getters['vocabulary/srsStagesCount'][stageId].n2,
          n1: this.$store.getters['vocabulary/srsStagesCount'][stageId].n1,
          nx: this.$store.getters['vocabulary/srsStagesCount'][stageId].nx,
          custom: this.$store.getters['vocabulary/srsStagesCount'][stageId].custom
        })
      })

      // summing up
      if (srsStagesIds.length > 1) {
        items.push({
          name: 'SUM',
          n5: items.reduce((prevVal, curVal) => prevVal + curVal.n5, 0),
          n4: items.reduce((prevVal, curVal) => prevVal + curVal.n4, 0),
          n3: items.reduce((prevVal, curVal) => prevVal + curVal.n3, 0),
          n2: items.reduce((prevVal, curVal) => prevVal + curVal.n2, 0),
          n1: items.reduce((prevVal, curVal) => prevVal + curVal.n1, 0),
          nx: items.reduce((prevVal, curVal) => prevVal + curVal.nx, 0),
          custom: items.reduce((prevVal, curVal) => prevVal + curVal.custom, 0)
        })
      }

      return items
    }
  }
}
</script>

<style scoped>
.rounded-l-1 {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
</style>
