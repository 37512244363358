export const pitchaccent = {
  getType: (reading, pattern) => {
    pattern = String(pattern)
    while (pattern.length < reading.length) pattern = `0${pattern}`

    if (/^0[01]*$/.test(pattern)) return 'heiban' // starts with 0, followed by 0s and/or 1s
    else if (/^[21][20]*0$/.test(pattern)) return 'atamadaka' // starts high (2 or 1), followed by 2s or 0s, ends with 0
    else if (/^0.*[2].*0$/.test(pattern)) return 'nakadaka' // starts and ends with 0, at least one 2 inbetween
    else if (/^.*2$/.test(pattern)) return 'odaka' // ends with 2
    else return 'nothing'
  },
  generate: (reading, accent) => {
    accent = String(accent)
    while (accent.length < reading.length) accent = `0${accent}`

    const type = pitchaccent.getType(reading, accent)

    let generatedString = ''
    for (let i = 0; i < reading.length; i++) {
      let css
      switch (accent.charAt(i)) {
        case '0':
          if (i < reading.length - 1 && accent.charAt(i + 1) !== '0') css = 'pitch-accent--low pitch-accent--rise'
          else css = 'pitch-accent--low'
          break
        case '1': css = 'pitch-accent--high'
          break
        case '2': css = 'pitch-accent--high pitch-accent--drop'
          break
      }
      generatedString += `<span class="${css} pitch-type--${type}">${reading.charAt(i)}</span>`
    }

    return generatedString
  }
}
