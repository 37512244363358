<template>
<v-dialog v-model="show" :persistent="persistent" content-class="elevation-0">
  <v-card>
    <v-card-text v-if="img">
      <v-img :src="require(`@/assets/svg/${img}`)" width="100%" max-height="200" contain></v-img>
      <v-divider width="300" class="mx-auto"></v-divider>
    </v-card-text>
    <v-card-title :class="centeredComponents ? 'justify-center' : ''">
      <span class="headline">{{ title }}</span>
    </v-card-title>
    <v-card-subtitle :class="centeredComponents ? 'text-center' : ''">{{ subtitle }}</v-card-subtitle>
    <v-card-text :class="centeredComponents ? 'text-center' : ''">{{ message }}</v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="mt-2">
      <v-spacer></v-spacer>
      <v-btn v-if="!singleButton" color="primary" outlined @click="decline">{{ declineButtonText }}</v-btn>
      <v-btn color="primary" depressed @click="confirm">{{ confirmButtonText }}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  props: {
    persistent: {
      type: Boolean,
      default: false
    },
    centeredComponents: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      default: ''
    },
    singleButton: {
      type: Boolean,
      default: false
    },
    confirmButtonText: {
      type: String,
      default: 'Yes'
    },
    declineButtonText: {
      type: String,
      default: 'No'
    },
    title: String,
    subtitle: String,
    message: String
  },
  data () {
    return {
      showDialog: false
    }
  },
  computed: {
    show: {
      get () {
        return this.showDialog
      },
      set (newState) {
        this.showDialog = newState
        this.$store.dispatch('setKeysEnabled', !newState)
      }
    }
  },
  methods: {
    confirm () {
      this.show = false
      this.$emit('confirm')
    },
    decline () {
      this.show = false
      this.$emit('decline')
    }
  }
}
</script>
