import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/i18n'
import router from '@/router/router'
import moment from 'moment'
import snackbar from './modules/snackbar'
import api from './modules/api/api'
import apiGet from './modules/api/get'
import apiPost from './modules/api/post'
import settings from './modules/settings'
import vocabulary from './modules/vocabulary'
import decks from './modules/decks'
import user from './modules/user'
import dashboard from './modules/dashboard'
import lessons from './modules/lessons'
import reviews from './modules/reviews'

Vue.use(Vuex)

const intervalTimerCounter = 20

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    snackbar,
    api,
    apiGet,
    apiPost,
    settings,
    vocabulary,
    decks,
    user,
    dashboard,
    lessons,
    reviews
  },
  state: {
    installPrompt: null,
    online: navigator.onLine,
    intervalTimer: null,
    loggedIn: false,
    initialSyncCompleted: false,
    requireReload: false,
    keysEnabled: true,
    proExpDate: 0,
    userEmail: '',
    limits: {
      customVocab: {
        free: 25,
        pro: 'Unlimited'
      },
      notesLength: {
        free: 150,
        pro: 300
      }
    },
    pollyVoices: [
      // { nickname: 'Mizuki', name: 'Mizuki', language: 'ja-JP', gender: 'Female', color: 'mizuki', service: 'polly' },
      // { nickname: 'Takumi', name: 'Takumi', language: 'ja-JP', gender: 'Male', color: 'takumi', service: 'polly' },
      { nickname: 'Emi', name: 'ja-JP_EmiV3Voice', language: 'ja-JP', gender: 'Female', color: 'mizuki', service: 'tts-watson' },
      { nickname: 'Nanami', name: 'ja-JP-NanamiNeural', language: 'ja-JP', gender: 'Female', color: 'mizuki', service: 'tts-azure' },
      { nickname: 'Keita', name: 'ja-JP-KeitaNeural', language: 'ja-JP', gender: 'Male', color: 'takumi', service: 'tts-azure' },
      { nickname: 'Amy', name: 'Amy', language: 'en-GB', gender: 'Female', color: 'amy', service: 'polly' },
      { nickname: 'Matthew', name: 'Matthew', language: 'en-US', gender: 'Male', color: 'matthew', service: 'polly' }
    ],
    srsGroups: [
      { id: 0, name: 'Lesson', color: 'lessons' },
      { id: 1, name: i18n.t('srs1'), color: 'light-green lighten-2' },
      { id: 2, name: 'Apprentice', color: 'green lighten-2' },
      { id: 3, name: 'Adept', color: 'teal lighten-2' },
      { id: 4, name: 'Expert', color: 'indigo lighten-2' },
      { id: 5, name: 'Sensei', color: 'gold' }
    ],
    srsStages: [
      {
        id: 0,
        group: 0,
        nameSuffix: '',
        nextReviewValue: 0,
        nextReviewText: 'Now'
      },
      {
        id: 1,
        group: 1,
        nameSuffix: '1',
        nextReviewValue: 4,
        get nextReviewText () { return `${this.nextReviewValue} hours` }
      },
      {
        id: 2,
        group: 1,
        nameSuffix: '2',
        nextReviewValue: 8,
        get nextReviewText () { return `${this.nextReviewValue} hours` }
      },
      {
        id: 3,
        group: 1,
        nameSuffix: '3',
        nextReviewValue: 24,
        get nextReviewText () { return `${this.nextReviewValue} hours` }
      },
      {
        id: 4,
        group: 1,
        nameSuffix: '4',
        nextReviewValue: 48,
        get nextReviewText () { return `${this.nextReviewValue} hours` }
      },
      {
        id: 5,
        group: 2,
        nameSuffix: '1',
        nextReviewValue: 7 * 24,
        get nextReviewText () { return `${this.nextReviewValue / 24} days` }
      },
      {
        id: 6,
        group: 2,
        nameSuffix: '2',
        nextReviewValue: 14 * 24,
        get nextReviewText () { return `${this.nextReviewValue / 24} days` }
      },
      {
        id: 7,
        group: 3,
        nameSuffix: '',
        nextReviewValue: 30 * 24,
        get nextReviewText () { return `${this.nextReviewValue / 24} days` }
      },
      {
        id: 8,
        group: 4,
        nameSuffix: '',
        nextReviewValue: 120 * 24,
        get nextReviewText () { return `${this.nextReviewValue / 24} days` }
      },
      {
        id: 9,
        group: 5,
        nameSuffix: '',
        nextReviewValue: 10 * 365 * 24,
        get nextReviewText () { return `${this.nextReviewValue / 24 / 365} years` }
      }
    ],
    reviewModes: [
      {
        id: 1,
        titleShort: 'JP → EN',
        titleFull: 'Japanese → English'
      },
      {
        id: 2,
        titleShort: 'EN → JP',
        titleFull: 'English → Japanese'
      },
      {
        id: 3,
        titleShort: 'JP ⇄ EN',
        titleFull: 'Japanese ⇄ English'
      },
      {
        id: 4,
        titleShort: 'JP → JP',
        titleFull: 'Japanese → Japanese'
      }
    ],
    navDrawerItems: {
      dashboard: { title: i18n.t('navDrawer.dashboard'), icon: 'mdi-view-dashboard', link: 'dashboard' },
      selfStudy: { title: i18n.t('navDrawer.self'), icon: 'mdi-library', link: 'selfstudy' },
      add: { title: i18n.t('navDrawer.add'), icon: 'mdi-database-plus', link: 'add' },
      decks: { title: i18n.t('navDrawer.decks'), icon: 'mdi-cards', link: 'decks' },
      browse: { title: i18n.t('navDrawer.browse'), icon: 'mdi-book-open-page-variant', link: 'browse' },
      stats: { title: i18n.t('navDrawer.stats'), icon: 'mdi-chart-bar', link: 'stats' },
      help: { title: i18n.t('navDrawer.kb'), icon: 'mdi-frequently-asked-questions', link: 'faq' },
      settings: { title: i18n.t('navDrawer.settings'), icon: 'mdi-cog', link: 'settings' },
      appearance: { title: i18n.t('navDrawer.appearance'), icon: 'mdi-palette', link: '' },
      lang: { title: i18n.t('navDrawer.lang'), icon: 'mdi-web', link: '' },
      prime: { title: 'Support Torii', icon: 'mdi-heart', link: 'prime' }
    }
  },
  getters: {
    online (state, getters, rootState) {
      return state.online && !rootState.settings.offlineMode
    },
    proUser (state) {
      return state.proExpDate > moment.utc().unix()
    },
    lifetimeMember (state) {
      return state.proExpDate >= 4102444800 // Fri Jan 01 2100 00:00:00 GMT+0000
    },
    srsStageName: (state) => (srsStageId) => {
      const grpId = state.srsStages.find(stage => stage.id === srsStageId).group
      return `${state.srsGroups.find(grp => grp.id === grpId).name} ${state.srsStages[srsStageId].nameSuffix}`
    },
    japanesePollyVoices (state) {
      return state.pollyVoices.filter(voice => voice.language === 'ja-JP')
    },
    englishPollyVoices (state) {
      return state.pollyVoices.filter(voice => voice.language.startsWith('en-'))
    }
  },
  mutations: {
    setInstallPrompt (state, event) {
      state.installPrompt = event
    },
    setOnlineStatus (state, value) {
      state.online = value
    },
    setLoggedIn (state, isLoggedIn) {
      state.loggedIn = isLoggedIn
    },
    setKeysEnabled (state, enabled) {
      state.keysEnabled = enabled
    },
    setProExpiryDate (state, unixTimestamp) {
      state.proExpDate = unixTimestamp
    },
    initialSyncDone (state) {
      state.initialSyncCompleted = true
    },
    requireReload (state, value) {
      state.requireReload = value
    },
    userEmail (state, email) {
      state.userEmail = email
    },
    setIntervalTimer (state, func) {
      console.log('* Interval timer started *')
      clearInterval(state.intervalTimer)
      state.intervalTimer = setInterval(func, intervalTimerCounter * 1000)
    }
  },
  actions: {
    setInstallPrompt (context, event) {
      context.commit('setInstallPrompt', event)
    },
    setOnlineStatus (context, value) {
      context.commit('setOnlineStatus', value)
    },
    setLoggedIn (context, isLoggedIn) {
      context.commit('setLoggedIn', isLoggedIn)
      context.dispatch('api/initNetworkQueue')
    },
    setKeysEnabled (context, enabled) {
      if (enabled) {
        setTimeout(() => { context.commit('setKeysEnabled', true) }, 250)
      } else {
        context.commit('setKeysEnabled', false)
      }
    },
    setProExpiryDate (context, unixTimestamp) {
      context.commit('setProExpiryDate', unixTimestamp)
      Vue.prototype.$db.pro.put({ id: 0, exp_date: unixTimestamp })
    },
    initialSyncDone (context) {
      context.commit('initialSyncDone')
    },
    requireReload (context) {
      context.commit('requireReload', true)
    },
    reload (context) {
      console.log('Reloading...')
      context.commit('requireReload', false)

      Vue.prototype.$db.user.toArray(user => {
        context.dispatch('user/init', user[0], { root: true })
        Vue.prototype.$db.settings.toArray(settings => {
          context.dispatch('settings/init', settings[0], { root: true })
        })
      })

      Vue.prototype.$db.vocabulary.toArray(serverVocab => {
        Vue.prototype.$db.progress.toArray(progress => {
          Vue.prototype.$db.custom.toArray(customVocab => {
            // TODO: Delete before public release
            customVocab.forEach(cv => {
              cv.archived = cv.archived || 0
              cv.prioritized = cv.prioritized || 0
            })
            // ^^^ //

            context.dispatch('vocabulary/init', { serverVocab, progress, customVocab }, { root: true })

            Vue.prototype.$db.decksMetadata.toArray(deckMeta => {
              Vue.prototype.$db.decks.toArray(deckData => {
                const deckIds = deckMeta.map(s => s.id)
                const deckDataMapped = deckIds.map(id => {
                  return {
                    id: id,
                    data: deckData.filter(s => s.id === id).map(edition => edition.vocab_id)
                  }
                })
                const decks = deckDataMapped.map(data => ({ ...data, ...deckMeta.find(deck => deck.id === data.id) }))
                context.dispatch('decks/init', decks, { root: true })
                context.dispatch('setIntervalTimer')
              })
            })
          })
        })
      })
    },
    setIntervalTimer (context) {
      context.commit('setIntervalTimer', function () {
        if (document.hasFocus()) {
          switch (router.currentRoute.name) {
            case 'lessons':
              context.dispatch('user/update', { timeLessons: context.state.user.timeLessons + intervalTimerCounter }, { root: true })
              break
            case 'reviews':
              context.dispatch('user/update', { timeReviews: context.state.user.timeReviews + intervalTimerCounter }, { root: true })
              break
            default:
              context.dispatch('user/update', { timeIdle: context.state.user.timeIdle + intervalTimerCounter }, { root: true })
          }
        }

        // context.dispatch('apiGet/synced')

        // regular vocab
        if (context.rootState.api.networkQueue.vocab.length > 0) {
          context.dispatch('apiPost/updateVocab', context.rootState.api.networkQueue.vocab, { root: true })
        }
        // custom vocab
        if (context.rootState.api.networkQueue.custom.length > 0) {
          context.dispatch('apiPost/updateCustomVocab', context.rootState.api.networkQueue.custom, { root: true })
        }
        // deck metadata
        if (context.rootState.api.networkQueue.deckMeta.length > 0) {
          context.dispatch('apiPost/updateDeckMeta', context.rootState.api.networkQueue.deckMeta, { root: true })
        }
      })
    },
    userEmail (context, email) {
      context.commit('userEmail', email)
    }
  }
})
