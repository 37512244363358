const snackbar = {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store.')
    }

    Vue.prototype.$snackbar = {
      showMessage (payload) {
        store.commit('snackbar/showMessage', payload, { root: true })
      }
    }
  }
}
export default snackbar
