<template>
<v-container fluid fill-height class="pa-0">
  <FontRandomizer ref="fontRand" />
  <LoadingOverlay v-if="!loaded" />
  <template v-if="loaded">
  <v-row no-gutters class="flex-grow-0">
    <v-col cols="12" class="d-flex flex-column">
      <v-toolbar dense dark>
        <v-menu v-if="toolbarMenu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, i) in toolbarItems"
              :key="i"
              :disabled="item.disabled"
              @click.stop="item.action"
            >
              <v-list-item-icon>
                <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip v-else bottom v-for="(item, i) in toolbarItems" :key="i">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon :disabled="item.disabled" @click.stop="item.action">
              <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
            </v-btn>
          </template>
          {{ item.tooltip || item.title }}
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-chip v-show="isSelfStudySession" v-on="on" outlined color="white" class="mr-3">Self-Study</v-chip>
          </template>
          This is a Self-Study session.<br/>
          SRS progression is disabled.
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-chip
              v-show="isExtraStudySession"
              v-on="on"
              outlined
              color="white"
              class="mr-3"
            >
              Extra Study
            </v-chip>
          </template>
          This is an Extra Study session.<br/>
          SRS progression is disabled.
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-icon v-on="on" class="mr-1">mdi-inbox</v-icon>
          </template>
          {{ reviewsAvailable }} Items Available
        </v-tooltip>
        {{ reviewsAvailable }}
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-icon v-on="on" class="ml-3 mr-1">mdi-check-bold</v-icon>
          </template>
          {{ reviewsCompleted }} Items Completed
        </v-tooltip>
        {{ reviewsCompleted }}
      </v-toolbar>
    </v-col>
    <v-col cols="12">
      <v-card tile flat color="reviews" align="center" justify="center" :height="$vuetify.breakpoint.smAndDown ? 150 : 250">
        <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col>
            <div v-if="curReview.reviewMode === 'JpToEn'">
              <div v-show="!audioReview">
                <v-hover v-if="!hideFurigana" v-slot="{ hover }">
                  <div
                    :class="pitchAccentClass"
                    class="shadow white--text text-h4 text-md-h3 mb-2 mb-md-5"
                    :style="hover ? null : japaneseFont"
                    @click="toggleFuriganaVisibility"
                    v-html="furiganaOrPlaceholder"
                  ></div>
                </v-hover>
                <v-hover v-slot="{ hover }">
                  <div
                    :class="pitchAccentClass"
                    class="shadow white--text text-h2 text-md-h1 font-weight-medium"
                    :style="hover ? null : japaneseFont"
                    v-html="vocab"
                  ></div>
                </v-hover>
              </div>
              <div v-show="audioReview">
                <v-btn icon width="100" height="100" @click="playWordAudio">
                  <v-icon size="100" color="white">mdi-volume-high</v-icon>
                </v-btn>
              </div>
            </div>
            <div v-else-if="curReview.reviewMode === 'JpToJp'">
              <v-hover v-slot="{ hover }">
                <div class="shadow white--text text-h2 text-md-h1 font-weight-medium" :style="hover ? null : japaneseFont">{{ curReview.vocab }}</div>
              </v-hover>
            </div>
            <div v-else>
              <div class="line-clamp shadow white--text text-h5 text-md-h4 font-weight-medium">{{ gloss }}</div>
              <div class="text-truncate text-h6 text-md-h5 white--text mt-0 mt-md-2">{{ synonyms }}</div>
              <div class="white--text mt-3 mt-md-5" :style="{ position: 'absolute', bottom: '5%', left: '50%', transform: 'translateX(-50%)' }">{{ partOfSpeech }}</div>
            </div>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-chip v-show="showChip" label
            :color="chipBgColor"
            :textColor="chipTextColor"
            :style="{ position: 'absolute', bottom: '5px', left: '50%', transform: 'translateX(-50%)' }"
            class="mx-1 my-1">
            <v-icon left
              :color="chipTextColor">
              {{ chipIcon }}
            </v-icon>
            <h2>{{ chipLabel }}</h2>
          </v-chip>
        </v-expand-transition>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-toolbar dense :light="curReview.reviewMode === 'JpToEn'" :dark="japaneseInputExpected">
        <v-spacer></v-spacer>
        <div v-if="curReview.reviewMode === 'JpToEn'" class="text-h6 text-md-h5">Vocabulary <b>Meaning</b></div>
        <div v-else class="text-h6 text-md-h5">Vocabulary <b>Reading</b></div>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12">
      <TextInputModule ref="tim"
        v-if="!flashcardMode"
        @play-audio="playAudio"
        @play-word-audio="playWordAudio"
        @play-sentence-audio="playSentenceAudio"
        @response-submitted="awaitingResponse = false"
        @reveal-furigana="revealFurigana = true"
        @next-review="next"
        @review-loaded="reviewLoaded"
        @autoplay-audio="autoplayAudio"
      />
      <FlashcardModule ref="fm"
        v-else
        @play-audio="playAudio"
        @play-word-audio="playWordAudio"
        @play-sentence-audio="playSentenceAudio"
        @response-submitted="awaitingResponse = false"
        @reveal-furigana="revealFurigana = true"
        @next-review="next"
        @review-loaded="reviewLoaded"
        @autoplay-audio="autoplayAudio"
      />
    </v-col>
  </v-row>
  <ReorderDialog ref="reorderDialog" @closed="focused" />
  <ConfirmDialog
    ref="cdQuitSess"
    title="Quit Study Session?"
    message="If you quit now, the vocab items you have not finished quizzing on yet will remain in your lessons."
    confirm-button-text="Quit"
    decline-button-text="Cancel"
    :persistent="false"
    @confirm="$router.push('dashboard')"
  />
  <ConfirmDialog
    ref="cdArchiveRev"
    :title="`Archive this word?`"
    message="The item will be removed from your lessons and review queue. It can be unarchived at any time (Dashboard => Browse)."
    :persistent="false"
    @confirm="archive"
  />
  <ConfirmDialog
    ref="cdLessons"
    :persistent="true"
    :centeredComponents="dailyLessonsRemaining < 1"
    :img="dailyLessonsRemaining < 1 ? 'well_done.svg' : ''"
    :single-button="dailyLessonsRemaining < 1"
    :title="dailyLessonsRemaining > 0 ? 'Good job!' : 'All done!'"
    subtitle="The words you've just learned have been moved to your review queue."
    :message="dailyLessonsRemaining > 0
      ? `There are ${dailyLessonsRemaining} more lessons available. Continue?`
      : `You finished today's lessons. Awesome!`"
    :confirm-button-text="dailyLessonsRemaining > 0 ? 'Continue' : 'End Session'"
    decline-button-text="End Session"
    @confirm="dailyLessonsRemaining > 0 ? $router.push('lessons') : $router.push('dashboard')"
    @decline="$router.push('dashboard')"
  ></ConfirmDialog>
  <ConfirmDialog
    ref="cdSelfStudy"
    title="All done!"
    message="Start over?"
    confirm-button-text="Quit"
    decline-button-text="Restart"
    :persistent="true"
    @decline="initSession(); nextReview()"
    @confirm="$router.push('dashboard')"
  />
  <ConfirmDialog
    ref="cdRecentLessons"
    title="Good job!"
    message="You can review your recent lessons again later."
    confirm-button-text="Quit"
    decline-button-text="Restart"
    :persistent="true"
    @decline="initSession(); nextReview()"
    @confirm="$router.push('dashboard')"
  />
  </template>
</v-container>
</template>

<script>
import vars from '@/vars.js'
import { jmdict } from '@/plugins/jmdict.js'
import { audio } from '@/plugins/audio.js'
import { pitchaccent } from '@/plugins/pitchaccent.js'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TextInputModule from './TextInputModule.vue'
import FlashcardModule from './FlashcardModule.vue'
import ReorderDialog from './ReorderDialog.vue'
import FontRandomizer from '@/components/FontRandomizer.vue'

export default {
  components: {
    TextInputModule,
    FlashcardModule,
    ReorderDialog,
    FontRandomizer,
    ConfirmDialog,
    LoadingOverlay
  },
  data () {
    return {
      loaded: false,
      awaitingResponse: true,
      showChip: false,
      chipLabel: '',
      chipIcon: '',
      chipBgColor: '',
      chipTextColor: '',
      answerCorrect: false,
      revealFurigana: false,
      pollyVoiceCycle: 0,
      randomFont: '',
      toBeUpdated: undefined
    }
  },
  computed: {
    prevReview () {
      return this.$store.state.reviews.prevReview
    },
    mode () {
      return this.$store.state.reviews.mode
    },
    isSelfStudySession () {
      return this.mode === vars.reviewSessionModes.selfStudy
    },
    isExtraStudySession () {
      return this.mode === vars.reviewSessionModes.recentLessons || this.mode === vars.reviewSessionModes.recentMistakes
    },
    toolbarItems () {
      const items = [
        {
          id: 2,
          condition: !this.flashcardMode,
          disabled: false,
          title: `LM is ${this.lightningMode ? 'ON' : 'OFF'}`,
          tooltip: `Lightning Mode is ${this.lightningMode ? 'ON' : 'OFF'}`,
          icon: this.lightningMode ? 'mdi-lightning-bolt' : 'mdi-lightning-bolt-outline',
          iconColor: this.lightningMode ? 'primary' : '',
          action: () => { this.lightningMode = !this.lightningMode }
        },
        {
          id: 4,
          condition: true,
          disabled: this.customVocab,
          title: 'Archive Item',
          icon: 'mdi-archive',
          action: () => { this.$refs.cdArchiveRev.show = true }
        },
        {
          id: 3,
          condition: this.mode !== vars.reviewSessionModes.lessons,
          disabled: false,
          title: 'Reorder',
          icon: 'mdi-sort',
          action: () => { this.$refs.reorderDialog.show = true }
        },
        {
          id: 1,
          condition: true,
          disabled: false,
          title: 'Quit',
          icon: 'mdi-home',
          action: () => { this.mode === vars.reviewSessionModes.lessons ? this.$refs.cdQuitSess.show = true : this.sessionSummary() }
        }
      ].filter(item => item.condition)

      return this.toolbarMenu ? items : items.sort((a, b) => a.id - b.id)
    },
    toolbarMenu () {
      return this.$vuetify.breakpoint.xs
    },
    japaneseFont () {
      if (this.$store.state.settings.reviewsFontRandomizer && this.awaitingResponse && this.mode !== vars.reviewSessionModes.lessons) {
        return this.randomFont
      } else {
        return null
      }
    },
    flashcardMode () {
      if (this.mode === vars.reviewSessionModes.lessons) return false
      else if (this.isSelfStudySession) return this.$store.state.settings.selfStudy.flashcardMode
      else return this.$store.state.settings.flashcardMode
    },
    lightningMode: {
      get () {
        return this.$store.state.settings.reviewsLightningMode
      },
      set (enabled) {
        this.$store.dispatch('settings/update', { reviewsLightningMode: enabled })
      }
    },
    curReview () {
      return this.$store.state.reviews.curReview
    },
    srsStages () {
      return this.$store.state.srsStages.slice(1)
    },
    audioReview () {
      if (this.curReview.reviewMode === 'JpToEn') {
        switch (this.$store.state.settings.reviewAudioMode) {
          case 2: return true
          case 1: return Math.random() < 0.5
          default: return false
        }
      } else {
        return false
      }
    },
    reviewsAvailable () {
      return this.$store.state.reviews.reviews.length
    },
    reviewsCompleted () {
      return this.$store.state.reviews.sessionItemsCompleted.length
    },
    dailyLessonsRemaining () {
      return this.$store.getters['user/dailyLessonsRemaining']
    },
    srsStageAlreadyUpdated () {
      return this.$store.state.reviews.sessionAnsweredIncorrectly.some(item => item.id === this.curReview.id && item.reviewMode === this.curReview.reviewMode)
    },
    customVocab () {
      return this.curReview.id >= this.$store.state.vocabulary.customVocabStartIndex
    },
    hideFurigana () {
      return this.$store.getters['reviews/hideFurigana']
    },
    vocab () {
      if (this.hideFurigana && this.$store.state.settings.reviewsPitchAccent && this.curReview.accent) {
        return pitchaccent.generate(this.curReview.vocab, this.curReview.accent)
      } else {
        return this.curReview.vocab
      }
    },
    furiganaOrPlaceholder () {
      if (this.$store.state.settings.reviewsHideKana && !this.revealFurigana) {
        return `<span>${this.$store.state.settings.placeholder}</span>`
      } else if (!this.$store.state.settings.reviewsPitchAccent || !this.curReview.accent) {
        return `<span>${this.curReview.vocab_kana}</span>`
      } else {
        return pitchaccent.generate(this.curReview.vocab_kana, this.curReview.accent)
      }
    },
    pitchAccentClass () {
      const theme = this.$vuetify.theme.dark ? 'dark' : 'light'
      const pClass =
        this.$store.state.settings.theme[theme].pitchAccentColors.enable &&
        !this.$store.state.settings.theme[theme].pitchAccentColors.disableInReviews
          ? 'pitch--colored'
          : 'pitch--plain'
      return pClass
    },
    lessonIndex () {
      return this.$store.getters['reviews/lessonIndex']
    },
    synonyms () {
      // Splitting synonyms string into single elements, trimming whitespace and filtering out empty elements
      if (this.curReview.synonyms) {
        return this.curReview.synonyms.split(';').map(item => item.trim()).filter(e => e).join(', ')
      } else {
        return ''
      }
    },
    gloss () {
      if (this.customVocab) return this.curReview.meaning
      else return jmdict.getGlossAsString(this.curReview)
    },
    partOfSpeech () {
      if (this.customVocab) return this.curReview.pos
      else return jmdict.getPartsOfSpeech(this.curReview, this.curReview.jmdict_sense).short.join(', ')
    },
    japaneseInputExpected () {
      return this.curReview.reviewMode.endsWith('ToJp')
    }
  },
  methods: {
    updateDailyStats () {
      this.$store.dispatch('user/updateDailyStats')
    },
    initSession () {
      if (this.mode === vars.reviewSessionModes.lessons) {
        const arr = this.$store.getters['lessons/lessons']
        const reviews = []
        for (const itm in arr) {
          const dummyA = Object.assign({}, arr[itm], { reviewMode: 'JpToEn' })
          const dummyB = Object.assign({}, arr[itm], { reviewMode: 'EnToJp' })
          reviews.push(dummyA, dummyB)
        }

        // Start session
        this.$store.dispatch('reviews/startSession', reviews)
        this.loaded = true

        // Autoplay audio in lessons mode
        this.autoplayAudio()
      } else if (this.isSelfStudySession) {
        let vocab = []

        if (this.$store.state.settings.selfStudy.learnedVocab) {
          vocab = Array.from(this.$store.getters['vocabulary/learnedVocab'])
        }

        if (this.$store.state.settings.selfStudy.customVocab) {
          const customVocab = Array.from(this.$store.getters['vocabulary/customVocab'])
          vocab = [...vocab, ...customVocab]
        }

        if (this.$store.state.settings.selfStudy.archivedVocab) {
          const archivedVocab = Array.from(this.$store.getters['vocabulary/archivedVocab'])
          vocab = [...vocab, ...archivedVocab]
        }

        let jpToEnReviews = []
        let enToJpReviews = []
        let jpToJpReviews = []

        if (this.$store.state.settings.selfStudy.reviewModes.includes(1)) {
          jpToEnReviews = vocab.filter(item => {
            return this.$store.state.settings.selfStudy.srsStages.includes(item.streak_meaning)
          }).map(obj => ({ ...obj, reviewMode: 'JpToEn' }))
        }

        if (this.$store.state.settings.selfStudy.reviewModes.includes(2)) {
          enToJpReviews = vocab.filter(item => {
            return this.$store.state.settings.selfStudy.srsStages.includes(item.streak_reading)
          }).map(obj => ({ ...obj, reviewMode: 'EnToJp' }))
        }

        if (this.$store.state.settings.selfStudy.reviewModes.includes(4)) {
          jpToJpReviews = vocab.filter(item => {
            return this.$store.state.settings.selfStudy.srsStages.includes(item.streak_jptojp) && !this.$waka.isKana(item.vocab)
          }).map(obj => ({ ...obj, reviewMode: 'JpToJp' }))
        }

        const reviews = [...jpToEnReviews, ...enToJpReviews, ...jpToJpReviews]

        // TODO: audio reviews in self study

        if (reviews.length > 0) {
          // Start session
          this.$store.dispatch('reviews/startSession', reviews)
          this.$store.dispatch('reviews/reorder')
          this.loaded = true

          // Autoplay audio in self-study session
          this.autoplayAudio()
        } else {
          this.$snackbar.showMessage({ content: 'No words fit your criteria.', color: 'warning' })
          this.$router.push({ name: 'self-study' })
        }
      } else if (this.mode === vars.reviewSessionModes.recentLessons) {
        const reviews = Array.from(this.$store.getters['vocabulary/recentLessons'])

        // Start session
        this.$store.dispatch('reviews/startSession', reviews)
        this.$store.dispatch('reviews/reorder')
        this.loaded = true

        // Autoplay audio
        this.autoplayAudio()
      } else if (this.mode === vars.reviewSessionModes.recentMistakes) {
        // TODO
        const reviews = Array.from(this.$store.getters['vocabulary/recentLessons'])

        // Start session
        this.$store.dispatch('reviews/startSession', reviews)
        this.$store.dispatch('reviews/reorder')
        this.loaded = true

        // Autoplay audio
        this.autoplayAudio()
      } else {
        const reviews = Array.from(this.$store.getters['vocabulary/reviews'])

        // Start session
        this.$store.dispatch('reviews/startSession', reviews)
        this.$store.dispatch('reviews/reorder')
        this.loaded = true

        // Autoplay audio
        this.autoplayAudio()
      }

      this.setRandomFont()
    },
    setRandomFont () {
      const font = this.$refs.fontRand.installedFonts[Math.floor(Math.random() * this.$refs.fontRand.installedFonts.length)]
      this.randomFont = `font-family: ${font}, sans-serif !important`
    },
    archive () {
      this.$store.dispatch('reviews/archive')

      if (this.reviewsAvailable) {
        // Continuing session
        this.nextReview()
      } else {
        this.sessionSummary()
      }
    },
    playWordAudio () {
      if (!this.customVocab && this.curReview.vocab_audio) {
        audio.playVocabAudio(this.curReview.vocab_audio)
      } else {
        this.genAudio(this.curReview.vocab)
      }
    },
    playSentenceAudio () {
      if (!this.customVocab && this.curReview.sentence_audio) {
        audio.playVocabAudio(this.curReview.sentence_audio)
      } else {
        if (this.curReview.sentence) this.genAudio(this.curReview.sentence)
      }
    },
    genAudio (text) {
      const voice = this.$store.getters.japanesePollyVoices[this.pollyVoiceCycle].name
      const gender = this.$store.getters.japanesePollyVoices[this.pollyVoiceCycle].gender
      const language = this.$store.getters.japanesePollyVoices[this.pollyVoiceCycle].language
      const service = this.$store.getters.japanesePollyVoices[this.pollyVoiceCycle].service

      this.$store.dispatch('apiPost/synthesizeSpeech', { text, voice, gender, language, service })

      this.pollyVoiceCycle++
      if (this.pollyVoiceCycle === this.$store.getters.japanesePollyVoices.length) {
        this.pollyVoiceCycle = 0
      }
    },
    playAudio () {
      if (this.customVocab) {
        if (this.$store.state.settings.reviewsSentenceAudioAutoplay && this.curReview.sentence) {
          this.genAudio(`${this.curReview.vocab}。${this.curReview.sentence}`)
        } else {
          this.genAudio(this.curReview.vocab)
        }
      } else {
        if (this.$store.state.settings.reviewsSentenceAudioAutoplay && this.curReview.sentence) {
          audio.playVocabAudio(this.curReview.vocab_audio, this.curReview.sentence_audio)
        } else {
          audio.playVocabAudio(this.curReview.vocab_audio)
        }
      }
    },
    autoplayAudio () {
      // Autoplay audio
      if (this.curReview.reviewMode === 'JpToEn') {
        if (this.audioReview) {
          this.playWordAudio()
        } else if (
          this.$store.state.settings.reviewsAudioPlayAtStart &&
          this.$store.state.settings.reviewsVocabAudioAutoplay &&
          // Disable autoplay if user toggled off Furigana in settings EXCEPT if the word is a kana-only vocab
          (!this.$store.state.settings.reviewsHideKana || this.hideFurigana)
        ) {
          this.playAudio()
        }
      }
    },
    toggleFuriganaVisibility () {
      this.$store.dispatch('settings/update', { reviewsHideKana: !this.$store.state.settings.reviewsHideKana })
    },
    next (answeredCorrectly) {
      this.answerCorrect = answeredCorrectly

      // Calculating new srs stage
      if (this.mode === vars.reviewSessionModes.lessons) {
        if (this.answerCorrect) {
          if (this.$store.getters['reviews/hasDuplicateVocabId']) {
            this.$store.dispatch('reviews/itemCompleted')
            this.nextReview()
          } else {
            const newSrsStage = 1
            const now = this.$date.utc()
            let reviewDue = now.minute() || now.second() || now.millisecond() ? now.add(1, 'hour').startOf('hour') : now.startOf('hour')
            reviewDue = reviewDue.add(this.srsStages.find(x => x.id === newSrsStage).nextReviewValue - 1, 'hours').unix()

            const payload = {
              correct: this.answerCorrect,
              vocab: {
                streak_meaning: newSrsStage,
                streak_reading: newSrsStage,
                streak_jptojp: newSrsStage,
                due_meaning: reviewDue,
                due_reading: reviewDue,
                due_jptojp: reviewDue
              }
            }

            this.$store.dispatch('reviews/updateStage', payload)
            this.updateDailyStats()
            this.$store.dispatch('reviews/itemCompleted')

            if (this.reviewsAvailable) {
              // Continue session
              this.nextReview()
              this.displayChip(true, newSrsStage)
            } else {
              // Ask user whether they want to contiue with another session or
              // tell them that no more lessons are available right now
              this.$refs.cdLessons.show = true
            }
          }
        } else {
          this.nextReview()
        }
      } else if (this.isSelfStudySession || this.isExtraStudySession) {
        if (this.answerCorrect || this.flashcardMode) {
          this.updateDailyStats()
          this.$store.dispatch('reviews/itemCompleted')

          if (this.reviewsAvailable) {
            // Continuing session
            this.nextReview()
          } else {
            // TODO: end self-study session
            // this.sessionSummary()
            if (this.mode === vars.reviewSessionModes.recentLessons) this.$refs.cdRecentLessons.show = true
            if (this.isSelfStudySession) this.$refs.cdSelfStudy.show = true
          }
        } else {
          // Continue session
          this.nextReview()
        }
      } else {
        let curSrsStage = -1
        let newSrsStage = -1
        const maxSrsStage = this.$store.state.srsStages.length - 1
        const itemAnsweredCorrectly = this.answerCorrect && !this.srsStageAlreadyUpdated

        if (!this.srsStageAlreadyUpdated) {
          let tag = ''
          switch (this.curReview.reviewMode) {
            case 'JpToEn':
              tag = 'meaning'
              break
            case 'EnToJp':
              tag = 'reading'
              break
            case 'JpToJp':
              tag = 'jptojp'
              break
          }

          const newCorrect = this.answerCorrect ? this.curReview[`correct_${tag}`] + 1 : this.curReview[`correct_${tag}`]
          const newReviewed = this.curReview[`reviewed_${tag}`] + 1
          curSrsStage = this.curReview[`streak_${tag}`]

          if (this.answerCorrect) {
            // Inrementing srs stage by 1 if it's lower than max stage (=Sensei)
            newSrsStage = curSrsStage < maxSrsStage ? curSrsStage + 1 : maxSrsStage
          } else {
            /* Decrementing srs stage by
              1 if current stage belongs to lowest group (=Rookie)
              2 if current stage belongs to higher group (=Amateur+)
              can't be less than lowest stage of lowest group (=Rookie 1) */
            newSrsStage = curSrsStage <= 4 ? curSrsStage - 1 : curSrsStage - 2
            if (newSrsStage < 1) newSrsStage = 1
          }

          // Getting current time in UTC mode
          const now = this.$date.utc()

          // Round up to nearest hour
          let reviewDue = now.minute() || now.second() || now.millisecond() ? now.add(1, 'hour').startOf('hour') : now.startOf('hour')

          // Adding hours until next review - 1 (!) depending on selected srs level
          reviewDue = reviewDue.add(this.srsStages.find(x => x.id === newSrsStage).nextReviewValue - 1, 'hours').unix()

          const payload = {
            correct: this.answerCorrect,
            vocab: {
              [`streak_${tag}`]: newSrsStage,
              [`correct_${tag}`]: newCorrect,
              [`reviewed_${tag}`]: newReviewed,
              [`due_${tag}`]: reviewDue
            }
          }

          this.$store.dispatch('reviews/updateStage', payload)
          this.updateDailyStats()
        } else {
          switch (this.curReview.reviewMode) {
            case 'JpToEn': newSrsStage = this.curReview.streak_meaning
              break
            case 'EnToJp': newSrsStage = this.curReview.streak_reading
              break
            case 'JpToJp': newSrsStage = this.curReview.streak_jptojp
              break
          }
        }

        // In flashcard mode, wrong items are completed on their first turn just like correct reviews
        if (this.answerCorrect || this.flashcardMode) {
          // const itemAnsweredCorrectly = this.answerCorrect && !this.srsStageAlreadyUpdated
          this.$store.dispatch('reviews/itemCompleted')

          if (this.reviewsAvailable) {
            // Continuing session
            this.nextReview()

            // Displaying review item's new srs stage; hide after a short delay
            this.displayChip(itemAnsweredCorrectly, newSrsStage)
          } else {
            this.sessionSummary()
          }
        } else {
          // Continue session
          this.nextReview()
        }
      }
    },
    displayChip (itemAnsweredCorrectly, newSrsStage) {
      const maxSrsStage = this.$store.state.srsStages.length - 1
      this.chipLabel = this.$store.getters.srsStageName(newSrsStage)

      if (newSrsStage === maxSrsStage) {
        this.chipIcon = 'mdi-check-circle-outline'
        this.chipBgColor = 'gold'
        this.chipTextColor = '#303551'
      } else {
        this.chipIcon = itemAnsweredCorrectly ? 'mdi-arrow-up-circle-outline' : 'mdi-arrow-down-circle-outline'
        this.chipBgColor = itemAnsweredCorrectly ? 'success' : 'error'
        this.chipTextColor = itemAnsweredCorrectly ? 'green darken-4' : 'red darken-4'
      }
      this.showChip = true
      setTimeout(() => { this.showChip = false }, 1250)
    },
    nextReview () {
      this.awaitingResponse = true
      this.revealFurigana = false

      if (!this.flashcardMode) {
        this.$refs.tim.nextReview()
      } else {
        this.$refs.fm.nextReview()
      }
    },
    reviewLoaded () {
      this.awaitingResponse = true
      this.revealFurigana = false
      this.setRandomFont()

      // const aaa = this.$store.getters['vocabulary/vocab'].filter(item =>
      // JSON.parse(item.meaning)[item.jmdict_sense - 1].gloss.sort().toString() === JSON.parse(this.curReview.meaning)[this.curReview.jmdict_sense - 1].gloss.sort().toString()
      // )
      /* const ccc = []
      this.$store.getters['vocabulary/vocab'].filter(item => {
        const bbb = this.$store.getters['vocabulary/vocab'].filter(i => {
          return item.jmdict_ent_seq === i.jmdict_ent_seq && item.jmdict_sense === i.jmdict_sense
        })
        if (bbb.length > 1) ccc.push(item.jmdict_ent_seq)
        return bbb.length > 1
      })
      console.log([...new Set(ccc)]) */
    },
    sessionSummary () {
      if (this.$store.state.reviews.sessionAnsweredCorrectly.length > 0 ||
        this.$store.state.reviews.sessionAnsweredIncorrectly.length > 0) {
        this.$router.push('reviews/summary')
      } else {
        return this.$router.push('dashboard')
      }
    },
    focused () {
      if (!this.flashcardMode) {
        this.$refs.tim.focusInput()
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('reviews/init', this.$route.params.mode)
  },
  mounted () {
    // Loading session data after a short timeout;
    // Loading the data right away prevents the loading bar from initiating
    setTimeout(this.initSession, 250)
  },
  beforeDestroy () {
    audio.stop()
  }
}
</script>

<style scoped>
.container.fill-height {
  flex-direction: column;
  align-items: initial;
}
.container.fill-height>.row {
  flex: 1 1 auto;
}
.pitch--plain:deep(.pitch-type--heiban),
.pitch--plain:deep(.pitch-type--atamadaka),
.pitch--plain:deep(.pitch-type--nakadaka),
.pitch--plain:deep(.pitch-type--odaka) {
  border-color: white !important;
}
</style>
