import * as wanakana from 'wanakana'
import { utils } from '@/plugins/utils'

const state = {
  mode: '',
  reviews: [],
  curReview: undefined,
  curReviewBeforeUpdate: undefined,
  prevReview: undefined,
  prevReviewBeforeUpdate: undefined,
  sessionItemsCompleted: [],
  sessionAnsweredCorrectly: [],
  sessionAnsweredIncorrectly: [],
  curReviewIndex: 0,
  selectedTab: 0
}

const getters = {
  hideFurigana (state) {
    return state.curReview.vocab === state.curReview.vocab_kana || wanakana.isKatakana(state.curReview.vocab)
  },
  synonyms (state) {
    // Splitting synonyms string into single elements, trimming whitespace and filtering out empty elements
    if (state.curReview.synonyms) {
      return state.curReview.synonyms.split(';').map(item => item.trim()).filter(e => e)
    } else {
      return ''
    }
  },
  readings (state) {
    // Splitting readings string into single elements, trimming whitespace and filtering out empty elements
    if (state.curReview.readings) {
      return state.curReview.readings.split(';').map(item => item.trim()).filter(e => e)
    } else {
      return ''
    }
  },
  // Lesson mode only
  hasDuplicateVocabId (state) {
    return state.reviews.filter(r => r.id === state.curReview.id).length > 1
  }
}

const mutations = {
  init (state, mode) {
    state.mode = mode
  },
  startSession (state, reviews) {
    utils.shuffle(reviews)
    state.reviews = reviews
    state.sessionItemsCompleted = []
    state.sessionAnsweredCorrectly = []
    state.sessionAnsweredIncorrectly = []
    state.curReviewIndex = -1
    state.selectedTab = 0
  },
  load (state) {
    // const newIndex = Math.floor(Math.random() * state.reviews.length)
    const newIndex = state.curReviewIndex + 1 < state.reviews.length ? state.curReviewIndex + 1 : 0
    if (state.curReview && state.reviews[newIndex] !== state.curReview) {
      state.prevReview = state.curReview
      state.prevReviewBeforeUpdate = state.curReviewBeforeUpdate
    }

    state.curReview = state.reviews[newIndex]
    state.curReviewBeforeUpdate = undefined

    state.curReviewIndex = newIndex
    state.selectedTab = 0
  },
  undoItem (state) {
    const difference = (state.prevReview && state.prevReviewBeforeUpdate) &&
      (state.prevReview !== state.prevReviewBeforeUpdate)
    console.log(`Undo: prevReview ${difference ? '!==' : '==='} prevReviewBeforeUpdate.`)

    if (difference) {
      // Remove item from
      const sessIndexC = state.sessionAnsweredCorrectly.findIndex(vocab => vocab === state.prevReview)
      if (sessIndexC !== -1) state.sessionAnsweredCorrectly.splice(sessIndexC, 1)
      const sessIndexI = state.sessionAnsweredIncorrectly.findIndex(vocab => vocab === state.prevReview)
      if (sessIndexI !== -1) state.sessionAnsweredIncorrectly.splice(sessIndexI, 1)

      //
      const payload = utils.objectDifference(state.prevReviewBeforeUpdate, state.prevReview)
      const payloadDebug = utils.objectDifference(state.prevReview, state.prevReviewBeforeUpdate)
      console.log(`Undo #${state.prevReviewBeforeUpdate.id} (${state.prevReviewBeforeUpdate.reviewMode}): ${JSON.stringify(payloadDebug)} to ${JSON.stringify(payload)}.`)

      // Writing to IndexedDB
      const vocabId = state.prevReviewBeforeUpdate.id
      this.dispatch('vocabulary/progress', { progress: payload, vocabId })
    }

    const index = state.reviews.findIndex(vocab => vocab === state.prevReview)
    if (index !== -1) {
      if (difference) state.reviews[index] = state.prevReviewBeforeUpdate
      state.curReviewIndex = index
    } else {
      const add = state.prevReviewBeforeUpdate ? state.prevReviewBeforeUpdate : state.prevReview
      state.reviews.splice(state.curReviewIndex, 0, add)

      const delIndex = state.sessionItemsCompleted.findIndex(v => v === state.prevReview)
      if (delIndex !== -1) state.sessionItemsCompleted.splice(delIndex, 1)

      console.log(`Undo: Item #${add.id} (${add.reviewMode}) added back to review queue.`)
    }

    state.prevReview = undefined
    state.prevReviewBeforeUpdate = undefined

    state.curReview = state.reviews[state.curReviewIndex]
    state.curReviewBeforeUpdate = undefined

    state.selectedTab = 0
  },
  reorder (state, revs) {
    state.reviews = revs
    state.curReview = undefined
    state.curReviewBeforeUpdate = undefined
    state.prevReview = undefined
    state.prevReviewBeforeUpdate = undefined
    state.curReviewIndex = -1
    state.selectedTab = 0
  },
  archive (state) {
    state.curReviewBeforeUpdate = JSON.parse(JSON.stringify(state.curReview))
    state.curReview.archived = 1
    state.reviews = state.reviews.filter(vocab => vocab.id !== state.curReview.id)
  },
  updateStage (state, obj) {
    state.curReviewBeforeUpdate = JSON.parse(JSON.stringify(state.curReview))

    for (const item in obj.vocab) {
      if (typeof obj[item] === 'boolean') obj[item] = obj[item] ? 1 : 0
      state.curReview[item] = obj.vocab[item]
    }

    console.log(`Progress #${state.curReview.id} (${state.curReview.reviewMode}): to ${Object.values(obj.vocab).join(':')}`)

    if (obj.correct) state.sessionAnsweredCorrectly.push(state.curReview)
    else state.sessionAnsweredIncorrectly.push(state.curReview)

    // Writing to IndexedDB
    const vocabId = state.curReview.id
    this.dispatch('vocabulary/progress', { progress: obj.vocab, vocabId })
  },
  updateSynonyms (state, { vocabId, newSynonyms }) {
    state.reviews.forEach((vocab, index) => {
      if (vocab.id === vocabId) state.reviews[index].synonyms = newSynonyms
    })
  },
  updateReadings (state, { vocabId, newReadings }) {
    state.reviews.forEach((vocab, index) => {
      if (vocab.id === vocabId) state.reviews[index].readings = newReadings
    })
  },
  updateNotes (state, { vocabId, text }) {
    state.reviews.forEach((vocab, index) => {
      if (vocab.id === vocabId) state.reviews[index].notes = text
    })
  },
  itemCompleted (state) {
    state.reviews.splice(state.curReviewIndex, 1)
    state.curReviewIndex--
    state.sessionItemsCompleted.push(state.curReview)
  }
}

const actions = {
  init (context, mode) {
    context.commit('init', mode)
  },
  startSession (context, reviews) {
    context.commit('startSession', reviews)
    context.commit('load')
  },
  load (context) {
    context.commit('load')
  },
  reorder (context) {
    const revs = [...context.state.reviews]
    // utils.shuffle(revs)

    function getOrder (item) {
      const revModes = context.rootState.settings.reviewsReorder.reviewModes
      switch (item.reviewMode) {
        case 'JpToEn': return revModes.findIndex(e => e === 1)
        case 'EnToJp': return revModes.findIndex(e => e === 2)
        case 'JpToJp': return revModes.findIndex(e => e === 4)
        default: return 0
      }
    }

    function getSrsStage (item) {
      switch (item.reviewMode) {
        case 'JpToEn': return item.streak_meaning
        case 'EnToJp': return item.streak_reading
        case 'JpToJp': return item.streak_jptojp
        default: return 0
      }
    }

    function sortReviewModes (a, b) {
      return getOrder(a) - getOrder(b)
    }

    function sortSrsStages (a, b) {
      return context.rootState.settings.reviewsReorder.sortSrsStagesAscending
        ? getSrsStage(a) - getSrsStage(b)
        : getSrsStage(b) - getSrsStage(a)
    }

    if (context.rootState.settings.reviewsReorder.sortReviewModes && context.rootState.settings.reviewsReorder.sortSrsStages) {
      if (context.rootState.settings.reviewsReorder.levelPriority) {
        revs.sort((a, b) => sortSrsStages(a, b) || sortReviewModes(a, b))
      } else {
        revs.sort((a, b) => sortReviewModes(a, b) || sortSrsStages(a, b))
      }
    } else if (context.rootState.settings.reviewsReorder.sortReviewModes) {
      revs.sort((a, b) => sortReviewModes(a, b))
    } else if (context.rootState.settings.reviewsReorder.sortSrsStages) {
      revs.sort((a, b) => sortSrsStages(a, b))
    }

    context.commit('reorder', revs)
    context.commit('load')
  },
  archive (context) {
    const vocabId = context.state.curReview.id
    context.dispatch('vocabulary/archive', [vocabId], { root: true })
    context.commit('archive')
  },
  updateStage (context, newSrsStage) {
    context.commit('updateStage', newSrsStage)
  },
  updateSynonyms (context, { vocabId, newSynonyms }) {
    context.commit('updateSynonyms', { vocabId, newSynonyms })
  },
  updateReadings (context, { vocabId, newReadings }) {
    context.commit('updateReadings', { vocabId, newReadings })
  },
  updateNotes (context, { vocabId, text }) {
    context.commit('updateNotes', { vocabId, text })
  },
  itemCompleted (context) {
    context.commit('itemCompleted')
  },
  undoItem (context) {
    context.commit('undoItem')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
