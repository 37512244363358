const state = {
  decks: [],
  systemGeneratedDecks: [
    10, // Critical Condition Items
    69420, // Recent Lessons
    69421 // Recent Mistakes
  ]
}

const getters = {
  maxId (state) {
    return Math.max(...state.decks.map(o => o.id))
  },
  deck: (state, getters, rootState) => (deckId) => {
    const a = state.decks.flatMap(obj => obj.id === deckId ? obj.data : [])
    const b = rootState.vocabulary.vocab.filter(e => a.includes(e.id))
    return b.length
  },
  decks (state) {
    return state.decks.filter(deck => !state.systemGeneratedDecks.includes(deck.id))
  },
  systemGeneratedDecks (state) {
    return state.decks.filter(deck => state.systemGeneratedDecks.includes(deck.id))
  }
}

const mutations = {
  init (state, obj) {
    state.decks = obj
    console.log('%cDecks loaded.', 'color: green')
  },
  addDeck (state, deck) {
    state.decks.push(deck)
  },
  modifyDeck (state, deck) {
    const index = state.decks.findIndex(item => item.id === deck.id)
    this._vm.$set(state.decks, index, deck)
  },
  deleteDecks (state, deckIds) {
    state.decks = state.decks.filter(deck => !deckIds.includes(deck.id))
  }
}

const actions = {
  init (context, obj) {
    context.commit('init', obj)
  },
  addDeck (context, deck) {
    context.commit('addDeck', deck)
    // Writing to IndexedDB
    this._vm.$db.decksMetadata.add(deck).then(lastKey => {
      console.log(`%cAdded deck #${deck.id}.`, 'color: green')
      this._vm.$db.sync.update(0, { decks: this._vm.$date.utc().unix() })
      // add to sync queue
      context.dispatch('api/addToNetworkQueue', { type: 'deckMeta', data: [deck.id] }, { root: true })
    })
  },
  modifyDeck (context, deck) {
    context.commit('modifyDeck', deck)
    // Writing to IndexedDB
    this._vm.$db.decksMetadata.where('id').equals(deck.id).modify(deck).then(() => {
      console.log(`%cModified deck #${deck.id}.`, 'color: green')
      this._vm.$db.sync.update(0, { decks: this._vm.$date.utc().unix() })
      // add to sync queue
      context.dispatch('api/addToNetworkQueue', { type: 'deckMeta', data: [deck.id] }, { root: true })
    })
  },
  deleteDecks (context, deckIds) {
    context.commit('deleteDecks', deckIds)
    // Writing to IndexedDB
    this._vm.$db.decksMetadata.where('id').anyOf(deckIds).delete().then(deleteCount => {
      this._vm.$db.decks.where('id').anyOf(deckIds).delete().then(deleteCount2 => {
        console.log(`%cDeleted ${deleteCount} of ${deckIds.length} decks (${deleteCount2} entries).`, 'color: green')
        this._vm.$db.sync.update(0, { decks: this._vm.$date.utc().unix() })
        // add to sync queue
        context.dispatch('api/addToNetworkQueue', { type: 'deckMeta', data: deckIds }, { root: true })
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
