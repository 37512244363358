<template>
  <v-dialog v-model="show">
    <v-card class="mx-auto">
      <v-card-title>Reorder Reviews</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-checkbox
              v-model="sortReviewModes"
              label="Sort by Review Mode"
              hint="* drag to reorder"
              persistent-hint
              class="text-no-wrap"
            ></v-checkbox>
          </v-col>
          <v-col class="text-center my-5">
            <draggable v-model="selectedReviewModes" :disabled="!sortReviewModes">
              <v-chip
                v-for="(mode, i) in selectedReviewModes" :key="mode"
                draggable
                :disabled="!sortReviewModes"
                class="list-group-item text-no-wrap ma-1"
              >
                <span class="text--secondary">{{ i+1 }}.&nbsp;</span>
                {{ getTitle(mode) }}</v-chip>
            </draggable>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center" align="center">
          <v-col cols="12">
            <v-checkbox v-model="sortByLevel" label="Sort by SRS Level" class="text-no-wrap"></v-checkbox>
          </v-col>
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
              color="primary"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" :value="true" :disabled="!sortByLevel">
                    <v-icon>mdi-sort-ascending</v-icon>
                  </v-btn>
                </template>
                Ascending Sort
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" :value="false" :disabled="!sortByLevel">
                    <v-icon>mdi-sort-descending</v-icon>
                  </v-btn>
                </template>
                Descending Sort
              </v-tooltip>
            </v-btn-toggle>
          <v-col cols="12" class="text-center my-5">
            <v-chip
              v-for="(stage, i) in srsStages" :key="stage.id"
              draggable
              :disabled="!sortByLevel"
              class="text-no-wrap ma-1"
            >
              <span class="text--secondary">{{ i+1 }}.&nbsp;</span>
              {{ srsStageName(stage.id) }}</v-chip>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <span class="text-subtitle-1">Reorder Priority</span>
          </v-col>
          <v-btn-toggle
            v-model="levelPriority"
            mandatory
            color="primary"
            class="mb-5"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" :value="true" :disabled="!sortReviewModes || !sortByLevel">
                  Level Priority
                </v-btn>
              </template>
              Sort SRS levels, then sort the levels by review mode
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" :value="false" :disabled="!sortReviewModes || !sortByLevel">
                  Type Priority
                </v-btn>
              </template>
              Sort review modes, then sort the modes by SRS level
            </v-tooltip>
          </v-btn-toggle>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click.stop="hideDialog">Close</v-btn>
        <v-btn depressed color="primary" @click.stop="reorder">Reorder</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  data: () => ({
    show: false
  }),
  computed: {
    sortReviewModes: {
      get () {
        return this.$store.state.settings.reviewsReorder.sortReviewModes
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { reviewsReorder: { sortReviewModes: val } })
      }
    },
    selectedReviewModes: {
      get () {
        return this.$store.state.settings.reviewsReorder.reviewModes
      },
      set (reviewModesIds) {
        this.$store.dispatch('settings/updateLocal', { reviewsReorder: { reviewModes: reviewModesIds } })
      }
    },
    sortByLevel: {
      get () {
        return this.$store.state.settings.reviewsReorder.sortSrsStages
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { reviewsReorder: { sortSrsStages: val } })
      }
    },
    toggle_exclusive: {
      get () {
        return this.$store.state.settings.reviewsReorder.sortSrsStagesAscending
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { reviewsReorder: { sortSrsStagesAscending: val } })
      }
    },
    srsStages () {
      return this.$store.state.srsStages.slice(1).sort((a, b) => this.toggle_exclusive ? a.id - b.id : b.id - a.id)
    },
    levelPriority: {
      get () {
        return this.$store.state.settings.reviewsReorder.levelPriority
      },
      set (val) {
        this.$store.dispatch('settings/updateLocal', { reviewsReorder: { levelPriority: val } })
      }
    }
  },
  methods: {
    getTitle (modeId) {
      return this.$store.state.reviewModes.find(mode => mode.id === modeId).titleFull
    },
    srsStageName (stageId) {
      return this.$store.getters.srsStageName(stageId)
    },
    reorder () {
      this.hideDialog()
      this.$store.dispatch('reviews/reorder')
    },
    hideDialog () {
      this.show = false
      this.$emit('closed')
    }
  },
  watch: {
    show (val) {
      if (!val) {
        this.hideDialog()
      }
    }
  }
}
</script>

<style scoped>
.list-group-item {
  cursor: move;
}
</style>
