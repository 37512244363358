<template>
<v-card>
  <v-card-title>
    <v-row no-gutters>
      <v-col class="grow text-no-wrap">
        Review Forecast
      </v-col>
      <v-col class="shrink text-no-wrap">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon class="ml-2" @click="$router.go(0)">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          Reload page
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card-title>
  <v-card-text>
    <ForecastChart />
    <v-slider
      v-model="slider"
      thumb-label
      ticks="always"
      step="12"
      min="12"
      max="72"
      :tick-labels="tickLabels"
      hide-details
      track-color="grey"
      class="text-caption px-5 mb-1"
    >
      <template v-slot:thumb-label="{ value }">
        {{ value + 'h' }}
      </template>
    </v-slider>
  </v-card-text>
</v-card>
</template>

<script>
import ForecastChart from './ForecastChart.vue'

export default {
  components: {
    ForecastChart
  },
  data () {
    return {
      tickLabels: ['0.5d', '1d', '1.5d', '2d', '2.5d', '3d']
    }
  },
  computed: {
    slider: {
      get () {
        return this.$store.state.settings.forecastChartSlider
      },
      set (value) {
        this.$store.dispatch('settings/updateLocal', { forecastChartSlider: value })
      }
    },
    sliderLabel () {
      const duration = this.$date.duration(this.slider, 'hours').asDays()
      return `${duration} ${duration === 1 ? 'day' : 'days'}`
    }
  }
}
</script>
