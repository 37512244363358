<template>
<v-container class="pa-0">
  <v-subheader>Study Mode</v-subheader>
    <v-list-item>
      <v-list-item-icon>
        <v-icon @click="showStudyModeDialog">mdi-cog</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ studyMode }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
  <v-list-item :three-line="$vuetify.breakpoint.mdAndDown">
    <v-list-item-action>
      <v-text-field
        v-model="dailyWorkload"
        class="mt-0 pt-0"
        hide-details
        single-line
        type="number"
        :min="dailyWorkloadMin"
        :max="dailyWorkloadMax"
        style="width: 50px"
        @input="validateDailyWorkload"
        :key="dailyWorkloadKey"
      ></v-text-field>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>Daily workload</v-list-item-title>
      <v-list-item-subtitle>The number of lessons you can do per day. Keep it reasonable to avoid getting overloaded with reviews later on.</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
  <!--v-slider
    v-model="dailyWorkload"
    min="5"
    max="50"
    thumb-label="always"
    track-color="grey"
    class="mt-6 mx-12"
  >
    <template v-slot:prepend>
      <v-icon color="primary" @click="dailyWorkload--">mdi-minus</v-icon>
    </template>
    <template v-slot:append>
      <v-icon color="primary" @click="dailyWorkload++">mdi-plus</v-icon>
    </template>
  </v-slider-->
  <v-divider></v-divider>
  <v-list-item :three-line="$vuetify.breakpoint.xsOnly">
    <v-list-item-action>
      <v-text-field
        v-model="batchSize"
        class="mt-0 pt-0"
        hide-details
        single-line
        type="number"
        :min="batchSizeMin"
        :max="batchSizeMax"
        style="width: 50px"
        @input="validateBatchSize"
        :key="batchSizeKey"
      ></v-text-field>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>Batch size</v-list-item-title>
      <v-list-item-subtitle>The number of new lessons to be introduced before the quiz.</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
  <!--v-slider
    v-model="batchSize"
    min="3"
    max="10"
    thumb-label="always"
    track-color="grey"
    class="mt-6 mx-12"
  >
    <template v-slot:prepend>
      <v-icon color="primary" @click="batchSize--">mdi-minus</v-icon>
    </template>
    <template v-slot:append>
      <v-icon color="primary" @click="batchSize++">mdi-plus</v-icon>
    </template>
  </v-slider-->
  <v-divider></v-divider>
  <v-list>
    <v-subheader>Pitch Accent</v-subheader>
    <v-list-item link :input-value="lessonsPitchAccent" @click="lessonsPitchAccent = !lessonsPitchAccent">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Enable Pitch Accent Graphs</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-divider></v-divider>
    <v-subheader>Furigana</v-subheader>
    <v-list-item link :input-value="lessonsHideKana" @click="lessonsHideKana = !lessonsHideKana">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Hide Furigana</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-list-item link :input-value="lessonsHideSentence" @click="lessonsHideSentence = !lessonsHideSentence">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Hide context sentence meaning</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-divider></v-divider>
    <v-subheader>Japanese Audio</v-subheader>
    <v-list-item
      link
      :three-line="$vuetify.breakpoint.xsOnly"
      :input-value="lessonsAudioAutoplay"
      @click="lessonsAudioAutoplay = !lessonsAudioAutoplay"
    >
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Autoplay audio</v-list-item-title>
          <v-list-item-subtitle>Audio for vocabulary and context sentences will autoplay when toggled.</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-list-item>
  </v-list>
  <StudyModeDialog ref="smd" />
</v-container>
</template>

<script>
import StudyModeDialog from '@/dashboard/StudyModeDialog.vue'

export default {
  components: {
    StudyModeDialog
  },
  data () {
    return {
      dailyWorkloadMin: 5,
      dailyWorkloadMax: 50,
      dailyWorkloadKey: 0,
      batchSizeMin: 3,
      batchSizeMax: 10,
      batchSizeKey: 0
    }
  },
  computed: {
    studyMode () {
      return this.$store.getters['settings/decks'].find(x => x.id === this.$store.state.settings.studyMode.id).title
    },
    dailyWorkload: {
      get () {
        return this.$store.state.settings.dailyLimit
      },
      set (newValue) {
        // min/max validation
        if (newValue < this.dailyWorkloadMin || newValue > this.dailyWorkloadMax) return

        this.$store.dispatch('settings/update', { dailyLimit: newValue })
        this.$store.dispatch('requireReload')
      }
    },
    batchSize: {
      get () {
        return this.$store.state.settings.lessonsBatchSize
      },
      set (newValue) {
        // min/max validation
        if (newValue < this.batchSizeMin || newValue > this.batchSizeMax) return

        this.$store.dispatch('settings/update', { lessonsBatchSize: newValue })
        this.$store.dispatch('requireReload')
      }
    },
    lessonsPitchAccent: {
      get () {
        return this.$store.state.settings.lessonsPitchAccent
      },
      set (value) {
        this.$store.dispatch('settings/update', { lessonsPitchAccent: value })
      }
    },
    lessonsHideKana: {
      get () {
        return this.$store.state.settings.lessonsHideKana
      },
      set (value) {
        this.$store.dispatch('settings/update', { lessonsHideKana: value })
      }
    },
    lessonsHideSentence: {
      get () {
        return this.$store.state.settings.lessonsHideSentence
      },
      set (value) {
        this.$store.dispatch('settings/update', { lessonsHideSentence: value })
      }
    },
    lessonsAudioAutoplay: {
      get () {
        return this.$store.state.settings.lessonsAudioAutoplay
      },
      set (value) {
        this.$store.dispatch('settings/update', { lessonsAudioAutoplay: value })
      }
    }
  },
  methods: {
    showStudyModeDialog () {
      this.$refs.smd.init()
    },
    validateDailyWorkload (val) {
      if (val < this.dailyWorkloadMin) {
        this.dailyWorkload = this.dailyWorkloadMin
        // this.dailyWorkloadKey++
      } else if (val > this.dailyWorkloadMax) {
        this.dailyWorkload = this.dailyWorkloadMax
        this.dailyWorkloadKey++
      }
    },
    validateBatchSize (val) {
      if (val < this.batchSizeMin) {
        this.batchSize = this.batchSizeMin
        // this.batchSizeKey++
      } else if (val > this.batchSizeMax) {
        this.batchSize = this.batchSizeMax
        this.batchSizeKey++
      }
    }
  }
}
</script>
