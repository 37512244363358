<template>
<v-container>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>Review Stats</v-card-title>
        <v-card-text>
          <div class="overline">Accuracy</div>
          <v-row>
            <v-col v-for="(reviewMode, i) in reviewStatsData" :key="i" cols="6" md="3">
              <v-card outlined>
                <v-card-title class="justify-center">{{ reviewMode.title }}</v-card-title>
                <v-card-text class="text-center">
                  <v-progress-circular
                    :size="100"
                    :width="15"
                    :rotate="-90"
                    color="reviews"
                    :value="showValues ? reviewMode.val : 0"
                  >
                    {{ showValues ? parseInt(reviewMode.val) || 0 : 0 }}%
                  </v-progress-circular>
                  <v-spacer class="my-3"></v-spacer>
                  <div>
                    <div class="d-inline text-h6">{{ reviewMode.reviewsCompleted }}</div>
                    <div class="d-inline"> reviews</div>
                  </div>
                  <v-divider></v-divider>
                  <div>
                    <div class="d-inline text-h6">{{ reviewMode.correctCount }}</div>
                    <div class="d-inline"> correct</div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div class="overline mt-5">Daily Average</div>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <v-card-text class="text-h6">
                  You completed {{ dailyStatsData.reviewsCompleted }} reviews in {{ dailyStatsData.daysReviewed }} days.
                  That's <strong>{{ parseInt(dailyStatsData.reviewsCompleted / dailyStatsData.daysReviewed) || 0 }}</strong> reviews per day on average.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>Lesson Stats</v-card-title>
        <v-card-text>
          <div class="overline">Progress</div>
          <v-row>
            <v-col v-for="(studyMode, i) in lessonStatsData" :key="i" cols="12" sm="6">
              <v-card outlined>
                <v-card-title>{{ studyMode.title }}</v-card-title>
                <v-card-text>
                  <v-progress-linear rounded height="15" color="lessons" :value="showValues ? studyMode.val : 0">
                    {{ showValues ? parseInt(studyMode.val) : 0 }}%
                  </v-progress-linear>
                  <v-row>
                    <v-col class="shrink text-left">
                      <div class="text-h6">{{ studyMode.wordsLearned }}</div>
                      <v-divider></v-divider>
                      <div class="text-no-wrap">Learned</div>
                    </v-col>
                    <v-col class="grow"></v-col>
                    <v-col class="shrink text-right">
                      <div class="text-h6">{{ studyMode.wordCount }}</div>
                      <v-divider></v-divider>
                      <div class="text-no-wrap">Total</div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div class="overline mt-5">Daily Average</div>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <v-card-text class="text-h6">
                  You completed {{ dailyStatsData.lessonsCompleted }} lessons in {{ dailyStatsData.daysStudied }} days.
                  That's {{ parseInt(dailyStatsData.lessonsCompleted / dailyStatsData.daysStudied) || 0 }} lessons per day on average.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>Self-Study Stats</v-card-title>
        <v-card-text>
          <v-card outlined>
            <v-card-text class="text-h6">
              You completed {{ dailyStatsData.selfStudyRevsCompleted }} reviews in {{ dailyStatsData.daysSelfStudied }} days.
              That's <strong>{{ parseInt(dailyStatsData.selfStudyRevsCompleted / dailyStatsData.daysSelfStudied) || 0 }}</strong> reviews per day on average.
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>Time Investment</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <v-card-text class="text-h6">
                  You've been a member for <strong>{{ createTime.daysSince }} days</strong>! (signed up {{ createTime.date }})
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card outlined>
                <v-card-text class="text-center">
                  <span class="text-h6">{{ studyTime }}</span>
                  <v-divider></v-divider>
                  Study Time
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card outlined>
                <v-card-text class="text-center">
                  <span class="text-h6">{{ reviewTime }}</span>
                  <v-divider></v-divider>
                  Review Time
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card outlined>
                <v-card-text class="text-center">
                  <span class="text-h6">{{ overallTime }}</span>
                  <v-divider></v-divider>
                  Idle
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  data () {
    return {
      showValues: false
    }
  },
  computed: {
    lessonStats () {
      return this.$store.getters['vocabulary/statistics'].lessons
    },
    reviewStats () {
      return this.$store.getters['vocabulary/statistics'].reviews
    },
    lessonStatsData () {
      return [
        {
          title: 'Core 10k',
          wordCount: this.lessonStats.core10kCount,
          wordsLearned: this.lessonStats.core10kLearned,
          get val () { return this.wordsLearned / this.wordCount * 100 }
        },
        {
          title: 'WaniKani Supplement',
          wordCount: this.lessonStats.wkCount,
          wordsLearned: this.lessonStats.wkLearned,
          get val () { return this.wordsLearned / this.wordCount * 100 }
        },
        {
          title: 'Kana-only',
          wordCount: this.lessonStats.kanaOnlyCount,
          wordsLearned: this.lessonStats.kanaOnlyLearned,
          get val () { return this.wordsLearned / this.wordCount * 100 }
        },
        {
          title: 'JLPT N5',
          wordCount: this.lessonStats.jlpt5Count,
          wordsLearned: this.lessonStats.jlpt5Learned,
          get val () { return this.wordsLearned / this.wordCount * 100 }
        },
        {
          title: 'JLPT N4',
          wordCount: this.lessonStats.jlpt4Count,
          wordsLearned: this.lessonStats.jlpt4Learned,
          get val () { return this.wordsLearned / this.wordCount * 100 }
        },
        {
          title: 'JLPT N3',
          wordCount: this.lessonStats.jlpt3Count,
          wordsLearned: this.lessonStats.jlpt3Learned,
          get val () { return this.wordsLearned / this.wordCount * 100 }
        },
        {
          title: 'JLPT N2',
          wordCount: this.lessonStats.jlpt2Count,
          wordsLearned: this.lessonStats.jlpt2Learned,
          get val () { return this.wordsLearned / this.wordCount * 100 }
        },
        {
          title: 'JLPT N1',
          wordCount: this.lessonStats.jlpt1Count,
          wordsLearned: this.lessonStats.jlpt1Learned,
          get val () { return this.wordsLearned / this.wordCount * 100 }
        }
      ]
    },
    reviewStatsData () {
      return [
        {
          title: 'Overall',
          reviewsCompleted: this.reviewStats.tr,
          correctCount: this.reviewStats.tc,
          get val () { return this.reviewsCompleted > 0 ? (this.correctCount / this.reviewsCompleted * 100) : 0 }
        },
        {
          title: 'JP → EN',
          reviewsCompleted: this.reviewStats.rm,
          correctCount: this.reviewStats.m,
          get val () { return this.reviewsCompleted > 0 ? (this.correctCount / this.reviewsCompleted * 100) : 0 }
        },
        {
          title: 'EN → JP',
          reviewsCompleted: this.reviewStats.rr,
          correctCount: this.reviewStats.r,
          get val () { return this.reviewsCompleted > 0 ? (this.correctCount / this.reviewsCompleted * 100) : 0 }
        },
        {
          title: 'JP → JP',
          reviewsCompleted: this.reviewStats.rjp,
          correctCount: this.reviewStats.jp,
          get val () { return this.reviewsCompleted > 0 ? (this.correctCount / this.reviewsCompleted * 100) : 0 }
        }
      ]
    },
    dailyStatsData () {
      return this.$store.state.user.dailyStats.reduce((counter, obj) => {
        if (obj.lessons > 0) {
          counter.daysStudied++
          counter.lessonsCompleted += obj.lessons
        }
        if (obj.reviews > 0) {
          counter.daysReviewed++
          counter.reviewsCompleted += obj.reviews
        }
        if (obj.selfStudy > 0) {
          counter.daysSelfStudied++
          counter.selfStudyRevsCompleted += obj.selfStudy
        }
        return counter
      }, { daysStudied: 0, lessonsCompleted: 0, daysReviewed: 0, reviewsCompleted: 0, daysSelfStudied: 0, selfStudyRevsCompleted: 0 })
    },
    createTime () {
      const dateFormat = 'MMMM DD, YYYY'
      const date = this.$date.unix(this.$store.state.user.accountCreated).utc().add(this.$store.state.settings.timezone, 'hours')
      const day = date.format(dateFormat)
      const daysSince = this.$date.utc().add(this.$store.state.settings.timezone, 'hours').diff(date, 'days')
      return { date: day, daysSince }
    },
    studyTime () {
      return this.formatDuration(this.$store.state.user.timeLessons)
    },
    reviewTime () {
      return this.formatDuration(this.$store.state.user.timeReviews)
    },
    overallTime () {
      return this.formatDuration(this.$store.state.user.timeIdle)
    }
  },
  methods: {
    formatDuration (seconds) {
      const arr = []
      const duration = this.$date.duration(seconds, 'seconds')
      const days = duration.days()
      const hours = duration.hours()
      const minutes = duration.minutes()
      if (days) arr.push(`${days} ${days === 1 ? 'day' : 'days'}`)
      if (hours) arr.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`)
      if (minutes) arr.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`)
      return arr.length > 0 ? arr.join(', ') : 'less than a minute'
    }
  },
  mounted () {
    // Set values of v-progress components after short delay; necessary step to see it animated
    setTimeout(() => { this.showValues = true }, 250)
  }
}
</script>
