<template>
<v-container class="pa-0">
  <v-list>
    <v-subheader>Account</v-subheader>
    <ConfirmDialog
      ref="cdClearCache"
      title="Wipe locally saved data?"
      message="Clear your web browser's local storage and re-download your data from the cloud. Your local preferences will be preserved."
      :persistent="false"
      @confirm="clearCache"
    ></ConfirmDialog>
    <ConfirmDialog
      ref="cdLogout"
      title="Are you sure you want to sign out?"
      :persistent="false"
      @confirm="signOut"
    ></ConfirmDialog>
    <v-dialog v-model="dialogUsername">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on" :disabled="!online">
          <v-list-item-icon>
            <v-icon :disabled="!online">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Username</v-list-item-title>
            <v-list-item-subtitle>{{ userName }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-card>
        <v-card-title>Change Username</v-card-title>
        <v-card-subtitle>Only you will see this. Feel free to use whatever name you like.</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="username" v-model="validUsername" @submit.prevent="changeUsername">
            <v-text-field
              v-model="userName"
              label="New Username"
              :rules="rulesUsername"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="dialogUsername = false">Close</v-btn>
          <v-btn depressed color="primary" :disabled="!validUsername" @click="changeUsername">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEmail">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on" :disabled="!online">
          <v-list-item-icon>
            <v-icon :disabled="!online">mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Email</v-list-item-title>
            <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-card>
        <v-card-title>Change Email</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="dialogEmailNew"
            label="New Email"
            :rules="rulesEmail"
            :disabled="dialogLoading"
            autocomplete="off"
          ></v-text-field>
          <v-text-field
            v-model="dialogEmailPw"
            label="Password Confirm"
            type="password"
            :disabled="dialogLoading"
          ></v-text-field>
          <div class="error--text">{{ dialogError }}</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="dialogEmail = false">Close</v-btn>
          <v-btn depressed color="primary" :loading="dialogLoading" @click="changeEmail">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-list-item :three-line="$vuetify.breakpoint.mdAndDown">
      <v-list-item-icon>
        <v-icon>mdi-lock</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Password</v-list-item-title>
        <v-list-item-subtitle>To change your password, please sign out and use the 'Forgot your password?' link on the login page.</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-btn depressed color="primary" :disabled="!online || isLoggingOut" class="mr-2" @click="$refs.cdLogout.show = true">
        <v-icon left :disabled="!online || isLoggingOut">mdi-exit-to-app</v-icon>
        Sign Out
      </v-btn>
      <v-btn outlined color="primary" :disabled="!online" @click="$refs.cdClearCache.show = true">
        <v-icon left :disabled="!online">mdi-trash-can-outline</v-icon>
        Clear local storage
      </v-btn>
    </v-list-item>
    <v-divider class="mt-2"></v-divider>
    <v-subheader>Date &amp; Time</v-subheader>
    <v-dialog v-model="dialogTime">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Timezone</v-list-item-title>
            <v-list-item-subtitle>{{ timezoneTitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-card>
        <v-card-title>Select Timezone</v-card-title>
        <v-card-text>
          <v-list rounded>
            <v-list-item-group mandatory v-model="timezone" color="primary">
              <v-list-item v-for="item in timezones" :key="item.title">
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogTime = false">Save & Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-list-item link :input-value="time24HourFormat" @click="time24HourFormat = !time24HourFormat">
      <template v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Use 24-hour format</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
    <v-divider></v-divider>
    <v-subheader>Offline Mode</v-subheader>
    <v-list-item>
      <span class="text--secondary">
        Offline Mode activates automatically when you're not connected to the internet.
        Use the button below to toggle Offline Mode manually.
      </span>
    </v-list-item>
    <v-list-item>
      <v-btn depressed color="primary" class="mr-2" @click="toggleOfflineMode">
        <v-icon left>{{ offlineMode ? 'mdi-wifi' : 'mdi-wifi-off' }}</v-icon>
        {{ offlineMode ? 'Go Online' : 'Go Offline' }}
      </v-btn>
    </v-list-item>
    <v-divider class="mt-2"></v-divider>
    <v-subheader>Vacation Mode</v-subheader>
  </v-list>
  <v-container class="text--secondary mx-2">
    <div>
      If you are going to be away from Torii for a prolonged period of time, you can freeze the spaced repetition system until you deactivate vacation mode.<br/>
      <strong>Please note</strong> that any active subscriptions will <strong>not</strong> be extended by the lenght of your vacation.
    </div>
    <div v-html="vacationModeText">{{ vacationModeText }}</div>
    <v-btn depressed color="primary" class="my-2" :loading="btnLoading" @click="toggleVacationMode">{{ vacationModeBtnText }}</v-btn>
  </v-container>
  <v-divider></v-divider>
  <v-expansion-panels flat hover :disabled="!online" class="ma-0 pa-0">
    <v-expansion-panel>
      <v-expansion-panel-header>Danger Zone</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <v-dialog v-model="dialogResetAccount" width="500" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :disabled="!online || isLoggingOut" block color="warning">
                  <v-icon left :disabled="!online || isLoggingOut">fa-exclamation-triangle</v-icon>
                  Reset Progress
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline background" primary-title>
                  Reset Progress
                </v-card-title>
                <v-card-text class="mt-5">
                  <p>This will wipe your progress and reset most settings.</p>
                  <p>Your custom content (vocab, notes, synonyms, readings) will be preserved.</p>
                  <p class="warning--text font-weight-bold">This action cannot be undone!</p>
                  <v-divider class="mb-3"></v-divider>
                  <p>Type <kbd>reset</kbd> into the textfield below to proceed.</p>
                  <v-text-field v-model="resetAcc" outlined dense></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined
                    color="primary"
                    @click="resetAcc = '', dialogResetAccount = false">
                    Cancel
                  </v-btn>
                  <v-btn depressed
                    color="primary"
                    :disabled="resetAcc !== 'reset' || !online"
                    :loading="isLoggingOut"
                    @click="resetAccount">
                    Reset Progress
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="6">
            <v-dialog v-model="dialogDeleteAccount" width="500" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :disabled="!online || isLoggingOut" block color="error">
                  <v-icon left :disabled="!online || isLoggingOut">fa-skull-crossbones</v-icon>
                  Delete Account
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline background" primary-title>
                  Delete Account
                </v-card-title>
                <v-card-text class="mt-5">
                  <p>We'd hate to see you go! :(</p>
                  <p>Please use the contact form to tell us your reasons for deleting your account. We're open to feedback and are continously trying to improve Torii SRS.</p>
                  <p class="warning--text font-weight-bold">Account deletion is permanent!</p>
                  <v-divider class="mb-3"></v-divider>
                  <p>Type <kbd>delete</kbd> into the textfield below to proceed.</p>
                  <v-text-field v-model="deleteAcc" outlined dense></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined
                    color="primary"
                    @click="deleteAcc = '', dialogDeleteAccount = false">
                    Cancel
                  </v-btn>
                  <v-btn depressed
                    color="primary"
                    :disabled="deleteAcc !== 'delete' || !online"
                    :loading="isLoggingOut"
                    @click="deleteAccount">
                    Delete Account
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-container>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  components: {
    ConfirmDialog
  },
  data () {
    return {
      isClearingCache: false,
      isLoggingOut: false,
      btnLoading: false,
      dialogLoading: false,
      dialogUsername: false,
      dialogEmail: false,
      dialogTime: false,
      dialogResetAccount: false,
      dialogDeleteAccount: false,
      dialogEmailNew: '',
      dialogEmailPw: '',
      dialogError: '',
      resetAcc: '',
      deleteAcc: '',
      newUsername: '',
      validUsername: null,
      rulesUsername: [
        v => !!v || 'Username must have at least 1 character',
        v => (v && v.length <= 25) || 'Username may not have more than 25 characters'
      ],
      rulesEmail: [
        v => !!v || '',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'E-mail must be valid'
      ],
      timezones: [
        { utc: -12, title: '(UTC-12) International Date Line West' },
        { utc: -11, title: '(UTC-11) Coordinated Universal Time-11' },
        { utc: -10, title: '(UTC-10) Hawaii, Aleutian Islands' },
        { utc: -9, title: '(UTC-9) Alaska' },
        { utc: -8, title: '(UTC-8) Pacific Time (US & Canada)' },
        { utc: -7, title: '(UTC-7) Mountain Time (US & Canada)' },
        { utc: -6, title: '(UTC-6) Central Time (US & Canada), Mexico City' },
        { utc: -5, title: '(UTC-5) Eastern Time (US & Canada), Bogota, Lima' },
        { utc: -4, title: '(UTC-4) Atlantic Time (Canada), Caracas, Georgetown' },
        { utc: -3, title: '(UTC-3) Brasilia, Buenos Aires, Greenland' },
        { utc: -2, title: '(UTC-2) Coordinated Universal Time-02' },
        { utc: -1, title: '(UTC-1) Azores, Cabo Verde Islands' },
        { utc: 0, title: '(UTC+0) Dublin, London, Lisbon' },
        { utc: 1, title: '(UTC+1) Vienna, Berlin, Brussels, Madrid, Paris, Rome' },
        { utc: 2, title: '(UTC+2) Athens, South Africa' },
        { utc: 3, title: '(UTC+3) Baghdad, Istanbul, Moscow, St. Petersburg' },
        { utc: 4, title: '(UTC+4) Abu Dhabi, Baku, Tbilisi' },
        { utc: 5, title: '(UTC+5) Ekaterinburg, Islamabad, Karachi' },
        { utc: 6, title: '(UTC+6) Astana, Dhaka' },
        { utc: 7, title: '(UTC+7) Bangkok, Hanoi, Jakarta' },
        { utc: 8, title: '(UTC+8) Beijing, Singapore, Hong Kong' },
        { utc: 9, title: '(UTC+9) Tokyo, Seoul, Osaka, Sapporo' },
        { utc: 10, title: '(UTC+10) Eastern Australia, Guam' },
        { utc: 11, title: '(UTC+11) Magadan, Solomon Islands, New Caledonia' },
        { utc: 12, title: '(UTC+12) Aukland, Wellington, Fiji' },
        { utc: 13, title: '(UTC+13) Samoa' },
        { utc: 14, title: '(UTC+14) Kiritimati Island' }
      ]
    }
  },
  computed: {
    online () {
      return this.$store.getters.online
    },
    offlineMode () {
      return this.$store.state.settings.offlineMode
    },
    userName: {
      get () {
        return this.$store.state.user.name
      },
      set (value) {
        this.newUsername = value
      }
    },
    userEmail () {
      return this.$store.state.userEmail
    },
    timezoneTitle () {
      return this.timezones.find(x => x.utc === this.$store.state.settings.timezone).title
    },
    timezone: {
      get () {
        return this.timezones.findIndex(x => x.utc === this.$store.state.settings.timezone)
      },
      set (index) {
        const newUtc = this.timezones[index].utc
        this.$store.dispatch('settings/update', { timezone: newUtc })
      }
    },
    time24HourFormat: {
      get () {
        return this.$store.state.settings.time24HourFormat
      },
      set (value) {
        this.$store.dispatch('settings/update', { time24HourFormat: value })
      }
    },
    vacationMode () {
      return this.$store.getters['user/vacationMode']
    },
    vacationModeText () {
      const dateFormat = 'MMMM DD, YYYY'
      const timeFormat = 'hh:mm a'

      if (this.vacationMode) {
        const date = this.$date.unix(this.$store.state.user.vacationStart).utc().add(this.$store.state.settings.timezone, 'hours')
        const day = date.format(dateFormat)
        const time = date.format(timeFormat)
        return 'Your current vacation was activated on <b>' + day + '</b> at <b>' + time + '</b>.'
      } else {
        const date = this.$date.unix(this.$store.state.user.vacationEnd).utc().add(this.$store.state.settings.timezone, 'hours')
        const day = date.format(dateFormat)
        const time = date.format(timeFormat)
        return 'Your last vacation was deactivated on <b>' + day + '</b> at <b>' + time + '</b>.'
      }
    },
    vacationModeBtnText () {
      if (this.vacationMode) return 'Deactivate Vacation Mode'
      else return 'Activate Vacation Mode'
    }
  },
  methods: {
    changeUsername () {
      if (this.validUsername) {
        this.$store.dispatch('user/update', { name: this.newUsername.trim() })
        this.$refs.username.reset()
        this.dialogUsername = false
      }
    },
    changeEmail () {
      const newMail = this.dialogEmailNew.trim()
      const pw = this.dialogEmailPw.trim()

      if (!newMail || !pw) return
      this.dialogLoading = true

      this.$store.dispatch('apiPost/email', {
        newEmail: newMail,
        password: pw
      }).then(response => {
        if (response.data.success) {
          this.dialogEmail = false
          this.$store.dispatch('userEmail', newMail)
        } else {
          this.dialogError = response.data.error_description
        }
      }).then(() => {
        this.dialogLoading = false
      })
    },
    toggleOfflineMode () {
      this.$store.dispatch('settings/updateLocal', { offlineMode: !this.offlineMode })
    },
    signOut () {
      this.isLoggingOut = true
      this.$store.dispatch('apiPost/logout').then(response => {
        // Clear network queue
        this.$store.dispatch('api/emptyNetworkQueue', 'all')
        // Clear indexeddb
        this.$db.delete().then(() => {
          this.$vuetify.theme.dark = false
          this.$router.go(0)
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.isLoggingOut = false
        })
      })
    },
    clearCache () {
      this.isClearingCache = true
      // Clear network queue
      this.$store.dispatch('api/emptyNetworkQueue', 'all')
      // Clear indexeddb
      this.$db.delete().then(() => {
        this.$vuetify.theme.dark = false
        this.$router.go(0)
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.isClearingCache = false
      })
    },
    toggleVacationMode () {
      this.btnLoading = true
      this.$store.dispatch('user/vacationMode', this.vacationMode).then(() => { this.btnLoading = false })
    },
    resetAccount () {
      this.isLoggingOut = true
      this.$store.dispatch('apiPost/resetProgress').then(response => {
        if (response.data.success) {
          this.signOut()
        } else {
          console.error(response.data.error_description)
        }
      }).then(() => {
        this.isLoggingOut = true
      })
    },
    deleteAccount () {
      this.isLoggingOut = true
      this.$store.dispatch('apiPost/deleteAccount').then(response => {
        if (response.data.success) {
          this.signOut()
        } else {
          console.error(response.data.error_description)
        }
      }).then(() => {
        this.isLoggingOut = true
      })
    }
  }
}
</script>
